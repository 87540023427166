@import "../mixins/font-face";

$baseDir: '../';

/* roboto-regular - latin */
@include gescome-font-face(
		$family-name: 'Roboto',
		$font-path: '#{$baseDir}fonts/Roboto/roboto-v27-latin-regular',
		$font-weight: 400,
		$font-style: normal,
);

/* roboto-500 - latin */
@include gescome-font-face(
		$family-name: 'Roboto',
		$font-path: '#{$baseDir}fonts/Roboto/roboto-v27-latin-500',
		$font-weight: 500,
		$font-style: normal,
);

/* roboto-700 - latin */
@include gescome-font-face(
		$family-name: 'Roboto',
		$font-path: '#{$baseDir}fonts/Roboto/roboto-v27-latin-700',
		$font-weight: 700,
		$font-style: normal,
);

/* roboto-900 - latin */
@include gescome-font-face(
		$family-name: 'Roboto',
		$font-path: '#{$baseDir}fonts/Roboto/roboto-v27-latin-900',
		$font-weight: 900,
		$font-style: normal,
);

h1 {
	color: $default-cornflower-blue;
	font-size: clamp(2rem, 0.6701rem + 1.2987vw, 2.8rem);;
	font-weight: $font-weight__most-bold;
	line-height: 3rem;
	margin-bottom: 0;
	text-transform: uppercase;
}

h2 {
	font-size: clamp(1.4rem, 0.7351rem + 0.6494vw, 1.8rem);
	font-weight: $font-weight__bold;
	line-height: 2.2rem;
	margin: 0;
	padding-top: 3rem;
	padding-bottom: 2rem;
}
