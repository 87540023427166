$tabs__border-radius: 18px;

#tab-details,
#tab-attachments,
#tab-comments,
#tab-history {
	min-height: 70vh;
}

#tab-fav-projects,
#tab-fav-orders,
#tab-fav-proposals,
#tab-fav-clients {
	min-height: 45rem;
}

.tabs {
	&__wrapper {
		position: relative;

		&.shadowed-js {
			.tabs__content:not(.active-subtab-item) {
				position: relative;

				&:before {
					@include pseudo(
							$background: $default-athens-gray,
							$height: 100%,
							$top: 0,
							$translateY: 0,
							$translateX: 0,
							$width: 100%
					);
					border-radius: $tabs__border-radius;
					left: 0;
					opacity: .7;
					z-index: 2;
				}
			}
		}
	}

	&-selector__block {
		background-color: $default-white;
		display: none;
		padding: 3rem $content-wrapper__padding_mobile 1.5rem;
	}

	&__title {
		background-color: transparent;
		border-radius: $tabs__border-radius $tabs__border-radius 0 0;
		color: $default-comet;
		cursor: pointer;
		display: inline-block;
		font-size: clamp(1.1rem, 0.8600rem + 0.3750vw, 1.4rem);
		font-weight: $font-weight__most-bold;
		line-height: 1.6rem;
		min-width: 11rem;
		padding: 2rem 1.1vw;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		transition: color .15s ease-in-out, background-color .15s ease-in-out;
		user-select: none;

		&-parent {
			position: relative;

			&.active-tab-js {
				& > .tabs__title-list-child {
					display: flex;
					z-index: 4;
				}
			}
		}

		&-list {
			align-items: center;
			display: flex;
			flex-direction: row;

			&_left {
				justify-content: flex-start;
			}

			&_right {
				justify-content: flex-end;
			}

			&-child {
				display: none;
				position: absolute;
				top: 100%;
			}
		}

		&.active-tab-js {
			background-color: $default-white;
			color: $default-cornflower-blue;
			cursor: default;
		}

		&.active-parent-tab-js {
			background-color: $default-white;
			color: $default-cornflower-blue;
		}

		&:not(:last-of-type) {
			margin-right: 4px;
		}

		&:not(.active-tab-js) {
			&:hover,
			&:active {
				color: $default-cornflower-blue;
			}
		}

		&:not(.active-tab-js):focus {
			background-color: rgb(255 255 255 / 65%);
		}
	}

	&__subtitle {
		@extend .tabs__title;
		white-space: nowrap;

		&:not(.active-tab-js):focus {
			color: $default-cornflower-blue;
		}
	}

	&__content {
		background-color: $tab-content__bg-color;
		display: none;
		padding: 2vmin 2vw;

		&.active-tab-item {
			display: block;
		}

		&.active-subtab-item {
			display: block;
			height: 56px;
			position: absolute;
			top: 56px;
			width: 100%;
			z-index: 3;
		}

		&_top {
			padding: 1.5rem 0;
		}

		&_right {
			border-radius: $tabs__border-radius 0 $tabs__border-radius $tabs__border-radius;

			&:not(:last-of-type) {
				border-radius: $tabs__border-radius;
			}
		}

		&_left {
			border-radius: 0 $tabs__border-radius $tabs__border-radius $tabs__border-radius;

			&:not(:first-of-type) {
				border-radius: $tabs__border-radius;
			}
		}

		&-list {
			border-radius: 0;
		}

		&_empty {
			&.active-tab-item {
				& ~ [data-btn='tabs-btn'],
				.dataTables_wrapper {
					display: none;
				}

				.tabs__notice {
					@include flex(
							$justify: flex-start
					);
				}
			}
		}
	}

	&__title-list {
		font-size: 0;
		margin-bottom: 0;
	}

	&__notice {
		display: none;

		&-icon {
			background-image: url(../images/icons/icons-sprite.svg);
			background-position: -264px -50px;
			background-repeat: no-repeat;
			background-size: auto;
			content: '';
			display: inline-block;
			margin-right: 1rem;
			height: 33px;
			width: 33px;
		}

		&-text {
			font-size: $font-size__light;
			width: calc(100% - 33px - 1rem);
		}
	}
}

.tabs-btn {
	&__container {
		padding-top: 1rem;
	}

	&__title-list {
		display: flex;
		flex-wrap: nowrap;
		padding: .5rem;
	}

	&__title {
		border: 2px solid rgba(99, 117, 233, 0.6);
		border-radius: 6px;
		color: $default-cornflower-blue;
		font-size: clamp(1.4rem, 0.5143rem + 0.8929vw, 1.8rem);
		font-weight: $font-weight__most-bold;
		margin-right: 1.2rem;
		padding: 1.3rem 2rem;
		text-transform: uppercase;

		&.active-tab-js {
			background-color: #6375E9;
			color: #fff;
		}

		&:hover,
		&:active {
			background-color: $default-cornflower-blue;
			color: $default-white;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

/*------Mobile media queries------*/
@media screen and (max-width: #{$screen__l - 1px}) {
	.tabs-btn {
		&__title-list {
			overflow-x: auto;
			overflow-y: hidden;
			-ms-overflow-style: none; /* for Internet Explorer, Edge */
			scrollbar-width: none; /* for Firefox */

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__title {
			white-space: nowrap;
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.tabs {
		&-selector__block {
			display: block;
			margin-left: -$content-wrapper__padding_mobile;
			width: calc(100% + 2 * $content-wrapper__padding_mobile);
		}

		&__content {
			&_top {
				padding-bottom: 1rem;
				text-align: center;
			}

			margin-left: -$content-wrapper__padding_mobile;
			margin-right: -$content-wrapper__padding_mobile;

			&_left,
			&_right {
				border-radius: 0;

				&:not(:first-of-type),
				&:not(:last-of-type) {
					border-radius: 0;
				}
			}
		}

		&__title-list {
			display: none;
		}
	}
}

@media screen and (max-width: $screen__s) {
	.tabs {
		&__content {
			padding-left: $content-wrapper__padding_mobile;
			padding-right: $content-wrapper__padding_mobile;
		}
	}
}

/*------Desktop media queries------*/
@media screen and (min-width: $screen__xl) {
	.tabs-btn__title {
		white-space: nowrap;
	}
}