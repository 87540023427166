$date-col__width: 110px;
$author-col__width: 20%;
$file-item-col_width: 30%;
$attach-flex_gap: 1.5vw;

.attach {
	&-row {
		@include flex(
				$justify: flex-start,
				$align: flex-start
		);
		border-bottom: 1px solid $default-athens-gray;
		box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		width: 100%;

		&__content {
			@include flex(
					$align: flex-start
			);
			flex-grow: 1;
			gap: $attach-flex_gap;
		}

		&__actions {
			padding-top: 6px;
			padding-bottom: 1rem;
		}
	}

	&-cell {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	&__files-list {
		@include flex(
				$direction: column,
				$justify: flex-start,
				$align: flex-start
		);

		.attach__file-item {
			width: 100%;
		}
	}

	&__file-item {
		@include flex(
				$justify: flex-start,
				$align: center
		);
		padding: .5em 0;
	}

	&__file {
		width: $file-item-col_width;
	}

	&__file-label {
		margin: 0 1vw 0 0;
		cursor: pointer;
		width: auto;

		&:active {
			transform: scale(.9);
		}
	}

	&__file-input {
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&__file-img {
		margin-right: .5rem;
	}

	&__file-name {
		margin-right: 1vw;
		max-width: 45%;
		@include hideOverflowText;
	}

	&__download-link {
		margin-right: 1vw;
		padding: 3px;
	}

	&__file-size {
		color: $default-comet;
		margin-right: .7vw;
		position: relative;
		white-space: nowrap;

		&:before {
			content: '/';
			position: absolute;
			left: -0.9vw;
			top: 0;
		}
	}

	&__author {
		width: $author-col__width;
	}

	&__date {
		width: $date-col__width;
	}

	&__comment {
		width: calc(100% - $date-col__width - $author-col__width - $file-item-col_width - 3 * $attach-flex_gap);
	}

	&__filter {
		padding: 2rem 0;
	}
}

/*------Mobile media queries------*/
@media screen and (max-width: #{$screen__l - 1px}) {
	.attach {
		&-row {
			flex-direction: column;
			margin-bottom: 1.5rem;
			padding-bottom: 1rem;

			&__actions {
				margin-left: auto;
			}

			&__content {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.attach {
		&-row {
			&__content {
				flex-wrap: wrap;
				gap: 0;
			}
		}

		&__file {
			order: 1;
			width: 100%;

			&-item {
				flex-wrap: wrap;
			}

			&-label {
				margin-right: 1rem;
			}

			&-name {
				margin-right: 1rem;
				padding: 1.5rem 0;

				&_old {
					max-width: 50%;
					width: auto;
				}

				&_new {
					max-width: unset;
					width: calc(100% - 1rem - 22px);
				}
			}

			&-size {
				&:before {
					left: -0.9rem;
				}
			}
		}

		&__comment {
			order: 2;
			width: 100%;
		}

		&__author {
			width: calc(100% - $date-col__width);
		}

		&__date {
			padding-left: 1rem;
		}

		&__download-link {
			margin-right: 1rem;
			max-width: 60%;
			padding: 1.5rem 0;

			&:last-child {
				max-width: 80%;
			}
		}

		&__filter {
			width: 100%;
		}
	}
}
