$country-france__color: $default-safety-orange;
$country-morocco__color: $default-pelorous;
$country-ukraine__color: $default-gamboge;
$country-netherlands__color: $default-mulberry;
$country-switzerland__color: $default-fire-engine-red;
$country-belgium__color: $default-eucalyptus;
$country-poland__color: $default-amaranth;
$country-germany__color: $default-disco;

.countries-page {
	&__sidebar {
		@include flex(
			$direction: column,
			$align: flex-start
		);
		background-color: $default-white;
		height: 100%;
		min-height: 100vh;
		overflow-y: auto;
		padding: 3vh 2.5vmin 3vh 3vmin;
		position: relative;
		width: 35%;
		z-index: 1;
	}
}

.sidebar {
	&__header {
		padding-bottom: 2rem;
		width: 80%;
	}

	&__list {
		margin: 0;
	}

	&__link {
		border-radius: $link__border-radius;
		font-size: clamp(1.6rem, 0.6154rem + 0.9615vw, 2rem);
		padding: $link__padding;
		text-decoration: none;
		transition: $default-transition;

		&:focus {
			outline: none;
			box-shadow: $default-shadow;
		}
	}
}

.countries {
	&__list {
		@include flex(
			$justify: flex-start,
			$align: flex-start,
			$wrap: wrap
		);
		gap: 1rem 2vw;
	}

	&__item-name {
		color: $default-jaguar;
		text-transform: uppercase;

		&:hover {
			&[data-country="france"] {
				color: $country-france__color;
			}

			&[data-country="morocco"] {
				color: $country-morocco__color;
			}

			&[data-country="ukraine"] {
				color: $country-ukraine__color;
			}

			&[data-country="netherlands"] {
				color: $country-netherlands__color;
			}

			&[data-country="switzerland"] {
				color: $country-switzerland__color;
			}

			&[data-country="belgium"] {
				color: $country-belgium__color;
			}

			&[data-country="poland"] {
				color: $country-poland__color;
			}

			&[data-country="germany"] {
				color: $country-germany__color;
			}
		}
	}
}

.company__list-item {
	padding: .5rem 0;

	&:hover {
		.smile-comp {
			&:after {
				background-position: 0px -176px;
			}
		}

		.creative-comp {
			&:after {
				background-position: -293px -102px;
			}
		}
	}
}

.companies {
	&__list {
		@include flex(
			$align: flex-start,
			$direction: column
		);

		&:not(.country-popup__list) {
			flex-grow: 1;
			padding: 2rem 0;
		}
	}

	&__item-name {
		align-items: center;
		display: inline-flex;
		font-size: 0;

		&:after {
			background-image: url("../images/logos/logo-sprite.svg");
			background-repeat: no-repeat;
			background-size: auto;
			content: '';
			display: inline-block;
		}

		&.active {
			&.smile-comp {
				&:after {
					background-position: 0px -176px;
				}
			}

			&.creative-comp {
				&:after {
					background-position: -293px -62px;
				}
			}
		}
	}
}

.smile-comp {
	&:after {
		background-position: 0px -140px;
		height: 35px;
		width: 115px;
	}

	&.country-popup__link {
		&:hover,
		&:focus {
			&:after {
				background-position: 0px -176px;
			}
		}
	}
}

.neopixl-comp {
	&:after {
		background-position: -171px -1px;
		height: 25px;
		width: 104px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -171px -27px;
		}
	}
}

.creative-comp {
	&:after {
		background-position: -293px -63px;
		height: 32px;
		width: 166px;
	}

	&.country-popup__link {
		&:hover,
		&:focus {
			&:after {
				background-position: -293px -102px;
			}
		}
	}
}

.uxrepublic-comp {
	&:after {
		background-position: -158px -59px;
		height: 41px;
		width: 131px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -158px -101px;
		}
	}
}

.sensiolabs-comp {
	&:after {
		background-position: -8px -4px;
		height: 24px;
		width: 151px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -8px -33px;
		}
	}
}

.alterway-comp {
	&:after {
		background-position: -289px -1px;
		height: 30px;
		width: 162px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -289px -31px;
		}
	}
}

.synotis-comp {
	&:after {
		background-position: -117px -142px;
		height: 31px;
		width: 85px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -117px -175px;
		}
	}
}

.symfony-comp {
	&:after {
		background-position: -1px -61px;
		height: 38px;
		width: 153px;
	}

	&:hover,
	&:focus {
		&:after {
			background-position: -1px -100px;
		}
	}
}

.sidebar__footer {
	&-links {
		@include flex(
			$justify: flex-start,
			$wrap: wrap
		);
		gap: 1.5rem 2vw;
		margin: 0;
	}

	&-link {
		display: inline-block;
		color: $default-comet;
		font-size: $font-size__regular;
		text-decoration: underline;

		&:hover,
		&:focus {
			&[data-gescom="inter"] {
				color: $default-royal-blue;
			}

			&[data-gescom="corp"] {
				color: $default-free-speech-blue;
			}

			&[data-gescom="group"] {
				color: $default-manatee2;
			}

			&[data-gescom="training"],
			&[data-gescom="training2"] {
				color: $default-cornflower-blue;
			}

			&[data-gescom="smiler&d"] {
				color: $default-dark-violet;
			}
		}
	}
}

#countries {
	right: 0;
	width: 100%;

	.country-path:hover {
		cursor: pointer;
	}
}

.country {
	&-popup {
		background-color: $default-white;
		border-radius: 0 1rem 1rem 1rem;
		display: inline-block;
		padding: 2rem 1.5vw;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		z-index: 1;

		&__list {
			gap: 2rem;
			margin: 0;

			&.companies__list {
				align-items: center;
			}
		}
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__l - 1px}) {
	#countries {
		position: absolute;
		top: 50%;
		height: auto;
		transform: translateY(-50%);
	}

	.countries-page {
		background-color: $default-white;

		&__sidebar {
			width: 100%;
		}
	}

	.companies__item-name {
		&.smile-comp {
			&:after {
				background-position: 0px -176px;
			}
		}

		&.alterway-comp {
			&:after {
				background-position: -289px -31px;
			}
		}

		&.creative-comp {
			&:after {
				background-position: -293px -102px;
			}
		}

		&.neopixl-comp {
			&:after {
				background-position: -171px -27px;
			}
		}

		&.sensiolabs-comp {
			&:after {
				background-position: -8px -33px;
			}
		}

		&.uxrepublic-comp {
			&:after {
				background-position: -158px -101px;
			}
		}

		&.synotis-comp {
			&:after {
				background-position: -117px -175px;
			}
		}

		&.symfony-comp {
			&:after {
				background-position: -1px -100px;
			}
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.sidebar {
		&__header {
			width: 60%;
		}
	}

	.countries-page {
		&__wrapper {
			height: 100vh;
			background-color: $default-white;
		}

		&__sidebar {
			padding: 4vh 4vmin 2vh;
		}
	}

	.companies {
		&__list {
			gap: 2rem;
		}
	}
}

@media screen and (max-height: 600px) {
	.countries-page {
		&__wrapper {
			height: 100%;
			position: relative;
		}
	}

	.countries__list,
	.companies__list {
		height: auto;
	}

	.countries__list-item,
	.company__list-item {
		padding: .8rem 0;
	}

	.sidebar__footer-links {
		padding-top: .5rem;
	}
}

/*------Desktop media queries------*/

@media only screen and (min-width: $screen__xl) {
	.countries-page__sidebar {
		padding: 4rem 6rem 4rem 4rem;
	}
}

@media only screen and (min-width: $screen__l) {
	#countries {
		position: fixed;
		left: 0;
		bottom: 0;
		top: 0;
		height: 100%;
	}

	.countries-page {
		&__wrapper {
			height: 100vh;
		}
	}
}