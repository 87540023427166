$link-default__color: $default-aluminium;
$link-default_hover__color: $default-jumbo;
$link__padding: 4px;
$link__border-radius: 6px;

.link {
	border-radius: $link__border-radius;
	display: inline-block;
	padding: $link__padding;
	position: relative;
	transition: $default-transition;

	&:before {
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover,
	&:active {
		text-decoration: none;
	}

	&:focus {
		box-shadow: $default-shadow;
		outline: none;
	}

	&_default {
		color: $link-default__color;

		&:hover,
		&:active {
			color: $link-default_hover__color;
		}
	}

	&_primary {
		color: $link-primary__color;

		&:hover,
		&:active {
			color: $link-primary_hover__color;
			text-decoration: underline;
		}
	}

	&_overflow {
		@include hideOverflowText;
	}
}

.main-link,
.country-link {
	display: inline-block;
	padding: $link__padding;
	color: inherit;
	transition: $default-transition;
	border-radius: $link__border-radius;

	&:hover {
		text-decoration: none;
		color: inherit;
	}

	&:focus {
		box-shadow: $default-shadow;
	}
}

.add-link {
	font-size: clamp(1.2rem, 0.8675rem + 0.3247vw, 1.4rem);
}

.support-link {
	line-height: 16px;
	text-transform: uppercase;

	.combo-link__icon {
		right: 1rem;
	}

	.combo-link__text {
		font-weight: $font-weight__most-bold;
		padding-left: 0;
		padding-right: 2.5rem;
	}
}

.back-link {
	font-size: 1.3rem;

	&__wrapper {
		display: inline-block;
		margin-top: 1.2rem;
	}
}

.btn-link {
	@extend .gescom-btn;
	@extend .btn_secondary;
	@extend .btn_large;

	&:hover {
		color: $btn-secondary__color;
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;
	}
}

.combo-link {
	position: relative;

	&__icon {
		@extend .btn-combo__icon;
	}

	&__text {
		@extend .btn-combo__text;
		font-weight: $font-weight__bold;
	}

	&:hover:not(:focus),
	&:active {
		.btn-combo__text {
			text-decoration: underline;
		}
	}
}

.contributed-link {
	@extend .gescom-btn;
	@extend .btn_primary;
	border-radius: 38px;
	padding: 1.2rem 2.1rem 1rem;
	width: 100%;

	&:hover {
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;

		&:not(:active) {
			color: $default-white;
		}
	}

	&__title {
		font-size: $font-size__light;
		font-weight: $font-weight__regular;
		line-height: 1.4rem;
		margin: 0;
	}

	&__value {
		font-size: clamp(1.4rem, 0.7560rem + 0.6289vw, 1.8rem);
		line-height: 2.2rem;
		margin: 0;
	}

	&__warning {
		background-color: $default-safety-orange;
		border-color: $default-safety-orange;

		&:hover:not(:focus):not(.btn_disabled) {
			background-color: $default-persimmon;
			border-color: $default-persimmon;
		}

		&:active:not(.btn_disabled) {
			background-color: $default-tawny;
			border-color: $default-tawny;
		}
	}
}

a.cell-link {
	color: $default-color-text;
	border-radius: 6px;
	text-decoration: underline;
	transition: $default-transition;
	padding: $link__padding;
	position: relative;
	white-space: nowrap;

	&:hover {
		color: $link-primary__color;
	}

	&:focus {
		box-shadow: $default-shadow;
		outline: none;
	}

	&:active {
		color: $link-primary_hover__color;
	}
}

a.btn-picto {
	&:focus {
		color: $default-cornflower-blue;
		text-decoration: none;
	}
}
