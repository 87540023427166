/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/roboto-v27-latin-regular.eot");
  src: local(""), url("../fonts/Roboto/roboto-v27-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/roboto-v27-latin-regular.woff2") format("woff2"), url("../fonts/Roboto/roboto-v27-latin-regular.woff") format("woff"), url("../fonts/Roboto/roboto-v27-latin-regular.ttf") format("truetype"), url("../fonts/Roboto/roboto-v27-latin-regular.svg#Raleway") format("svg");
  font-weight: 400;
  font-style: normal;
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/roboto-v27-latin-500.eot");
  src: local(""), url("../fonts/Roboto/roboto-v27-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/roboto-v27-latin-500.woff2") format("woff2"), url("../fonts/Roboto/roboto-v27-latin-500.woff") format("woff"), url("../fonts/Roboto/roboto-v27-latin-500.ttf") format("truetype"), url("../fonts/Roboto/roboto-v27-latin-500.svg#Raleway") format("svg");
  font-weight: 500;
  font-style: normal;
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/roboto-v27-latin-700.eot");
  src: local(""), url("../fonts/Roboto/roboto-v27-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/roboto-v27-latin-700.woff2") format("woff2"), url("../fonts/Roboto/roboto-v27-latin-700.woff") format("woff"), url("../fonts/Roboto/roboto-v27-latin-700.ttf") format("truetype"), url("../fonts/Roboto/roboto-v27-latin-700.svg#Raleway") format("svg");
  font-weight: 700;
  font-style: normal;
}
/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/roboto-v27-latin-900.eot");
  src: local(""), url("../fonts/Roboto/roboto-v27-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/roboto-v27-latin-900.woff2") format("woff2"), url("../fonts/Roboto/roboto-v27-latin-900.woff") format("woff"), url("../fonts/Roboto/roboto-v27-latin-900.ttf") format("truetype"), url("../fonts/Roboto/roboto-v27-latin-900.svg#Raleway") format("svg");
  font-weight: 900;
  font-style: normal;
}
h1 {
  color: #6375E9;
  font-size: clamp(2rem, 0.6701rem + 1.2987vw, 2.8rem);
  font-weight: 900;
  line-height: 3rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

h2 {
  font-size: clamp(1.4rem, 0.7351rem + 0.6494vw, 1.8rem);
  font-weight: 700;
  line-height: 2.2rem;
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

html {
  font-size: 10px;
}

#main-page {
  z-index: auto;
}
#main-page.shadowed-js .page__header,
#main-page.shadowed-js .page__main,
#main-page.shadowed-js .page__footer {
  position: relative;
}
#main-page.shadowed-js .page__header:before,
#main-page.shadowed-js .page__main:before,
#main-page.shadowed-js .page__footer:before {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
  width: 100%;
  left: 0;
  z-index: 10;
}

body {
  background-color: #F2F2F2;
  color: #353549;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
}
body .hide-elem-js {
  display: none;
}

.content__wrapper {
  width: 100%;
  max-width: 1770px;
  margin: 0 auto;
  padding: 0 3vw;
}

.main__container {
  padding-bottom: 4rem;
}

.pos_rb {
  bottom: 5px;
  right: -3px;
}

.pos_rm {
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.page__main {
  padding-top: 1.5rem;
}
.page__title {
  padding: 2rem 0;
  margin: 0;
}
.page__title_higher {
  padding: 3rem 0;
}
.page__subtitle {
  font-size: clamp(1.4rem, 0.4154rem + 0.9615vw, 1.8rem);
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.page__buttons-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1.6rem;
  padding: 3rem 0;
  width: 100%;
}
.page__footer {
  padding: 3rem 0;
}

.top__wrapper {
  padding: 2.9rem 0 1.5rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.footer__wrapper {
  position: relative;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.footer__logo_desktop {
  width: 57vw;
  max-width: 750px;
}
.footer__logo_mobile {
  display: none;
}

.logo-block {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: 0;
}

.align-v_middle {
  display: inline-flex;
  align-items: center;
}
.align-h_right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}
.align_center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text_right {
  text-align: right;
}
.text_bolder {
  font-weight: bolder;
}
.text-alert {
  color: #FF0544;
}

.content__wrapper.footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support-link__wrapper {
  text-align: right;
}

.site_version_block .version {
  color: #6375E9;
  font-weight: 700;
  padding: 6px 28px 6px 8px;
}

.main-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 3vw;
}
.main-info_center {
  position: relative;
}
.main-info_center:before, .main-info_center:after {
  background-color: #CCCDD1;
  content: "";
  height: 80%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 1px;
}
.main-info_center:before {
  left: -1.5vw;
}
.main-info_center:after {
  right: -1.5vw;
}
.main-info__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}

.actions__btn {
  display: none;
}
.actions__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
  margin: 0;
}

.action__item_empty {
  height: 32px;
  width: 30px;
}
.action__label {
  display: none;
  color: #6375E9;
  font-weight: 700;
  padding-left: 1.2rem;
}

.section__subtitle {
  background-color: inherit;
  color: #6375E9;
  position: relative;
  z-index: 0;
  font-size: clamp(1.4rem, 0.4154rem + 0.9615vw, 1.8rem);
  font-weight: 700;
  margin-bottom: 1.5vmin;
}
.section__subtitle:after {
  background-color: #6375E9;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: -1;
}
.section__subtitle .subtitle__text {
  background-color: inherit;
  padding-right: 2rem;
}

.loader-overlay {
  overflow: hidden;
  position: relative;
}
.loader-overlay:after {
  background: #f2f2f2;
  background: radial-gradient(circle, #e0e0e0 20%, #f2f2f2 100%);
  bottom: 0;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 7;
}
.loader-overlay:before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../images/spinner-250px.gif");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 20%;
  left: 50%;
  max-height: 80px;
  max-width: 80px;
  width: 10%;
  z-index: 8;
}

.scroll__wrapper {
  position: relative;
}
.scroll__wrapper.show-chevron-js:after {
  background-color: transparent;
  content: "";
  height: 30px;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -8rem);
  width: 30px;
  background-image: url(../images/icons/down-shevron.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  right: -1rem;
  animation: scroll-down 1s linear infinite;
}

@keyframes scroll-down {
  0% {
    top: 100%;
  }
  50% {
    top: calc(100% - 15px);
  }
  100% {
    top: 100%;
  }
}
/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .top__wrapper {
    padding: 5vmin 0 2vmin;
  }

  .logo-block {
    position: relative;
    left: unset;
    padding-bottom: 4rem;
    top: unset;
    transform: none;
    width: 100%;
  }

  .footer__wrapper {
    flex-wrap: wrap;
  }
  .footer__logo_desktop {
    display: none;
  }
  .footer__logo_mobile {
    display: block;
    margin: 0 auto;
  }

  .site_version_block,
.support-link__wrapper {
    order: 2;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .content__wrapper {
    padding: 0 2vw;
  }

  .top__wrapper {
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
  }

  .main-actions__container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .main-actions__container .action__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .main-actions__container .action__label {
    display: inline-block;
  }
  .main-actions__container .btn-picto .icon {
    margin-right: 4px;
  }

  .actions__list_modal {
    align-items: flex-start;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
    display: none;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 3rem 2.5rem 2rem;
    position: absolute;
    right: 21px;
    top: 21px;
    z-index: -1;
  }
  .actions__list_modal.show-js {
    display: flex;
  }

  .page__title {
    padding-top: 1.8rem;
  }
  .page__title[data-action=update-title] {
    display: none;
  }
  .page__buttons-block {
    flex-wrap: wrap;
    justify-content: center;
  }

  .main-info {
    flex-wrap: wrap;
  }
  .main-info_left, .main-info_center, .main-info_rigth {
    width: 100%;
  }
  .main-info_left {
    padding-right: calc(42px + 1rem);
  }
  .main-info_center:before, .main-info_center:after {
    content: none;
  }
}
@media screen and (max-width: 480px) {
  .site_version_block .version {
    padding: 0;
  }
}
[class^=grid__col] {
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-self: stretch;
}

.grid__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}
.grid__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: nowrap;
  width: 100%;
}
.grid__col-12 {
  width: 100%;
}
.grid__col-11 {
  width: 91.67%;
}
.grid__col-10 {
  width: 83.33%;
}
.grid__col-9 {
  width: 75%;
}
.grid__col-8 {
  width: 66.67%;
}
.grid__col-7 {
  width: 58.33%;
}
.grid__col-6 {
  width: 50%;
}
.grid__col-5 {
  width: 41.67%;
}
.grid__col-4 {
  width: 33.33%;
}
.grid__col-3 {
  width: 25%;
}
.grid__col-2 {
  width: 16.66%;
}
.grid__col-1 {
  width: 8.33%;
}
.grid__col_center {
  align-self: center;
}
.grid__cell_pl {
  padding-left: 1rem;
}
.grid__cell_pr {
  padding-right: 1rem;
}
.grid__cell_px {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.cell__title {
  font-weight: 700;
}
.cell__subtitle {
  font-size: 1.2rem;
  color: #676876;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.cell__content {
  font-size: 1.4rem;
  margin: 0;
  word-break: break-word;
}
.cell__content_important {
  font-weight: 700;
  color: #02031B;
}
.cell__link-container {
  margin-top: -4px;
}

.row-item__title {
  color: #02031B;
  padding-left: 1rem;
}
.row-item__date {
  color: #02031B;
  padding-left: 1rem;
  text-align: right;
}
.row-item__content {
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1.5rem;
}
.row-item__files {
  margin-left: auto;
  padding-left: 1rem;
}

.row__border_bottom {
  border-bottom: 1px solid #E8E8EB;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  [class^=grid__col] {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .grid__col-12.grid__cell_pr, .grid__col-12.grid__cell_pl {
    padding-left: 0;
    padding-right: 0;
  }

  .row-item__img {
    width: 42px;
  }
  .row-item__date {
    padding-left: 0.5rem;
    width: 25%;
  }
  .row-item__title {
    width: calc(100% - 42px - 25%);
  }
  .row-item__content {
    width: 66.67%;
    margin-left: 0;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
  .row-item__buttons {
    width: 33.33%;
    padding-left: 1rem;
  }
  .row-item__files {
    width: 100%;
    padding-left: 0.5rem;
  }
}
@media screen and (max-width: 480px) {
  .row-item .row-item__content {
    width: 83.33%;
  }
  .row-item .row-item__buttons {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    width: 16.66%;
  }
  .row-item .row-item__buttons:before {
    height: 100%;
  }
  .row-item .row-item__buttons .btn-picto {
    margin: 0.3rem 0;
  }
}
form {
  position: relative;
}

.form__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 3vw;
}
.form__row, .form__row_low {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-bottom: 2.2rem;
  width: 100%;
}
.form__row_low {
  padding-bottom: 0;
}
.form__row .form__col .form__row:last-child, .form__row_low .form__col .form__row:last-child, .form__row .form__col .form__row_low:last-child, .form__row_low .form__col .form__row_low:last-child {
  padding-bottom: 0;
}
.form__col {
  width: 100%;
  position: relative;
}
.form__col-2 {
  width: 50%;
  position: relative;
}
.form__col-3 {
  width: 33.33%;
  position: relative;
}
.form__col_many {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  align-self: stretch;
}
.form__col_xxlarge {
  width: 66.67%;
  position: relative;
}
.form__col_xlarge {
  width: 60%;
  position: relative;
}
.form__col_large {
  width: 50%;
  position: relative;
}
.form__col_large[data-container=addition] {
  padding-left: 1.5vw;
}
.form__col_medium {
  width: 45%;
  position: relative;
}
.form__col_normal {
  width: 35%;
  position: relative;
}
.form__col_small {
  width: 30%;
  position: relative;
}
.form__col_xsmall {
  width: 25%;
  position: relative;
}
.form__col_xxsmall {
  width: 20%;
  position: relative;
}
.form__col_left {
  padding-right: 3vw;
}
.form__col_left-small {
  padding-right: 1.5vw;
}
.form__col_right {
  padding-left: 3vw;
}
.form__col_right-small {
  padding-left: 1.5vw;
}
.form__col_center {
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}
.form__col_high {
  min-height: 255px;
}
.form__col_grow {
  flex-grow: 1;
}
.form__col_vertical {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.form__col_align {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.form__col_align-top .form__col_align,
.form__col_align-top .form__col_align-bottom {
  justify-content: flex-start;
}
.form__col_align-top .form__col_align-bottom {
  margin-top: 2.5rem;
}
.form__col_align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.form__col-error .form__message {
  position: relative;
  transform: unset;
}
.form__col_date .input-group.date .input-group-addon {
  height: 40px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.form__col_date .form__date {
  padding-left: 15px;
  padding-right: 35px;
}
.form__col_date .form__date:focus + .input-group-addon {
  z-index: 3;
}
.form__col_new {
  margin-bottom: 2.2rem;
  position: relative;
  width: 100%;
}
.form__set {
  background-color: #F2F2F2;
  padding: 10px 0 0;
  width: 100%;
}
.form__set_hidden {
  display: none;
  padding: 1.2rem 0 0;
}
.form__subtitle {
  background-color: inherit;
  color: #6375E9;
  position: relative;
  z-index: 0;
  font-size: clamp(1.6rem, 1.2675rem + 0.3247vw, 1.8rem);
  margin-bottom: 15px;
  font-weight: 700;
}
.form__subtitle:after {
  background-color: #6375E9;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: -1;
}
.form__subtitle .subtitle__text {
  background-color: inherit;
  padding-right: 2rem;
}
.form__link-box {
  align-self: flex-end;
  font-weight: 700;
  margin-bottom: 0.4rem;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}
.form__input, .form__date {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  font-size: 1.4rem;
  height: 40px;
  padding: 0.5rem 1.2rem 0.5rem 1rem;
  width: 100%;
}
.form__input:hover, .form__date:hover {
  border-color: #97989F;
}
.form__input:focus, .form__date:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #97989F;
  opacity: 1;
  /* Firefox */
}
.form__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #97989F;
}
.form__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #97989F;
}
.form__input_short {
  flex-grow: 1;
  width: auto;
}
.form__input-annex {
  box-sizing: border-box;
  padding-left: 0.5rem;
  text-align: right;
  width: 4rem;
}
.form__btn {
  border-radius: 30px;
  min-width: 135px;
  padding: 0.9rem 2rem;
}
.form__btn:active:not(.btn_disabled) {
  transform: scale(0.92);
}
.form__error-field {
  border-color: #FF0544 !important;
}
.form__label {
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 5px;
}
.form__label_required {
  position: relative;
  margin-left: 1.4rem;
}
.form__label_required:before {
  content: "*";
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  left: -11px;
  color: #FF0544;
}
.form__label_large {
  width: 100%;
}
.form__label_vertical {
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: auto;
}
.form__label_disable {
  color: #676876;
}
.form__area {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  resize: none;
  min-height: 160px;
  overflow-y: auto;
  width: 100%;
}
.form__area:hover {
  border-color: #97989F;
}
.form__area:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__message {
  font-size: 1.2rem;
  line-height: normal;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  width: 100%;
}
.form__message_short {
  padding-right: 4rem;
}
.form__message .text-danger {
  color: #FF0544 !important;
}
.form__message .text-danger:not(:empty) {
  display: inline-block;
  padding-top: 0.6rem;
}
.form__notice {
  color: #676876;
  font-style: italic;
  margin-left: 1.4rem;
}
.form__value {
  font-weight: 700;
  margin-left: 1.4rem;
}
.form__input:not([readonly]):hover, .form__date:hover, .form__area:hover {
  border-color: #97989F;
}
.form__input:not([readonly]):focus, .form__area:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__input[readonly]:hover, .form__input[readonly]:focus {
  cursor: default;
}
.form__date:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__select.bootstrap-select {
  border-radius: 6px;
  height: 40px;
  width: 100%;
}
.form__select.bootstrap-select.form__select-multiple {
  height: auto;
}
.form__select > .dropdown-toggle {
  height: 100%;
  border-radius: 6px;
  font-size: 1.4rem;
  padding-right: 4rem;
  padding-left: 1rem;
}
.form__select.form__number > .dropdown-toggle {
  font-weight: 400;
}
.form__select .dropdown-toggle:after {
  top: 50%;
  left: 100%;
  transform: translate(-30px, -50%);
  background-image: url("../images/icons/arrow-down.svg");
}
.form__select .dropdown-toggle.btn-light:focus {
  outline: none !important;
}
.form__select .dropdown-toggle .filter-option-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
}
.form__select.show .dropdown-toggle:after {
  background-image: url("../images/icons/arrow-up.svg");
}
.form__select.show .dropdown-toggle:focus {
  outline: none !important;
}
.form__select.show > .btn-light.dropdown-toggle {
  background-color: #FFFFFF;
  border-color: #E0E0E0;
  border-bottom-color: transparent;
  border-radius: 6px 6px 0 0;
  z-index: 9;
}
.form__select.show > .btn-light.dropdown-toggle:not(.disable-element):focus {
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__select > .dropdown-toggle.bs-placeholder:active, .form__select > .dropdown-toggle.bs-placeholder:hover {
  color: #97989F;
  border-color: #97989F;
}
.form__select > .dropdown-toggle.bs-placeholder:not(.disable-element):focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__select > .btn-light {
  background-color: #FFFFFF;
  border: 1px solid #E8E8EB;
  color: #353549;
}
.form__select > .btn-light:hover, .form__select > .btn-light:active {
  background-color: #FFFFFF !important;
  border-color: #97989F;
}
.form__select .dropdown-menu {
  font-size: 1.4rem;
  margin-top: 0;
  padding-top: 0;
  border-radius: 0 0 6px 6px;
  top: -1px !important;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  clip-path: inset(0 -4px -4px -4px);
}
.form__select .dropdown-menu .inner.show {
  overflow-x: hidden;
}
.form__select .dropdown-menu .inner.show::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.form__select .dropdown-menu .inner.show::-webkit-scrollbar-track {
  background-color: #E8E8EB;
}
.form__select .dropdown-menu .inner.show::-webkit-scrollbar-thumb {
  background-color: #6375E9;
}
.form__select .dropdown-menu .dropdown-divider {
  position: absolute;
  top: -5px;
  left: 12px;
  border-top: 1px solid #E8E8EB;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  width: calc(100% - 24px);
  z-index: 1;
}
.form__select .dropdown-menu .dropdown-header {
  font-size: 1.6rem;
  font-weight: bold;
  color: #02031B;
}
.form__select > .dropdown-menu {
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 9;
}
.form__select.select__search > .dropdown-menu {
  transform: translate3d(0px, 3px, 0px) !important;
}
.form__select .dropdown-divider {
  top: 44px;
}
.form__select .dropdown-item {
  color: #353549;
  padding: 0.8rem 2.2rem;
}
.form__select .dropdown-item.active {
  background-color: #F8F8F8;
  color: #6375E9;
  font-weight: 700;
}
.form__select .dropdown-item.active.selected {
  background-color: #F2F2F2;
}
.form__select .dropdown-item:focus, .form__select .dropdown-item:hover, .form__select .dropdown-item.active:hover {
  background-color: #F8F8F8;
}
.form__select-multiple .dropdown-toggle {
  display: none;
}
.form__select-multiple .dropdown-item {
  padding: 6px 20px 6px 60px;
  position: relative;
}
.form__select-multiple .dropdown-item:before {
  position: absolute;
  content: "";
  left: 22px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #FFFFFF;
  border: 1px solid #E8E8EB;
  box-sizing: border-box;
  border-radius: 3px;
}
.form__select-multiple > .dropdown-menu li {
  padding: 6px 0;
}
.form__select-multiple > .dropdown-menu .no-results {
  padding-left: 16px;
}
.form__select-multiple .bs-searchbox {
  position: relative;
  padding: 7px 0 7px 8px;
  margin-bottom: 10px;
}
.form__select-multiple .bs-searchbox:before {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-3px, -50%);
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("../images/icons/search-select.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
.form__select-multiple .bs-searchbox.focuses:before {
  background-image: url("../images/icons/search-select-action.svg");
}
.form__select.form__select-multiple > .dropdown-menu {
  display: block !important;
  box-sizing: content-box;
  position: relative !important;
  padding: 0 12px 10px 0;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  top: -3px !important;
  width: calc(100% - 14px);
  min-width: calc(100% - 14px);
  min-height: 252px;
  z-index: 8;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  clip-path: none;
}
.form__select.form__select-multiple > .dropdown-menu:hover {
  border-color: #97989F;
}
.form__select.form__select-multiple > .dropdown-menu:focus-within {
  outline: none;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.form__select.form__select-multiple > .dropdown-menu:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 48px;
  left: 0;
  background-color: #E8E8EB;
}
.form__check {
  display: none;
}
.form__check:checked + input[type=hidden] + .label__check:before {
  background-color: #6375E9;
  border-color: #6375E9;
}
.form__check:checked + input[type=hidden] + .label__check:after {
  background-color: transparent;
  content: "";
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;
  background-image: url("../images/icons/check-white.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  left: 5px;
}
.form__check[data-state=undefined] + input[type=hidden] + .label__check.label__check_all:before {
  background-color: #6375E9;
  background-clip: content-box;
  padding: 2px;
}
.form__check[data-state=undefined] + input[type=hidden] + .label__check.label__check_all:after {
  content: "";
  position: absolute;
  background-image: none;
  background-color: #FFFFFF;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;
  height: 3px;
  border-radius: 2px;
  display: inline-block;
}
.form__radio {
  display: none;
}
.form__radio:checked + .label__radio:before {
  background-color: transparent;
}
.form__radio:checked + .label__radio:after {
  background-color: #6375E9;
  content: "";
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  border-radius: 50%;
  left: 3px;
}
.form__tag-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.form__tag.customer__tag {
  margin: 0;
}
.form__tag.selected-js {
  color: #6375E9;
  border: 2px solid #6375E9;
}
.form__tag:hover .tag__remove, .form__tag:focus-within .tag__remove {
  display: inline-block;
}
.form__btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
}
.form__btn-wrapper .btn_large:last-child, .form__btn-wrapper .btn-link:last-child {
  margin-right: 0;
}

.col__pr {
  padding-right: 2.5rem;
}

.form__select .dropdown-toggle:after,
.select__search .bs-searchbox:after {
  position: absolute;
  content: "";
  width: 16px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  border: none;
  margin: 0;
  padding: 0;
}

.easy-autocomplete {
  width: 100% !important;
}
.easy-autocomplete-container {
  z-index: 4;
}
.easy-autocomplete-container li.__hide {
  display: none !important;
}
.easy-autocomplete .input__search {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  color: #353549;
  padding: 0.5rem 1.2rem 0.5rem 1rem;
}
.easy-autocomplete .input__search:hover {
  border-color: #97989F;
}
.easy-autocomplete .input__search:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.easy-autocomplete .input__search.input_active-js {
  border-radius: 6px 6px 0 0;
  border: none;
}
.easy-autocomplete .easy-autocomplete-container ul {
  border: none;
  position: relative;
  border-radius: 0 0 6px 6px;
  outline: none;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  clip-path: inset(2px -4px -4px -4px);
  top: -2px;
}
.easy-autocomplete .easy-autocomplete-container ul:before {
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 24px);
  height: 1px;
  background-color: #E8E8EB;
  top: 1px;
  left: 12px;
}
.easy-autocomplete .easy-autocomplete-container ul li {
  font-size: 1.6rem;
  border: none;
  padding: 8px 12px 8px 20px;
}
.easy-autocomplete .easy-autocomplete-container ul li:last-child {
  border-radius: 0 0 6px 6px;
}
.easy-autocomplete .easy-autocomplete-container ul li.selected {
  background-color: #F8F8F8;
}
.easy-autocomplete .easy-autocomplete-container ul li b {
  color: #6375E9;
  font-weight: normal;
}

.remove-fields__btn {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(0.6rem);
}

.label__check, .label__radio {
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 3.5rem;
  position: relative;
}
.label__check:before, .label__radio:before {
  background-color: #FFFFFF;
  content: "";
  height: 22px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 22px;
  display: inline-block;
  border: 1px solid #E0E0E0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.label__check.form__label_disable, .label__radio.form__label_disable {
  cursor: default;
}
.label__check.form__label_disable:before, .label__radio.form__label_disable:before {
  background-color: #F2F2F2;
}
.label__check:not(.form__label_disable):hover:before, .label__radio:not(.form__label_disable):hover:before {
  border-color: #97989F;
}
.label__check:not(.form__label_disable):focus:before, .label__radio:not(.form__label_disable):focus:before {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.label__check_all {
  color: #6375E9;
  font-weight: 700;
}
.label__check:before {
  border-radius: 4px;
}
.label__radio {
  margin-top: 1.5rem;
}
.label__radio:before {
  border-radius: 50%;
}

.select-multiple__item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.select-multiple__item:hover > .item__btn-remove, .select-multiple__item:focus-within > .item__btn-remove {
  display: inline-block;
}
.select-multiple__item-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.select-multiple__controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin-top: 25px;
}

.item__title {
  white-space: nowrap;
}

.form__tag,
.new-tag__input,
.select-multiple__item {
  padding: 11px 20px;
  margin: 0;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  border-radius: 32px;
  color: #676876;
  line-height: 1.8rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.form__tag,
.select-multiple__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
}
.form__tag:hover,
.select-multiple__item:hover {
  border-color: #6375E9;
  color: #6375E9;
}
.form__tag:focus-within,
.select-multiple__item:focus-within {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}

.customer__tag.selected-js > .tag__remove:hover {
  background-image: url("../images/icons/close-hover-blue.svg");
}

.tag__remove,
.item__btn-remove {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 15px;
  border: none;
  background-image: url("../images/icons/close.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  transition: all 0.3s ease-in-out;
}
.tag__remove:hover,
.item__btn-remove:hover {
  background-image: url("../images/icons/close-hover-blue.svg");
}
.tag__remove:focus,
.item__btn-remove:focus {
  background-image: url("../images/icons/close-focus.svg");
  transform: scale(1.4);
}
.tag__remove:active,
.item__btn-remove:active {
  transform: scale(0.9);
}

.new-tag__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.new-tag__input {
  width: 110px;
}
.new-tag__input:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}

.select__search .bs-searchbox > input {
  font-size: 1.4rem;
  padding-left: 14px;
  padding-right: 30px;
  border: none;
  overflow: hidden;
}
.select__search .bs-searchbox > input:focus {
  box-shadow: none;
}

.bootstrap-select.form__select-multiple .bs-ok-default:after {
  content: none;
}
.bootstrap-select.show-tick.form__select-multiple .dropdown-menu li.selected {
  background-color: #F2F2F2;
}
.bootstrap-select.show-tick.form__select-multiple .dropdown-menu li.selected .dropdown-item:hover, .bootstrap-select.show-tick.form__select-multiple .dropdown-menu li.selected .dropdown-item:focus {
  background-color: #F2F2F2;
}
.bootstrap-select.show-tick.form__select-multiple .dropdown-menu .selected span.check-mark {
  left: 27px;
  top: 12px;
  right: auto !important;
  background-image: url("../images/icons/check-white.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 12px;
  height: 11px;
}
.bootstrap-select.show-tick.form__select-multiple .dropdown-menu .selected:before {
  background-color: #6375E9;
  border-color: #6375E9;
}

.datepicker.dropdown-menu {
  font-size: 1.6rem;
  color: #02031B;
  z-index: auto !important;
}
.datepicker.datepicker-orient-bottom {
  margin-top: 6px;
}
.datepicker.datepicker-orient-top {
  transform: translateY(-6px);
}
.datepicker tr,
.datepicker td,
.datepicker th {
  vertical-align: middle;
}
.datepicker .datepicker-months {
  width: 300px;
}
.datepicker .datepicker-months table {
  width: 100%;
}
.datepicker .datepicker-months .month {
  width: 31%;
}
.datepicker table tr td,
.datepicker table tr th {
  width: 35px;
  height: 35px;
}
.datepicker table tr td.active.active, .datepicker table tr td.active.active:hover,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover {
  background-color: #6375E9;
  border-color: #6375E9;
}

.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-group.date:hover > .form__date {
  border-color: #97989F;
}

[readonly],
.disable-element {
  pointer-events: none;
  background-color: #F2F2F2 !important;
  color: #676876;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .form__wrapper {
    padding: 0 2vw;
  }
  .form__set {
    padding: 12px 0;
  }
  .form__select.form__select-multiple > .dropdown-menu {
    min-height: 236px;
  }
  .form__col_many.form__col_mobile {
    flex-wrap: wrap;
  }
  .form__col_xlarge, .form__col_large, .form__col_normal, .form__col_small, .form__col_xsmall {
    width: 45%;
  }
  .form__col_medium {
    width: 100%;
  }
  .form__col_mobile .form__col_xsmall,
.form__col_mobile .form__col_small,
.form__col_mobile .form__col_medium,
.form__col_mobile .form__col_large,
.form__col_mobile .form__col_xlarge {
    padding: 0;
    width: 100%;
  }
  .form__col_mobile .form__col_xxsmall {
    width: 45%;
  }
  .form__col_mobile .form__col_align:not(:first-child),
.form__col_mobile .form__col_align-bottom:not(:first-child) {
    margin-top: 2.5rem;
  }
  .form__col_mobile.form__col-2 .remove-fields__btn {
    left: 100% !important;
    right: unset !important;
  }
  .form__col_mobile .form__col_xxsmall + .form__col_xxsmall {
    margin-top: 0;
  }
  .form__col_new {
    border: 1px solid #97989F;
    padding: 3rem 1rem 2.4rem;
    border-radius: 8px;
    margin: 1.5rem 0;
  }
  .form__col_new .remove-fields__btn {
    top: 5px;
    transform: translateX(-3rem);
  }
  .form__col_grow {
    width: 100%;
  }
  .form__col_grow.col__pr {
    padding-right: 0;
  }
  .form__btn-wrapper {
    justify-content: center;
  }
  .form__btn-wrapper .btn_large:last-child, .form__btn-wrapper .btn-link:last-child {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1023px) {
  .form__row, .form__row_low {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .form__row .form__col-2:first-child:not(:last-child):not(:empty), .form__row_low .form__col-2:first-child:not(:last-child):not(:empty) {
    margin-bottom: 2.5rem;
  }
  .form__row .form__col:not(:empty), .form__row_low .form__col:not(:empty) {
    margin-bottom: 1.5rem;
  }
  .form__row .form__col-3:not(:empty), .form__row_low .form__col-3:not(:empty),
.form__row .form__col-2:not(:empty),
.form__row_low .form__col-2:not(:empty),
.form__row .form__col_xxlarge:not(:empty),
.form__row_low .form__col_xxlarge:not(:empty) {
    margin-bottom: 2.5rem;
  }

  .form__col-2, .form__col-3, .form__col_xxlarge, .form__col_large[data-container=addition] {
    width: 100%;
    padding: 0;
  }
  .form__col_new .form__col-2:not(:first-child) {
    margin-top: 2.2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .form__subtitle + .form__row_tablet {
    padding-bottom: 0;
  }

  .form__row_tablet {
    flex-wrap: wrap;
  }
  .form__row_tablet .form__col-2 {
    width: 100%;
  }
  .form__row_tablet .form__col_left-small:not(:empty), .form__row_tablet .form__col_right-small:not(:empty) {
    padding: 2.5rem 0 0;
  }
}
/*------Desktop media queries------*/
@media screen and (min-width: 1024px) and (max-width: 1180px) {
  .form__col_date .form__date {
    padding-left: 10px;
    padding-right: 27px;
  }
  .form__col_date .input-group.date .input-group-addon {
    width: 20px;
    right: -4px;
  }
  .form__col_date .input-group.date .input-group-addon img {
    width: 100%;
  }

  .com-prop__form .form__col_medium {
    width: 55%;
  }
  .com-prop__form .form__col_small {
    width: 33%;
  }
}
.gescom-btn, .contributed-link, .btn-link {
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
  transition: all 0.3s ease-in-out;
}
.gescom-btn:focus:not(.btn_disabled), .contributed-link:focus:not(.btn_disabled), .btn-link:focus:not(.btn_disabled) {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.gescom-btn:active:not(.btn_disabled), .contributed-link:active:not(.btn_disabled), .btn-link:active:not(.btn_disabled) {
  transform-origin: center center;
  box-shadow: 0 0 0 4px rgba(145, 188, 248, 0.8);
}

.btn_default {
  color: #727272;
  border-color: #727272;
}
.btn_default:hover:not(:focus):not(.btn_disabled) {
  color: #FFFFFF;
  background-color: #727272;
}
.btn_default:active:not(.btn_disabled) {
  color: #FFFFFF;
  border-color: #353549;
  background-color: #353549;
}
.btn_primary, .contributed-link {
  background-color: #6375E9;
  border-color: #6375E9;
  color: #FFFFFF;
}
.btn_primary:hover:not(:focus):not(.btn_disabled), .contributed-link:hover:not(:focus):not(.btn_disabled) {
  background-color: #394ED2;
  border-color: #394ED2;
  color: #FFFFFF;
}
.btn_primary:active:not(.btn_disabled), .contributed-link:active:not(.btn_disabled) {
  background-color: #122ED7;
  border-color: #122ED7;
  color: #FFFFFF;
}
.btn_secondary, .btn-link {
  border-color: #6375E9;
  color: #6375E9;
}
.btn_secondary:hover:not(:focus):not(.btn_disabled), .btn-link:hover:not(:focus):not(.btn_disabled) {
  background-color: #6375E9;
  color: #FFFFFF;
}
.btn_secondary:active:not(.btn_disabled), .btn-link:active:not(.btn_disabled) {
  background-color: #353549;
  border-color: #353549;
  color: #FFFFFF;
}
.btn_large, .btn-link {
  min-width: 135px;
  border-radius: 32px;
  padding: 1.4rem 3rem;
}
.btn_large:active:not(.btn_disabled), .btn-link:active:not(.btn_disabled) {
  transform: scale(0.92);
}
.btn_small {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 50%;
  height: 40px;
  margin: 0 10px;
  width: 40px;
}
.btn_small:active:not(.btn_disabled) {
  transform: scale(0.8);
}
.btn_mini {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 50%;
  height: 32px;
  margin: 0 5px;
  width: 32px;
}
.btn_mini:active:not(.btn_disabled) {
  transform: scale(0.9);
}
.btn-combo {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
.btn-combo__icon, .combo-link__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.btn-combo__text, .combo-link__text {
  padding-left: 3rem;
  white-space: nowrap;
}
.btn-picto {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  padding: 4px;
  transition: all 0.3s ease-in-out;
}
.btn-picto:focus:not(.btn_disabled) {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.btn-picto:active:not(.btn_disabled) {
  transform-origin: center center;
  box-shadow: 0 0 0 4px rgba(145, 188, 248, 0.8);
}
.btn-picto_default {
  color: #868A94;
}
.btn-picto_default:hover:not(:focus):not(.btn_disabled) {
  color: #676876;
}
.btn-picto_default:active:not(.btn_disabled) {
  color: #353549;
}
.btn-picto_primary {
  color: #6375E9;
}
.btn-picto_primary:hover:not(:focus):not(.btn_disabled) {
  color: #394ED2;
}
.btn-picto_primary:active:not(.btn_disabled) {
  color: #122ED7;
}
.btn-picto_secondary {
  color: #727272;
}
.btn-picto_secondary:hover:not(:focus):not(.btn_disabled) {
  color: #6375E9;
}
.btn-picto_secondary:active:not(.btn_disabled) {
  color: #394ED2;
}
.btn-anim:hover:not(:focus):not(.btn_disabled) > .icon {
  transform: scale(1.25);
}
.btn-show-hide {
  display: none;
  width: 30px;
  height: 30px;
  transition: all 0.3s ease-in-out;
}
.btn-show-hide .icon-arrow {
  transform: rotate(-90deg);
}
.btn-show-hide.slide-active-js .icon-arrow {
  transform: rotate(90deg);
}
.btn_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  transition: transform 0.15s linear;
}
.icon-add {
  height: 16px;
  width: 16px;
}
.icon-add-circle {
  height: 20px;
  width: 21px;
}
.icon-close, .icon-arrow {
  height: 14px;
  width: 14px;
}
.icon-close_large {
  height: 21px;
  width: 21px;
}
.icon-check {
  height: 12px;
  width: 14px;
}
.icon-search {
  height: 25px;
  width: 24px;
}
.icon-tasks {
  height: 24px;
  width: 24px;
}
.icon-add_large, .icon-delete, .icon-question, .icon-action, .icon-edit, .icon-download, .icon-copy, .icon-refresh, .icon-show, .icon-dots, .icon-view, .icon-favorite, .icon-ok-circle, .icon-document, .icon-archive, .icon-hide {
  height: 22px;
  width: 22px;
}
.icon-attach {
  height: 18px;
  width: 18px;
}
.icon-settings {
  height: 23px;
  width: 22px;
}
.icon-menu {
  height: 15px;
  width: 22px;
}
.icon-contact {
  height: 19px;
  width: 19px;
}
.icon-long-arrow {
  height: 16px;
  width: 18px;
}

.check-picto[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}
.check-picto[type=checkbox]:after {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  content: "";
  height: 22px;
  width: 22px;
}
.check-picto[type=checkbox]:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.check-picto__label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0;
}

.add_to_favorite__label .check-picto:after {
  background-image: url("../images/icons/favorite.svg");
}
.add_to_favorite__label .check-picto:checked:after {
  background-image: url("../images/icons/favorite-active.svg");
}
.add_to_favorite__label:hover .check-picto:not(:checked):after {
  background-image: url("../images/icons/favorite-hover.svg");
}

.subscribe__label .check-picto:after {
  background-image: url("../images/icons/notification.svg");
}
.subscribe__label .check-picto:checked:after {
  background-image: url("../images/icons/notification-active.svg");
}
.subscribe__label:hover .check-picto:not(:checked):after {
  background-image: url("../images/icons/notification-hover.svg");
}

/*------Mobile media queries------*/
@media screen and (max-width: 640px) {
  .btn-show-hide {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .btn_large, .btn-link {
    padding: 1rem 2rem;
  }

  .actions__btn {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .actions__btn.active-js {
    background-color: #6375E9;
    color: #FFFFFF;
  }
}
.user-account {
  display: flex;
  height: 100%;
  position: relative;
}
.user-account__photo {
  border-radius: 50%;
  height: 42px;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 42px;
}
.user-account__control {
  cursor: pointer;
  padding: 2px;
}
.user-account__notification {
  background-color: #FF0544;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}
.user-account__notification:not(:empty) {
  line-height: 1.1rem;
  min-height: 16px;
  min-width: 16px;
}
.user-account__menu {
  background-color: #FFFFFF;
  box-shadow: 0 4px 12px rgba(2, 3, 27, 0.12);
  margin: 0;
  min-width: 300px;
  padding: 2.5rem 3.1rem 1rem 1.5rem;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translate(1rem, 5px);
  z-index: 10;
}
.user-account__menu-list {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}
.user-account__menu-section {
  margin-bottom: 0;
  padding-bottom: 1rem;
  position: relative;
}
.user-account__menu-section + .user-account__menu-section {
  border-top: 1px solid #E8E8EB;
}
.user-account__menu-section:last-child {
  margin: 0;
}
.user-account__menu-item {
  padding: 4px;
  font-size: 1.4rem;
}
.user-account__menu-link, .user-account__menu-title {
  border-radius: 8px;
  display: block;
  color: #02031B;
  line-height: 1.6rem;
  padding: 9px 0 9px 16px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.user-account__menu-link:before, .user-account__menu-title:before {
  border-radius: 50%;
  height: 4px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(7px, -50%);
  width: 4px;
}
.user-account__menu-title {
  color: #6375E9;
}
.user-account__menu-title:before {
  background-color: #6375E9;
  content: "";
}
.user-account__menu-link:hover {
  background-color: #F8F8F8;
  color: #02031B;
  text-decoration: none;
}
.user-account__menu-link:hover:before {
  background-color: #02031B;
  content: "";
}
.user-account__menu-link:focus {
  border-radius: 6px;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.user-account__menu-link:active {
  background-color: transparent;
  box-shadow: 0 0 0 4px rgba(145, 188, 248, 0.8);
  color: #5064E5;
}
.user-account__menu-link:active:before {
  background-color: #5064E5;
}
.user-account__menu-link__exit {
  margin-top: 1rem;
  padding-left: 2.5rem;
}
.user-account__menu-link__exit:before {
  background-image: url("../images/icons/icons-sprite.svg");
  background-color: transparent;
  background-position: -305px -12px;
  background-repeat: no-repeat;
  border-radius: unset;
  content: "";
  height: 24px;
  left: 0;
  transform: translate(0, -50%);
  width: 24px;
}
.user-account__menu-link__exit:hover:before, .user-account__menu-link__exit:active:before {
  background-color: transparent;
}
.user-account__menu-close {
  position: absolute;
  top: 0;
  transform: translate(-8px, 8px);
  right: 0;
}
.user-account__top-block {
  align-items: center;
}
.user-account__photo_large {
  padding-top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 8rem;
  width: 27%;
}
.user-account__user {
  flex-grow: 1;
}
.user-account__full-name {
  color: #6375E9;
  font-size: clamp(1.8rem, 1.478rem + 0.3145vw, 2rem);
  font-weight: 900;
  text-transform: uppercase;
}
.user-account__position {
  font-size: clamp(1.4rem, 1.078rem + 0.3145vw, 1.6rem);
  font-weight: 700;
  color: #02031B;
}
.user-account__info-list {
  flex-direction: column;
  padding-left: max(27%, 8rem);
}
.user-account__bottom-block {
  padding-left: max(27%, 8rem);
  width: auto;
}

.photo {
  border-radius: 50%;
  height: auto;
  width: 100%;
}

.notification__all {
  bottom: 0;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
  right: 10px;
  text-align: center;
  transform: translate(100%, -7px);
}
.notification__all:not(:empty) {
  line-height: 1.1rem;
  min-height: 16px;
  min-width: 16px;
  padding: 3px;
}
.notification__all:not(:empty):before {
  background-color: #FF0544;
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ff0544;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1);
  width: 100%;
  z-index: -1;
  animation: pulse 3s infinite;
}
.notification__item {
  margin-left: 1rem;
}
.notification__item:not(:empty) {
  padding: 3px 7px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 5, 68, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 5, 68, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 5, 68, 0);
  }
}
.info-list__item {
  margin-bottom: 0.9rem;
  min-height: 2.2rem;
  padding-left: 3rem;
  position: relative;
  word-break: break-word;
}
.info-list__item:before {
  content: "";
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  height: 20px;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 20px;
}
.info-list__item-phone-number::before {
  background-position: -52px -50px;
}
.info-list__item-mobile-number::before {
  background-position: -164px -50px;
}
.info-list__item-city::before {
  background-position: -89px -50px;
}

#tasks-toggle-btn:hover + .notification__all:before, #tasks-toggle-btn:focus + .notification__all:before {
  animation: none;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .user-account__photo_large {
    width: 20%;
  }
  .user-account__info-list, .user-account__bottom-block {
    padding-left: max(20%, 8rem);
  }
}
@media screen and (max-width: 767px) {
  .user-account__menu {
    display: block;
    height: 100vh;
    left: 0;
    padding: 4.5rem 2.5rem 1rem;
    position: fixed;
    top: 0;
    transform: unset;
  }
  .user-account__menu-list {
    max-height: calc(100vh - 5.5rem);
  }
  .user-account__menu-link, .user-account__menu-title {
    font-size: 1.6rem;
    padding-left: 2rem;
  }
  .user-account__menu-link__exit {
    padding-left: 3rem;
  }
  .user-account__menu-close {
    transform: translate(-2rem, 2rem);
  }
  .user-account__top-block {
    align-items: flex-start;
  }
  .user-account__user {
    word-break: break-word;
  }
  .user-account__photo_large {
    min-width: 5rem;
    padding-left: 0;
    padding-right: 0;
    width: 15%;
  }
  .user-account__info-list, .user-account__bottom-block {
    padding-left: max(15%, 5rem);
  }
  .user-account__full-name {
    margin-bottom: 0.5rem;
  }
}
.main-panel__container {
  margin: 2rem 0 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
}
.main-panel__user-info {
  min-height: 380px;
  width: 28%;
}
.main-panel__activities {
  margin: 0;
  gap: 4% 0;
  min-height: 380px;
  width: 21%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.main-panel__days-counter {
  min-height: 380px;
  width: 15%;
}
.main-panel__notices {
  margin: 0;
  min-height: 380px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.main-panel__card {
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
  font-size: clamp(1.2rem, 0.878rem + 0.3145vw, 1.4rem);
}

.card-indent__large {
  padding: 1vw;
}
.card-indent__small {
  padding: 0.5vw;
}
.card-item, .card-item_large {
  min-height: 30%;
  padding-bottom: 2.2rem;
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.card-item_large {
  width: 100%;
}
.card-item__link {
  right: 0;
  position: absolute;
  top: 100%;
  transform: translate(-0.5vw, calc(-100% - 0.5vw));
}
.card__link {
  font-weight: 700;
  text-align: center;
}
.card__icon {
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  height: 32px;
  margin-bottom: 0.5rem;
  width: 36px;
}
.card__icon-fees {
  background-position: 2px -74px;
}
.card__icon-missions {
  background-position: -112px -73px;
}
.card__icon-absences {
  background-position: -38px -73px;
}
.card__icon-skills {
  background-position: -74px -73px;
}
.card__icon-team {
  background-position: -154px -73px;
}

.days-counter__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.days-counter__item:nth-child(-n+2) {
  border-bottom: 1px solid #E0E0E0;
}
.days-counter__title {
  color: #676876;
  font-size: 1.2rem;
  margin: 0;
}
.days-counter__value {
  color: #02031B;
  font-weight: 700;
  margin: 0;
}
.days-counter__warning {
  color: #FF5F0F;
}

.notice-item {
  padding: 1.2rem 0 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.notice-item + .notice-item {
  border-top: 1px solid #E0E0E0;
}
.notice-item__icon {
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  height: 33px;
  margin-right: 1rem;
  width: 33px;
}
.notice-item__message {
  margin: 0;
  width: calc(100% - 33px - 1rem);
}
.notice-item__message .cell__content_important {
  transition: all 0.3s ease-in-out;
}
.notice-item__link {
  color: #353549;
  word-break: break-word;
}
.notice-item__information .notice-item__icon {
  background-position: -226px -50px;
}
.notice-item__alert-level-1 .notice-item__icon {
  background-position: -264px -50px;
}
.notice-item__alert-level-1 .notice-item__link:hover,
.notice-item__alert-level-1 .notice-item__link:hover * {
  color: #6375E9 !important;
}
.notice-item__alert-level-1 .notice-item__link:active,
.notice-item__alert-level-1 .notice-item__link:active * {
  color: #122ED7 !important;
}
.notice-item__alert-level-2 .notice-item__icon {
  background-position: -302px -50px;
}
.notice-item__alert-level-3 .notice-item__icon {
  background-position: -340px -50px;
}
.notice-item__alert-level-2 .notice-item__link:hover,
.notice-item__alert-level-2 .notice-item__link:hover *, .notice-item__alert-level-3 .notice-item__link:hover,
.notice-item__alert-level-3 .notice-item__link:hover * {
  color: #FF5F0F !important;
}
.notice-item__alert-level-2 .notice-item__link:active,
.notice-item__alert-level-2 .notice-item__link:active *, .notice-item__alert-level-3 .notice-item__link:active,
.notice-item__alert-level-3 .notice-item__link:active * {
  color: #C84F00 !important;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .main-panel__container {
    flex-wrap: wrap;
    gap: 20px;
  }
  .main-panel__user-info, .main-panel__notices {
    width: calc(60% - 10px);
  }
  .main-panel__activities, .main-panel__days-counter {
    width: calc(40% - 10px);
  }

  .card__icon {
    height: 32px;
    width: 32px;
  }
  .card__icon-fees {
    background-position: 0px -109px;
  }
  .card__icon-missions {
    background-position: -33px -109px;
  }
  .card__icon-absences {
    background-position: -66px -109px;
  }
  .card__icon-skills {
    background-position: -98px -109px;
  }
  .card__icon-team {
    background-position: -132px -109px;
  }
  .card-item, .card-item_large {
    padding-bottom: 0.5vw;
  }
  .card-item__link {
    top: 0;
    transform: translate(-0.5vw, 0.5vw);
  }
}
@media screen and (max-width: 767px) {
  .main-panel__user-info, .main-panel__notices, .main-panel__activities, .main-panel__days-counter {
    min-height: auto;
    width: 100%;
  }
  .main-panel__activities {
    gap: 10px;
  }
  .main-panel__card {
    font-size: 1.4rem;
  }

  .card-indent__large, .card-indent__small {
    padding: 2rem;
  }
  .card__icon {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .card-item, .card-item_large {
    flex-direction: row;
    height: auto;
    min-height: unset;
    justify-content: flex-start;
    width: 100%;
  }
  .card-item__link {
    top: 50%;
    transform: translate(-2rem, -50%);
  }

  .days-counter__item:nth-child(-n+3) {
    padding: 0 0.5rem;
    width: 33.3%;
  }
  .days-counter__item:nth-child(-n+2) {
    border-bottom: none;
  }
  .days-counter__item:nth-child(2) {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
  }
  .days-counter__item:last-child {
    margin-top: 2rem;
  }

  .contributed-link__value {
    line-height: 1.8rem;
  }

  .notice-item {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .notice-item:first-child {
    padding-top: 0;
  }
  .notice-item:last-child {
    padding-bottom: 0;
  }
}
#tag-set {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  padding: 0 0 0.8rem;
}

.com-prop__form .form__btn-wrapper {
  display: flex;
  background-color: #F2F2F2;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  /* Safari */
  bottom: 0;
  width: 100%;
  z-index: 8;
}
.com-prop__form .form__btn-wrapper.isSticky {
  box-shadow: 0 0 0 3px #F2F2F2;
  min-height: 82px;
}
.com-prop__form .form__btn-wrapper.isSticky:after {
  content: "";
  background: url(../images/icons/fast-forward.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  right: -5vw;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  animation: arrow-button 1s linear infinite;
}
.com-prop__form .form__btn-wrapper .link_vedecum {
  font-size: 1.4rem;
  font-weight: 700;
}
.com-prop__form .form__btn-wrapper .form__btn-active-block {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.table__attachments {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
}
.table__attachments thead {
  display: none;
}
.table__attachments-description {
  width: 220px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table__attachments-row {
  margin-bottom: 20px;
}
.table__attachments-row td {
  white-space: nowrap;
}
.table__attachments-row td:not(:last-child) {
  padding-right: 20px;
}
.table__attachments-row td:first-child {
  font-weight: 700;
  width: 420px;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table__attachments-row td img {
  margin-right: 15px;
}
.table__attachments-actions {
  vertical-align: bottom;
  border-left: 1px solid #E8E8EB;
  padding-left: 20px;
}
.table__attachments-button, .comment__attachments-button {
  font-size: 0;
  background-color: transparent;
  border: none;
}
.table__attachments-button:after, .comment__attachments-button:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: cover;
}
.table__attachments-button.download, .download.comment__attachments-button {
  padding: 1px 10px;
  display: inline-block;
}
.table__attachments-button.download:after, .download.comment__attachments-button:after {
  background-image: url("../images/icons/save.svg");
}
.table__attachments-button.download:hover:after, .download.comment__attachments-button:hover:after {
  background-image: url("../images/icons/save-focus.svg");
}
.table__attachments-button.edit, .edit.comment__attachments-button {
  padding: 1px 10px;
}
.table__attachments-button.edit:after, .edit.comment__attachments-button:after {
  background-image: url("../images/icons/edit-unfocus.svg");
}
.table__attachments-button.edit:hover:after, .edit.comment__attachments-button:hover:after {
  background-image: url("../images/icons/edit.svg");
}
.table__attachments-button.remove, .remove.comment__attachments-button {
  padding: 1px 10px;
}
.table__attachments-button.remove:after, .remove.comment__attachments-button:after {
  background-image: url("../images/icons/dalet.svg");
}
.table__attachments-button.remove:hover:after, .remove.comment__attachments-button:hover:after {
  background-image: url("../images/icons/delete.svg");
}
.table__attachments-section {
  margin-bottom: 30px;
}

.__hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.attachment__form-wrapper.form__error {
  border-color: #FF0544;
}
.attachment__form-wrapper.edit-wrapper {
  margin-top: 15px;
  transition: all 0.4s;
}
.attachment__form-wrapper.__hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.attachment__account-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.attachment__user {
  display: flex;
  align-items: center;
}
.attachment__user-img {
  margin-right: 20px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
}
.attachment__form, .comment__form-edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 50px;
}
.attachment__form-file .file-name {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attachment__form-file:active:before, .attachment__form-file:focus:before {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.attachment__form-actions .btn_large, .attachment__form-actions .btn-link {
  min-width: 140px;
  font-size: 1.4rem;
}
.attachment__form.edit-attachment, .edit-attachment.comment__form-edit {
  justify-content: flex-end;
}

.table__scroll {
  overflow-x: auto;
}

.download:active, .download:focus,
.edit:active,
.edit:focus,
.remove:active,
.remove:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}

.adv__list .form__select {
  width: 286px;
  height: 38px;
}
.adv__list .form__select > .btn-light {
  color: #02031B99;
}

.comment__list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.comment__item {
  padding: 15px 0;
  border-bottom: 1px solid #E8E8EB;
}
.comment__item-top-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.comment__item-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 700;
}
.comment__item-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment__item-actions {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 20px;
  position: relative;
}
.comment__item-actions:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 42px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #E8E8EB;
}
.comment__user-img {
  margin-right: 20px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
}
.comment__assignee-to {
  color: #6375E9;
  margin-left: 52px;
  margin-top: 5px;
  width: 100%;
}
.button-action-list {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.button-action-list:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/icons/dots.svg") no-repeat center;
  transition: all 0.5s;
  transform: rotate(180deg);
}
.button-action-list.action-close {
  position: relative;
}
.button-action-list.action-close:after {
  content: "";
  width: 1px;
  height: 42px;
  background-color: #E8E8EB;
  position: absolute;
  left: 0;
}
.button-action-list.action-close:before {
  background: url("../images/icons/close.svg") no-repeat center;
  transform: rotate(0);
}
.button-action-list.action-close:hover:before {
  background: url("../images/icons/close-focus.svg") no-repeat center;
}

.grid__container-form {
  justify-content: flex-end;
}

.show__form-action-wrapper {
  margin-bottom: 20px;
}

.attachments-actions {
  display: flex;
  flex-wrap: nowrap;
  transition: width 0.3s ease-out, opacity 0.3s ease-out 0.1s;
}

.disable-file {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes arrow-button {
  0% {
    top: 50%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 50%;
  }
}
/*------Mobile media queries------*/
@media screen and (max-width: 480px) {
  .com-prop__form .form__btn-wrapper {
    flex-wrap: wrap;
    padding: 5px 0;
  }
  .com-prop__form .form__btn-wrapper .form__btn-active-block {
    justify-content: space-around;
    padding-bottom: 5px;
  }
}
@media screen and (max-width: 767px) {
  .com-prop__form .form__btn-wrapper.isSticky {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .com-prop__form .form__btn-wrapper.isSticky .btn_large, .com-prop__form .form__btn-wrapper.isSticky .btn-link {
    margin: 0;
    min-width: 130px;
  }
  .com-prop__form .form__btn-wrapper.isSticky .btn_default {
    margin-right: 8px;
  }
  .com-prop__form .form__btn-wrapper.isSticky:after {
    right: -3vw;
    width: 25px;
    height: 25px;
  }
  .com-prop__form .form__btn-wrapper .link_vedecum {
    width: 100%;
    margin: 1rem 0;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 1023px) {
  .com-prop__form .grid__col-8,
.com-prop__form .grid__col-4 {
    padding: 0;
    width: 100%;
  }
}
.page__header {
  border-top: 6px solid #6375E9;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11;
}

.header__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.header__left-item {
  padding-left: 1.6vw;
}

.block__environment {
  background-color: #FF0544;
  text-align: center;
}
.block__environment .environment_description_text {
  color: #FFFFFF;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.menu__toggle {
  color: #02031B;
  padding: 8px 6px;
}
.menu__toggle:hover {
  color: #6375E9;
}

.logo {
  color: #6375E9;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin-left: 1vw;
  margin-right: auto;
}
.logo__image {
  max-width: 150px;
}

.gescom-logo_small {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: 0 auto;
  max-width: 150px;
}

.gescom-logo,
.website-logo {
  width: 100%;
  height: auto;
}

.lang-switcher {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.lang-switcher__label {
  border-radius: 8px;
  color: #676876;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  height: 2rem;
  line-height: 1.8rem;
  margin: 0;
  padding: 2px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 3rem;
}
.lang-switcher__label:hover {
  color: #6375E9;
}
.lang-switcher__label:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}
.lang-switcher__label:not(:last-child) {
  margin: 0 0 0.5rem 0;
}
.lang-switcher__radio {
  display: none;
}
.lang-switcher__radio:checked + .lang-switcher__label {
  background-color: #6375E9;
  background-clip: content-box;
  color: #FFFFFF;
}
.lang-switcher__radio:checked + .lang-switcher__label:hover {
  background-color: #5364CE;
}

.search-block {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-end;
  width: auto;
}
.search-block.mobile-only-js {
  background-color: #FFFFFF;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 0 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.search-block.mobile-only-js .search__form {
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
}
.search-block.mobile-only-js .search__input {
  margin: 0;
  padding: 0 4rem 0 1.5rem;
  width: 90%;
}
.search-block.mobile-only-js .search-icon__block {
  right: 12%;
}
.search-block.mobile-only-js .btn-search-open-js {
  display: none;
}
.search-block.mobile-only-js .btn-search-close-js {
  display: flex;
}
.search__form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  padding: 3px 0;
  position: relative;
  width: 0;
}
.search__input {
  border: 1px solid #E8E8EB;
  border-radius: 12px;
  height: 40px;
  margin-right: 5px;
  padding: 0 6rem 0 2.2rem;
  transition: all 0.3s ease-in-out;
  width: 95%;
}
.search__input:hover {
  border-color: #97989F;
}
.search__input:focus-within {
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}
.search__input:focus-within ~ .search-icon__block {
  opacity: 0;
}
.search-icon {
  color: #6375E9;
  height: 25px;
  width: 25px;
}
.search-icon__block {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.btn-search-close-js {
  display: none;
}
.btn-search-open-js {
  margin-right: 1.5rem;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  #toggle-menu-btn {
    margin-right: 0.5rem;
  }

  .header__wrapper {
    flex-wrap: wrap;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .header__left-item {
    padding-left: 1.5rem;
  }
  .header__gescom-logo {
    left: unset;
    margin: 0 auto 0 0;
    max-width: 110px;
    top: unset;
    transform: unset;
    position: relative;
  }

  .logo,
.lang-switcher {
    display: none;
  }

  .search-block .btn-search-open-js {
    margin-right: 0;
  }
}
.favorite__wrap {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.favorite__modal {
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  background-color: #F2F2F2;
  box-shadow: 0 4px 12px rgba(35, 36, 42, 0.56);
  border-radius: 12px;
  left: 50%;
  margin: 0;
  width: 70%;
  z-index: 10;
}
.favorite__modal_close {
  display: none;
}
.favorite__modal.shadowed-js:before {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
  width: 100%;
  border-radius: 12px;
  left: 0;
  z-index: 10;
}
.favorite__title {
  position: absolute;
  top: 1.4rem;
  transform: translate(2vw, 0);
  margin-bottom: 0;
}
.favorite-table__wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.favorite-table__wrapper .dataTables_length,
.favorite-table__wrapper .dataTables_filter,
.favorite-table__wrapper .dataTables_paginate,
.favorite-table__wrapper .dataTables_info {
  font-size: clamp(1.2rem, 0.7077rem + 0.4808vw, 1.4rem);
}
.favorite-table__wrapper .dataTables_paginate .paginate_button {
  line-height: clamp(1.4rem, 0.4154rem + 0.9615vw, 1.8rem);
}
.favorite-table.dataTable thead th {
  height: 44px;
  padding-bottom: 0;
  padding-top: 0;
  vertical-align: middle;
}

button[data-btn=tabs-btn] {
  position: absolute;
  bottom: 3rem;
  left: 2vw;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .favorite__modal {
    min-width: 85vw;
  }
}
@media screen and (max-width: 767px) {
  .favorite__modal {
    background-color: #FFFFFF;
    border-radius: 0;
    height: 100vh;
    left: 0;
    overflow: hidden scroll;
    padding: 2rem 3vw 0;
    position: fixed;
    top: 0;
    transform: unset;
    width: 100vw;
  }
  .favorite__modal_close {
    position: absolute;
    top: 0;
    transform: translate(-50%, 1rem);
    display: inline-block;
    right: 0;
  }
  .favorite__modal.shadowed-js:before {
    border-radius: unset;
  }
  .favorite__title {
    position: relative;
  }
  .favorite-table__wrapper {
    padding-top: 0;
  }

  button[data-btn=tabs-btn] {
    bottom: 0;
    display: block;
    margin: 0 auto;
    position: relative;
  }
}
.link {
  border-radius: 6px;
  display: inline-block;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.link:before {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.link:hover, .link:active {
  text-decoration: none;
}
.link:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}
.link_default {
  color: #AAABB2;
}
.link_default:hover, .link_default:active {
  color: #7C7D85;
}
.link_primary {
  color: #6375E9;
}
.link_primary:hover, .link_primary:active {
  color: #122ED7;
  text-decoration: underline;
}
.link_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-link,
.country-link {
  display: inline-block;
  padding: 4px;
  color: inherit;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
}
.main-link:hover,
.country-link:hover {
  text-decoration: none;
  color: inherit;
}
.main-link:focus,
.country-link:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}

.add-link {
  font-size: clamp(1.2rem, 0.8675rem + 0.3247vw, 1.4rem);
}

.support-link {
  line-height: 16px;
  text-transform: uppercase;
}
.support-link .combo-link__icon {
  right: 1rem;
}
.support-link .combo-link__text {
  font-weight: 900;
  padding-left: 0;
  padding-right: 2.5rem;
}

.back-link {
  font-size: 1.3rem;
}
.back-link__wrapper {
  display: inline-block;
  margin-top: 1.2rem;
}

.btn-link:hover {
  color: #6375E9;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}

.combo-link {
  position: relative;
}
.combo-link__text {
  font-weight: 700;
}
.combo-link:hover:not(:focus) .btn-combo__text, .combo-link:hover:not(:focus) .combo-link__text, .combo-link:active .btn-combo__text, .combo-link:active .combo-link__text {
  text-decoration: underline;
}

.contributed-link {
  border-radius: 38px;
  padding: 1.2rem 2.1rem 1rem;
  width: 100%;
}
.contributed-link:hover {
  text-decoration: none;
}
.contributed-link:focus {
  text-decoration: none;
}
.contributed-link:focus:not(:active) {
  color: #FFFFFF;
}
.contributed-link__title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  margin: 0;
}
.contributed-link__value {
  font-size: clamp(1.4rem, 0.756rem + 0.6289vw, 1.8rem);
  line-height: 2.2rem;
  margin: 0;
}
.contributed-link__warning {
  background-color: #FF5F0F;
  border-color: #FF5F0F;
}
.contributed-link__warning:hover:not(:focus):not(.btn_disabled) {
  background-color: #E05900;
  border-color: #E05900;
}
.contributed-link__warning:active:not(.btn_disabled) {
  background-color: #C84F00;
  border-color: #C84F00;
}

a.cell-link {
  color: #353549;
  border-radius: 6px;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  padding: 4px;
  position: relative;
  white-space: nowrap;
}
a.cell-link:hover {
  color: #6375E9;
}
a.cell-link:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
  outline: none;
}
a.cell-link:active {
  color: #122ED7;
}

a.btn-picto:focus {
  color: #6375E9;
  text-decoration: none;
}

.paginate__wrapper {
  margin-left: auto;
}
.paginate__pager-list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: clamp(1.4rem, 0.9077rem + 0.4808vw, 1.6rem);
  margin: 0;
}
.paginate__pager-item:not(:last-child) {
  margin-right: 6px;
}
.paginate__button {
  background-color: transparent;
  border: 1px solid #6375E9;
  border-radius: 20px;
  color: #6375E9;
  display: inline-block;
  line-height: clamp(1.8rem, 1.0615rem + 0.7212vw, 2.1rem);
  padding: 0.5em 0.9em;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.paginate__button:hover {
  background-color: #6375E9;
  color: #FFFFFF;
  text-decoration: none;
}
.paginate__button:focus {
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.paginate__button:active {
  background-color: #5064E5;
  color: #FFFFFF;
  box-shadow: 0 0 0 4px rgba(145, 188, 248, 0.8);
  transform: scale(0.85);
  transform-origin: center center;
}
.paginate__button_current {
  color: #FFFFFF;
  background-color: #6375E9;
}
.paginate__button_disabled {
  display: inline-block;
  color: #97989F;
  line-height: 21px;
  padding: 0.5em 0.9em;
  height: 100%;
}

.mm-menu_theme-white {
  --mm-color-button: #6375E9;
  --mm-color-text: #02031B;
  --mm-color-text-dimmed: #6375E9;
  --mm-color-background-highlight: #FFF;
}

.mm-listitem a:hover {
  background-color: #F8F8F8;
}
.mm-listitem_selected {
  color: #6375E9;
}

.mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
  background: none;
}
.mm-navbar > .mm-navbar__title {
  flex: auto;
  font-weight: 700;
  text-transform: uppercase;
}
.mm-navbar__title {
  font-weight: 700;
  color: #6375E9;
}

.mm-navbars_top {
  padding: 15px 0;
}
.mm-navbars_top .mm-navbar {
  flex-direction: column;
}
.mm-navbars_top .mm-navbar__btn {
  align-self: flex-end;
  order: -1;
}
.mm-navbars_top .mm-navbar__title {
  align-self: flex-start;
  font-weight: 900;
  font-size: clamp(2rem, 0.9333rem + 1.0417vw, 2.6rem);
  letter-spacing: 0.18rem;
  text-transform: uppercase;
}
.mm-navbars_top .mm-navbar__searchfield {
  align-self: center;
  width: 90%;
}
.mm-navbars_top .mm-searchfield__input {
  padding-right: 0;
}
.mm-navbars_bottom {
  padding: 0 3rem;
}
.mm-navbars_bottom .mm-navbar {
  min-height: 80px;
}
.mm-navbars_bottom .lang-switcher {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
}
.mm-navbars_bottom .lang-switcher__label {
  font-size: 1.4rem;
  height: 2.8rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  margin-right: 2rem;
  width: 4rem;
}

.mm-searchfield__btn {
  right: 10px;
}
.mm-searchfield__input {
  padding: 0 15px;
}
.mm-searchfield__input input {
  background-image: url("../images/icons/search-fill.svg");
  background-repeat: no-repeat;
  background-position: right 10px bottom 50%;
  border: 1px solid #E8E8EB !important;
  border-radius: 21px;
  height: 43px;
  padding-right: 50px;
}
.mm-searchfield__input input:hover, .mm-searchfield__input input:focus {
  background-image: url("../images/icons/search-fill.svg");
  background-repeat: no-repeat;
  background-position: right 10px bottom 50%;
}
.mm-searchfield__input input:not(:placeholder-shown) {
  background: none;
}

.mm-btn_close:before, .mm-btn_close:after {
  width: 7px;
  height: 7px;
}
.mm-btn_close:before {
  right: 15px;
}
.mm-btn_close.mm-navbar__btn:before {
  border-color: #97989F;
  right: 10px;
}
.mm-btn_close.mm-navbar__btn:after {
  border-color: #97989F;
}
.mm-btn_close.mm-navbar__btn:before, .mm-btn_close.mm-navbar__btn:after {
  width: 10px;
  height: 10px;
}

#mm-0 .mm-navbar {
  min-height: 2rem;
}
#mm-0 .mm-navbar__title {
  display: none;
}

#gescom-menu.mm-menu_opened {
  z-index: 1;
}
#gescom-menu .mm-navbars_top .mm-navbar {
  border-bottom: none;
  justify-content: space-between;
}
#gescom-menu .mm-panels .mm-navbar {
  border-bottom: none;
}
#gescom-menu .mm-listitem__btn {
  border-left: 0;
}

#mm-0 .mm-listitem__text {
  display: flex;
  align-items: center;
}
#mm-0 .mm-listitem:not(.account__menu) > .mm-listitem__text:before {
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
  content: "";
  display: inline-block;
  height: 22px;
  margin-right: 20px;
  width: 22px;
}

.menu_production_item .mm-listitem__text:before {
  background-position: -89px -14px;
}

.menu_sales_item .mm-listitem__text:before {
  background-position: -14px -14px;
}

.menu_adv_item .mm-listitem__text:before {
  background-position: -277px -14px;
}

.menu_purchase_item .mm-listitem__text:before {
  background-position: -52px -14px;
}

.menu_finance_item .mm-listitem__text:before {
  background-position: -202px -14px;
}

.menu_hr_item .mm-listitem__text:before {
  background-position: -164px -14px;
}

.menu_payroll_item .mm-listitem__text:before {
  background-position: -127px -14px;
}

.menu_administration_item .mm-listitem__text:before {
  background-position: -240px -14px;
}

.mm-navbar_sticky:not(.mm-hidden) ~ .mm-listview .mm-divider {
  top: 0 !important;
}

@media screen and (max-width: 767px) {
  .mm-navbars_top .mm-navbar {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .mm-navbars_top .mm-navbar__btn {
    align-self: flex-start;
    order: 0;
  }
  .mm-navbars_top .mm-navbar__title {
    justify-content: flex-start;
    margin-top: 2rem;
    padding-left: 3vw;
  }
  .mm-navbars_top .mm-navbar__searchfield {
    padding: 0 3vw;
  }

  .mm-navbars_bottom .lang-switcher {
    display: flex;
  }
}
.gescom-modal {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.gescom-modal[data-priority=main] {
  z-index: 1000;
}
.gescom-modal__content {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 12px;
  left: 50%;
  margin: auto;
}
.gescom-modal__content_small {
  max-width: 600px;
  padding: 4% 3%;
  width: 70%;
}
.gescom-modal__content_large {
  max-width: 800px;
  padding: 3% 4rem;
  width: 90%;
}
.gescom-modal__title {
  padding-bottom: 3vmin;
  text-align: center;
}
.gescom-modal__text {
  padding-bottom: 7%;
  text-align: center;
}
.gescom-modal__import-block {
  text-align: left;
  margin-bottom: 3rem;
}
.gescom-modal__period {
  color: #02031B;
  font-weight: 700;
}
.gescom-modal__btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.gescom-modal__close {
  position: absolute;
  top: 0;
  transform: translate(-50%, 50%);
  right: 0;
}

.form-modal__content {
  height: 90vh;
}
.form-modal__overflow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: unset;
  flex-wrap: nowrap;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.4rem;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}
.form-modal__overflow::-webkit-scrollbar {
  display: none;
}
.form-modal__set {
  background-color: #FFFFFF;
  padding: 1rem 0 0;
  width: 100%;
}
.form-modal__btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: auto;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .gescom-modal__content_large {
    border-radius: unset;
    height: 100%;
    left: 0;
    padding: 4rem 1.5rem 1rem;
    top: 0;
    transform: unset;
    width: 100%;
  }
  .gescom-modal__content_large .grid__col-6 {
    width: 100%;
  }
  .gescom-modal__text {
    padding-bottom: 4vmin;
  }
  .gescom-modal__btn-wrapper {
    margin-top: 2rem;
  }
  .gescom-modal__import-block .attach__file-item {
    justify-content: space-between;
  }
  .gescom-modal__import-block .attach__file-label {
    margin: 0 auto;
  }
  .gescom-modal__import-block .attach__file-name {
    min-width: 88%;
  }

  .form-modal__btn-wrapper {
    justify-content: space-around;
    gap: 0.5rem 0;
  }
}
@media screen and (max-width: 480px) {
  .gescom-modal__content_small {
    padding: 8% 5%;
    width: 90%;
  }
}
.proposal__main-info {
  padding: 1rem 0;
}
.proposal__actions {
  margin-left: auto;
}
.proposal__section {
  background-color: #FFFFFF;
  padding: 3vmin 1vw;
}
.proposal__title {
  display: inline-block;
  margin: 0;
}
.proposal__subtitle {
  font-size: clamp(1.4rem, 0.7351rem + 0.6494vw, 1.8rem);
  margin-bottom: 1.5vmin;
  background-color: inherit;
  color: #6375E9;
  position: relative;
  z-index: 0;
}
.proposal__subtitle:after {
  background-color: #6375E9;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: -1;
}
.proposal__subtitle .subtitle__text {
  background-color: inherit;
  padding-right: 2rem;
}
.proposal__tags {
  gap: 1rem;
}
.proposal__tag {
  padding: 0.5rem 1.5rem;
  color: #6375E9;
  border: 2px solid #6375E9;
  border-radius: 32px;
}

.history__item {
  padding-bottom: 1.5rem;
  padding-top: 2.5rem;
}
.history__item:first-child {
  padding-top: 0;
}
.history__author {
  padding-top: 21px;
}
.history__content {
  color: #676876;
  margin: 0;
}
.history__param {
  font-weight: 700;
}
.history__param, .history__value_new {
  color: #02031B;
}
.history__value_old {
  font-style: italic;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .proposal__main-info {
    width: 100%;
  }
  .proposal__section {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .com-prop-view-mode #main-page.shadowed-js {
    height: 100vh;
    overflow: hidden;
  }

  .proposal__main-info {
    padding: 2rem 0;
  }
  .proposal__actions {
    padding-top: 2rem;
  }
  .proposal__actions .actions__list {
    margin-top: 2rem;
  }
  .proposal__section {
    padding: 3vmin 0;
  }
  .proposal__section > .grid__container > .grid__col-6 {
    width: 100%;
  }
  .proposal__section > .grid__container > .grid__cell_pl, .proposal__section > .grid__container > .grid__cell_pr {
    padding-left: 0;
    padding-right: 0;
  }
  .proposal__section .proposal__cell_large {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .proposal__subtitle {
    padding-bottom: 1vmin;
  }

  .history__content.row-item__content {
    order: 1;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .history__author, .history__date {
    align-self: center;
  }
  .history__author {
    padding-top: 0.7rem;
  }
}
.activities__wrapper {
  padding-bottom: 3rem;
}

.main-table__wrapper {
  padding-bottom: 5rem;
}
.main-table__wrapper .dataTables_wrapper .dataTables_paginate {
  font-size: clamp(1.4rem, 0.9077rem + 0.4808vw, 1.6rem);
}
.main-table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled) {
  line-height: clamp(1.8rem, 1.0615rem + 0.7212vw, 2.1rem);
}

.table__wrapper .dataTables_wrapper .dataTables_filter {
  float: unset;
}
.table__wrapper .dataTables_wrapper .dataTables_filter input {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  border-radius: 25px;
  margin-left: 0;
  min-width: 250px;
  padding: 1.4rem 5rem 1.4rem 2rem;
  width: 20vw;
  background-image: url("../images/icons/search-fill.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 10px !important;
}
.table__wrapper .dataTables_wrapper .dataTables_filter input:hover {
  border-color: #97989F;
}
.table__wrapper .dataTables_wrapper .dataTables_filter input:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.table__wrapper .dataTables_wrapper .dataTables_paginate {
  font-weight: 700;
  margin-top: 2.5rem;
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled) {
  background-color: transparent;
  border: 1px solid #6375E9;
  color: #6375E9 !important;
  transition: all 0.3s ease-in-out;
  border-radius: 20px;
  margin-left: 6px;
  padding: 0.5em 0.9em;
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):hover, .table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled).current {
  text-decoration: none;
  background-color: #6375E9 !important;
  background-image: none;
  color: #FFFFFF !important;
  border: 1px solid #6375E9;
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):focus {
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):active {
  background-color: #5064E5;
  background-image: none;
  border-color: transparent;
  box-shadow: 0 0 0 4px rgba(145, 188, 248, 0.8);
  transform: scale(0.85);
  transform-origin: center center;
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  cursor: default;
}
.table__wrapper .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  color: #97989F !important;
}
.table__wrapper .dataTables_wrapper .dataTables_length {
  float: unset;
}
.table__wrapper .dataTables_wrapper .dataTables_length select {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  margin: 0 3px;
  padding: 1.3rem 1.5rem;
}
.table__wrapper .dataTables_wrapper .dataTables_length select:hover {
  border-color: #97989F;
}
.table__wrapper .dataTables_wrapper .dataTables_length select:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.table__wrapper .dataTables_wrapper .dataTables_scroll .dtfc-right-top-blocker {
  background-color: #FFFFFF;
  width: 5px !important;
}
.table__wrapper .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}

table.dataTable {
  border-collapse: collapse;
  font-size: 1.2rem;
}
table.dataTable.no-footer {
  border-bottom: none;
}
table.dataTable.stripe tbody tr.odd {
  background-color: #FFFFFF;
}
table.dataTable.stripe tbody tr.odd > .dtfc-fixed-right {
  background-color: #FFFFFF;
}
table.dataTable.stripe tbody tr.even {
  background-color: #F2F2F2;
}
table.dataTable.stripe tbody tr.even > .dtfc-fixed-right {
  background-color: #F2F2F2;
}
table.dataTable thead th {
  background-color: #4E4F5F;
  border-bottom: none;
  color: #FFFFFF;
  padding: 1.1rem 1.8rem 1.1rem 0.5rem;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
table.dataTable thead th.sorting:hover {
  background-color: #353549;
}
table.dataTable thead th:first-child {
  border-top-left-radius: 5px;
}
table.dataTable thead th:first-child:hover {
  border-top-left-radius: 5px;
}
table.dataTable thead th:last-child {
  border-top-right-radius: 5px;
}
table.dataTable thead th:last-child:hover {
  border-top-right-radius: 5px;
}
table.dataTable thead th:focus:not(:active) {
  background-color: #71727F;
}
table.dataTable thead tr > .dtfc-fixed-right {
  background-color: #4E4F5F;
}
table.dataTable tbody tr {
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  transition: all 0.3s ease-in-out;
}
table.dataTable tbody tr .dt-check {
  border-left: 1px solid #E0E0E0;
}
table.dataTable tbody tr .dt-control,
table.dataTable tbody tr .dt-action {
  outline: 1px solid #E0E0E0;
}
table.dataTable tbody tr .dt-control {
  cursor: default;
}
table.dataTable tbody tr.shown td.dt-control {
  overflow: visible;
}
table.dataTable tbody td {
  overflow: hidden;
  padding: 0.6rem 1rem;
  text-overflow: ellipsis;
  vertical-align: middle;
}
table.dataTable tbody td:last-child {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
table.dataTable tbody td.cell-center {
  text-align: center;
}
table.dataTable tbody td.td_center {
  text-align: center;
  padding-right: 20px;
}
table.dataTable tbody td .check-picto:after {
  background-image: url("../images/icons/star.svg");
}
table.dataTable tbody td .check-picto:checked:after {
  background-image: url("../images/icons/star-active.svg");
}
table.dataTable tbody td .check-picto:focus {
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}

.table__top-left {
  float: left;
}
.table__top-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
  gap: 6px;
  float: right;
}
.table__top-left, .table__top-right {
  margin-bottom: 2rem;
}
.table__top-left label, .table__top-right label {
  margin-bottom: 0;
}
.table__action-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 50%;
  height: 30px;
  position: relative;
  width: 30px;
  z-index: 1;
}
.table__cell-icon {
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: block;
  font-size: 0;
  height: 22px;
  margin: 0 auto;
  width: 22px;
}
.table__icon-active {
  background-position: -331px -13px;
}
.table__modal-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-100%, 0);
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
  left: 50%;
  padding: 1rem 2rem;
}
.table__modal-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 0;
}
.table__modal-list-item {
  padding: 0.6rem;
}

.action__container {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 11;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .main-table__wrapper {
    padding: 0.5rem 0;
  }

  .table__top-left, .table__top-right {
    float: unset;
  }
  .table__top-right {
    justify-content: center;
  }

  .table__wrapper .dataTables_wrapper .dataTables_filter {
    text-align: center;
  }
  .table__wrapper .dataTables_wrapper .dataTables_filter input {
    min-width: 315px;
  }

  .table__action-btn:focus:not(.btn_disabled) {
    box-shadow: none;
  }
}
#tab-details,
#tab-attachments,
#tab-comments,
#tab-history {
  min-height: 70vh;
}

#tab-fav-projects,
#tab-fav-orders,
#tab-fav-proposals,
#tab-fav-clients {
  min-height: 45rem;
}

.tabs__wrapper {
  position: relative;
}
.tabs__wrapper.shadowed-js .tabs__content:not(.active-subtab-item) {
  position: relative;
}
.tabs__wrapper.shadowed-js .tabs__content:not(.active-subtab-item):before {
  background-color: #E8E8EB;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
  width: 100%;
  border-radius: 18px;
  left: 0;
  opacity: 0.7;
  z-index: 2;
}
.tabs-selector__block {
  background-color: #FFFFFF;
  display: none;
  padding: 3rem 2vw 1.5rem;
}
.tabs__title, .tabs__subtitle {
  background-color: transparent;
  border-radius: 18px 18px 0 0;
  color: #676876;
  cursor: pointer;
  display: inline-block;
  font-size: clamp(1.1rem, 0.86rem + 0.375vw, 1.4rem);
  font-weight: 900;
  line-height: 1.6rem;
  min-width: 11rem;
  padding: 2rem 1.1vw;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  user-select: none;
}
.tabs__title-parent {
  position: relative;
}
.tabs__title-parent.active-tab-js > .tabs__title-list-child {
  display: flex;
  z-index: 4;
}
.tabs__title-list {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.tabs__title-list_left {
  justify-content: flex-start;
}
.tabs__title-list_right {
  justify-content: flex-end;
}
.tabs__title-list-child {
  display: none;
  position: absolute;
  top: 100%;
}
.tabs__title.active-tab-js, .active-tab-js.tabs__subtitle {
  background-color: #FFFFFF;
  color: #6375E9;
  cursor: default;
}
.tabs__title.active-parent-tab-js, .active-parent-tab-js.tabs__subtitle {
  background-color: #FFFFFF;
  color: #6375E9;
}
.tabs__title:not(:last-of-type), .tabs__subtitle:not(:last-of-type) {
  margin-right: 4px;
}
.tabs__title:not(.active-tab-js):hover, .tabs__subtitle:not(.active-tab-js):hover, .tabs__title:not(.active-tab-js):active, .tabs__subtitle:not(.active-tab-js):active {
  color: #6375E9;
}
.tabs__title:not(.active-tab-js):focus, .tabs__subtitle:not(.active-tab-js):focus {
  background-color: rgba(255, 255, 255, 0.65);
}
.tabs__subtitle {
  white-space: nowrap;
}
.tabs__subtitle:not(.active-tab-js):focus {
  color: #6375E9;
}
.tabs__content {
  background-color: #FFFFFF;
  display: none;
  padding: 2vmin 2vw;
}
.tabs__content.active-tab-item {
  display: block;
}
.tabs__content.active-subtab-item {
  display: block;
  height: 56px;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 3;
}
.tabs__content_top {
  padding: 1.5rem 0;
}
.tabs__content_right {
  border-radius: 18px 0 18px 18px;
}
.tabs__content_right:not(:last-of-type) {
  border-radius: 18px;
}
.tabs__content_left {
  border-radius: 0 18px 18px 18px;
}
.tabs__content_left:not(:first-of-type) {
  border-radius: 18px;
}
.tabs__content-list {
  border-radius: 0;
}
.tabs__content_empty.active-tab-item ~ [data-btn=tabs-btn],
.tabs__content_empty.active-tab-item .dataTables_wrapper {
  display: none;
}
.tabs__content_empty.active-tab-item .tabs__notice {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.tabs__title-list {
  font-size: 0;
  margin-bottom: 0;
}
.tabs__notice {
  display: none;
}
.tabs__notice-icon {
  background-image: url(../images/icons/icons-sprite.svg);
  background-position: -264px -50px;
  background-repeat: no-repeat;
  background-size: auto;
  content: "";
  display: inline-block;
  margin-right: 1rem;
  height: 33px;
  width: 33px;
}
.tabs__notice-text {
  font-size: 1.2rem;
  width: calc(100% - 33px - 1rem);
}

.tabs-btn__container {
  padding-top: 1rem;
}
.tabs-btn__title-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.5rem;
}
.tabs-btn__title {
  border: 2px solid rgba(99, 117, 233, 0.6);
  border-radius: 6px;
  color: #6375E9;
  font-size: clamp(1.4rem, 0.5143rem + 0.8929vw, 1.8rem);
  font-weight: 900;
  margin-right: 1.2rem;
  padding: 1.3rem 2rem;
  text-transform: uppercase;
}
.tabs-btn__title.active-tab-js {
  background-color: #6375E9;
  color: #fff;
}
.tabs-btn__title:hover, .tabs-btn__title:active {
  background-color: #6375E9;
  color: #FFFFFF;
}
.tabs-btn__title:last-child {
  margin-right: 0;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .tabs-btn__title-list {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
  }
  .tabs-btn__title-list::-webkit-scrollbar {
    display: none;
  }
  .tabs-btn__title {
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .tabs-selector__block {
    display: block;
    margin-left: -2vw;
    width: calc(100% + 4vw);
  }
  .tabs__content {
    margin-left: -2vw;
    margin-right: -2vw;
  }
  .tabs__content_top {
    padding-bottom: 1rem;
    text-align: center;
  }
  .tabs__content_left, .tabs__content_right {
    border-radius: 0;
  }
  .tabs__content_left:not(:first-of-type), .tabs__content_left:not(:last-of-type), .tabs__content_right:not(:first-of-type), .tabs__content_right:not(:last-of-type) {
    border-radius: 0;
  }
  .tabs__title-list {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .tabs__content {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}
/*------Desktop media queries------*/
@media screen and (min-width: 1440px) {
  .tabs-btn__title {
    white-space: nowrap;
  }
}
.login__page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__page-logo {
  color: #FFC932;
  margin: 45px 0;
  font-size: 20px;
}
.login__page-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo__title {
  font-weight: 900;
}

.gescom-logo_large {
  margin: 0 auto;
  max-width: 265px;
}

.registration__form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  font-size: 1.4rem;
}
.registration__form-field {
  position: relative;
}
.registration__form-input {
  width: 100%;
  height: 50px;
  padding: 5px 22px;
  border-radius: 6px;
  border: 1px solid #E8E8EB;
  color: #02031B99;
}
.registration__form-input.password {
  padding-right: 47px;
}
.registration__form-input:focus, .registration__form-input:not(:placeholder-shown) {
  padding-bottom: 2px;
}
.registration__form-input:focus + .field-label, .registration__form-input:not(:placeholder-shown) + .field-label {
  font-size: 9px;
  opacity: 0.6;
  top: 11px;
  left: 19px;
}
.registration__form-input::placeholder {
  opacity: 0;
  overflow: hidden;
}
.registration__form-link-wrapper {
  margin-top: 12px;
  font-size: 1.2rem;
  text-align: right;
}
.registration__form-link-wrapper .link {
  color: #02031B99;
  padding: 3px;
}
.registration__form-link-wrapper .link:hover, .registration__form-link-wrapper .link:focus {
  color: #5364CE;
}
.registration__form-actions {
  margin-top: 34px;
  display: flex;
  justify-content: center;
}

.field-label {
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  color: #02031B99;
  transition: all 0.4s ease-in-out;
}

.login-field {
  margin-bottom: 30px;
}

.show-password-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.countries-page__sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  padding: 3vh 2.5vmin 3vh 3vmin;
  position: relative;
  width: 35%;
  z-index: 1;
}

.sidebar__header {
  padding-bottom: 2rem;
  width: 80%;
}
.sidebar__list {
  margin: 0;
}
.sidebar__link {
  border-radius: 6px;
  font-size: clamp(1.6rem, 0.6154rem + 0.9615vw, 2rem);
  padding: 4px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.sidebar__link:focus {
  outline: none;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}

.countries__list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem 2vw;
}
.countries__item-name {
  color: #02031B;
  text-transform: uppercase;
}
.countries__item-name:hover[data-country=france] {
  color: #FF5F0F;
}
.countries__item-name:hover[data-country=morocco] {
  color: #1EA0AE;
}
.countries__item-name:hover[data-country=ukraine] {
  color: #E79F0D;
}
.countries__item-name:hover[data-country=netherlands] {
  color: #D25698;
}
.countries__item-name:hover[data-country=switzerland] {
  color: #BB1B21;
}
.countries__item-name:hover[data-country=belgium] {
  color: #2BA65A;
}
.countries__item-name:hover[data-country=poland] {
  color: #E93A47;
}
.countries__item-name:hover[data-country=germany] {
  color: #862240;
}

.company__list-item {
  padding: 0.5rem 0;
}
.company__list-item:hover .smile-comp:after {
  background-position: 0px -176px;
}
.company__list-item:hover .creative-comp:after {
  background-position: -293px -102px;
}

.companies__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.companies__list:not(.country-popup__list) {
  flex-grow: 1;
  padding: 2rem 0;
}
.companies__item-name {
  align-items: center;
  display: inline-flex;
  font-size: 0;
}
.companies__item-name:after {
  background-image: url("../images/logos/logo-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  content: "";
  display: inline-block;
}
.companies__item-name.active.smile-comp:after {
  background-position: 0px -176px;
}
.companies__item-name.active.creative-comp:after {
  background-position: -293px -62px;
}

.smile-comp:after {
  background-position: 0px -140px;
  height: 35px;
  width: 115px;
}
.smile-comp.country-popup__link:hover:after, .smile-comp.country-popup__link:focus:after {
  background-position: 0px -176px;
}

.neopixl-comp:after {
  background-position: -171px -1px;
  height: 25px;
  width: 104px;
}
.neopixl-comp:hover:after, .neopixl-comp:focus:after {
  background-position: -171px -27px;
}

.creative-comp:after {
  background-position: -293px -63px;
  height: 32px;
  width: 166px;
}
.creative-comp.country-popup__link:hover:after, .creative-comp.country-popup__link:focus:after {
  background-position: -293px -102px;
}

.uxrepublic-comp:after {
  background-position: -158px -59px;
  height: 41px;
  width: 131px;
}
.uxrepublic-comp:hover:after, .uxrepublic-comp:focus:after {
  background-position: -158px -101px;
}

.sensiolabs-comp:after {
  background-position: -8px -4px;
  height: 24px;
  width: 151px;
}
.sensiolabs-comp:hover:after, .sensiolabs-comp:focus:after {
  background-position: -8px -33px;
}

.alterway-comp:after {
  background-position: -289px -1px;
  height: 30px;
  width: 162px;
}
.alterway-comp:hover:after, .alterway-comp:focus:after {
  background-position: -289px -31px;
}

.synotis-comp:after {
  background-position: -117px -142px;
  height: 31px;
  width: 85px;
}
.synotis-comp:hover:after, .synotis-comp:focus:after {
  background-position: -117px -175px;
}

.symfony-comp:after {
  background-position: -1px -61px;
  height: 38px;
  width: 153px;
}
.symfony-comp:hover:after, .symfony-comp:focus:after {
  background-position: -1px -100px;
}

.sidebar__footer-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem 2vw;
  margin: 0;
}
.sidebar__footer-link {
  display: inline-block;
  color: #676876;
  font-size: 1.4rem;
  text-decoration: underline;
}
.sidebar__footer-link:hover[data-gescom=inter], .sidebar__footer-link:focus[data-gescom=inter] {
  color: #5064E5;
}
.sidebar__footer-link:hover[data-gescom=corp], .sidebar__footer-link:focus[data-gescom=corp] {
  color: #394ED2;
}
.sidebar__footer-link:hover[data-gescom=group], .sidebar__footer-link:focus[data-gescom=group] {
  color: #868A94;
}
.sidebar__footer-link:hover[data-gescom=training], .sidebar__footer-link:hover[data-gescom=training2], .sidebar__footer-link:focus[data-gescom=training], .sidebar__footer-link:focus[data-gescom=training2] {
  color: #6375E9;
}
.sidebar__footer-link:hover[data-gescom="smiler&d"], .sidebar__footer-link:focus[data-gescom="smiler&d"] {
  color: #930DB8;
}

#countries {
  right: 0;
  width: 100%;
}
#countries .country-path:hover {
  cursor: pointer;
}

.country-popup {
  background-color: #FFFFFF;
  border-radius: 0 1rem 1rem 1rem;
  display: inline-block;
  padding: 2rem 1.5vw;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}
.country-popup__list {
  gap: 2rem;
  margin: 0;
}
.country-popup__list.companies__list {
  align-items: center;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  #countries {
    position: absolute;
    top: 50%;
    height: auto;
    transform: translateY(-50%);
  }

  .countries-page {
    background-color: #FFFFFF;
  }
  .countries-page__sidebar {
    width: 100%;
  }

  .companies__item-name.smile-comp:after {
    background-position: 0px -176px;
  }
  .companies__item-name.alterway-comp:after {
    background-position: -289px -31px;
  }
  .companies__item-name.creative-comp:after {
    background-position: -293px -102px;
  }
  .companies__item-name.neopixl-comp:after {
    background-position: -171px -27px;
  }
  .companies__item-name.sensiolabs-comp:after {
    background-position: -8px -33px;
  }
  .companies__item-name.uxrepublic-comp:after {
    background-position: -158px -101px;
  }
  .companies__item-name.synotis-comp:after {
    background-position: -117px -175px;
  }
  .companies__item-name.symfony-comp:after {
    background-position: -1px -100px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar__header {
    width: 60%;
  }

  .countries-page__wrapper {
    height: 100vh;
    background-color: #FFFFFF;
  }
  .countries-page__sidebar {
    padding: 4vh 4vmin 2vh;
  }

  .companies__list {
    gap: 2rem;
  }
}
@media screen and (max-height: 600px) {
  .countries-page__wrapper {
    height: 100%;
    position: relative;
  }

  .countries__list,
.companies__list {
    height: auto;
  }

  .countries__list-item,
.company__list-item {
    padding: 0.8rem 0;
  }

  .sidebar__footer-links {
    padding-top: 0.5rem;
  }
}
/*------Desktop media queries------*/
@media only screen and (min-width: 1440px) {
  .countries-page__sidebar {
    padding: 4rem 6rem 4rem 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  #countries {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
  }

  .countries-page__wrapper {
    height: 100vh;
  }
}
.document__label {
  border-radius: 1rem;
  display: inline-block;
  font-size: 1.2rem;
  line-height: normal;
  padding: 0.4rem 0.8rem;
}
.document__type-label {
  background-color: #CCCDD1;
  border: 1px solid #CCCDD1;
  color: #4E4F5F;
  text-align: center;
}
.document__date-label {
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  color: #4E4F5F;
}
.document__date-label:before {
  background-image: url("../images/icons/calendar.svg");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  height: 14px;
  margin-bottom: -2px;
  margin-right: 5px;
  width: 14px;
}
.document__warning-label {
  background-color: #FFFFFF;
  border: 1px solid #FF0544;
  color: #FF0544;
  text-align: center;
}
.document__requirement-item {
  padding-bottom: 3rem;
}
.document__title {
  color: #02031B;
  font-weight: 700;
}
.document__description {
  font-size: 1.2rem;
  display: block;
}

.edit-mode-js .addition__form {
  width: 100%;
}
.edit-mode-js .creating-elem-js {
  display: none;
  pointer-events: none;
}

.create-mode-js .editing-elem-js {
  display: none;
  pointer-events: none;
}

.addition__wrapper {
  border: 1px solid #E8E8EB;
  border-radius: 12px;
  height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;
  visibility: hidden;
}
.addition__wrapper.create-mode-js, .addition__wrapper.edit-mode-js {
  overflow: visible;
  visibility: visible;
}
.addition__form {
  margin-left: auto;
  padding-left: 1rem;
}
.addition__comment {
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
}
.addition__btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.addition__files-list {
  margin: 0;
  padding-right: 2rem;
}
.addition__files-list li:first-child {
  padding-top: 0;
}
.addition__files-list li:last-child {
  padding-bottom: 0;
}
.addition__block_bottom {
  padding-top: 3rem;
  justify-content: flex-end;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .addition__files-list, .addition__btn-container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .addition__form {
    margin-left: 0;
    margin-top: 1rem;
    padding: 0 0.8rem;
    width: 100%;
  }
  .addition__adv-list {
    width: 100%;
  }
  .addition__files-list {
    padding-right: 0;
  }
  .addition__btn-container {
    justify-content: space-between;
    margin-top: 1rem;
  }
}
.attach-row, .comment-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  border-bottom: 1px solid #E8E8EB;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  width: 100%;
}
.attach-row__content, .comment-row__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  gap: 1.5vw;
}
.attach-row__actions, .comment-row__actions {
  padding-top: 6px;
  padding-bottom: 1rem;
}
.attach-cell, .comment-cell {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.attach__files-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.attach__files-list .attach__file-item {
  width: 100%;
}
.attach__file-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.5em 0;
}
.attach__file {
  width: 30%;
}
.attach__file-label {
  margin: 0 1vw 0 0;
  cursor: pointer;
  width: auto;
}
.attach__file-label:active {
  transform: scale(0.9);
}
.attach__file-input {
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.attach__file-img {
  margin-right: 0.5rem;
}
.attach__file-name {
  margin-right: 1vw;
  max-width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attach__download-link {
  margin-right: 1vw;
  padding: 3px;
}
.attach__file-size {
  color: #676876;
  margin-right: 0.7vw;
  position: relative;
  white-space: nowrap;
}
.attach__file-size:before {
  content: "/";
  position: absolute;
  left: -0.9vw;
  top: 0;
}
.attach__author {
  width: 20%;
}
.attach__date, .comment__date {
  width: 110px;
}
.attach__comment {
  width: calc(100% - 110px - 20% - 30% - 4.5vw);
}
.attach__filter {
  padding: 2rem 0;
}

/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .attach-row, .comment-row {
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }
  .attach-row__actions, .comment-row__actions {
    margin-left: auto;
  }
  .attach-row__content, .comment-row__content {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .attach-row__content, .comment-row__content {
    flex-wrap: wrap;
    gap: 0;
  }
  .attach__file {
    order: 1;
    width: 100%;
  }
  .attach__file-item {
    flex-wrap: wrap;
  }
  .attach__file-label {
    margin-right: 1rem;
  }
  .attach__file-name {
    margin-right: 1rem;
    padding: 1.5rem 0;
  }
  .attach__file-name_old {
    max-width: 50%;
    width: auto;
  }
  .attach__file-name_new {
    max-width: unset;
    width: calc(100% - 1rem - 22px);
  }
  .attach__file-size:before {
    left: -0.9rem;
  }
  .attach__comment {
    order: 2;
    width: 100%;
  }
  .attach__author {
    width: calc(100% - 110px);
  }
  .attach__date, .comment__date {
    padding-left: 1rem;
  }
  .attach__download-link {
    margin-right: 1rem;
    max-width: 60%;
    padding: 1.5rem 0;
  }
  .attach__download-link:last-child {
    max-width: 80%;
  }
  .attach__filter {
    width: 100%;
  }
}
.comment__author-photo {
  width: 54px;
}
.comment__main-info {
  width: 30%;
}
.comment__info {
  color: #676876;
}
.comment__content {
  width: calc(100% - 54px - 30% - 110px - 4.5vw);
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .comment-row__content {
    position: relative;
  }
  .comment__main-info {
    padding-left: 1rem;
    width: calc(100% - 54px);
  }
  .comment__author {
    padding-right: 110px;
  }
  .comment__content {
    width: 100%;
  }
  .comment__date {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.activity__wrapper {
  margin: 0 auto;
  max-width: 1240px;
}
.activity__info {
  padding-right: 2vw;
}
.activity__dashboard {
  padding-left: 2vw;
}
.activity__dashboard_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem 0;
  padding-bottom: 3rem;
  padding-top: 1rem;
}
.activity__dashboard_top [for=show-weekends] {
  flex-basis: calc(2rem + 150px);
}
.activity__dashboard_bottom {
  padding-top: 6rem;
  padding-bottom: 1rem;
  text-align: right;
}
.activity__week-item, .activity__month-total {
  background-color: #FFFFFF;
  border-radius: 1rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
  color: #02031B;
  margin-bottom: 2rem;
  padding: 2rem;
}
.activity__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 700;
  padding-bottom: 2rem;
}
.activity__header .activity__action {
  flex-grow: 1;
}
.activity__title {
  font-size: 1.8rem;
  flex-basis: 150px;
}
.activity__action {
  flex-basis: 230px;
}
.activity__total {
  margin-left: auto;
}
.activity__day-item, .activity__day-item_weekend, .activity__day-item_absent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.5rem 0;
}
.activity__day-item + .activity__day-item_weekend, .activity__day-item_absent + .activity__day-item_weekend {
  border-top: 1px solid #E0E0E0;
  margin-top: 1rem;
  padding-top: 1.5rem;
}
.activity__day-item_weekend:not(.hide-elem-js) + .activity__day-item, .activity__day-item_weekend:not(.hide-elem-js) + .activity__day-item_absent {
  border-top: 1px solid #E0E0E0;
  margin-top: 1rem;
  padding-top: 1.5rem;
}
.activity__day {
  flex-basis: 150px;
}
.activity__comment {
  flex-basis: calc(100% - 150px - 230px);
}
.activity__comment-input {
  height: 40px;
  padding-left: 1rem;
}
.activity__month-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 700;
}
.activity__summary {
  margin-left: auto;
}
.activity__current-total, .total-warning .activity__allocation {
  display: inline-block;
  width: 100%;
}
.activity__allocation {
  display: none;
}

.total-warning {
  color: #FFFFFF;
  background-color: #CF3200;
}
/*------Mobile media queries------*/
@media screen and (max-width: 1023px) {
  .activity__info {
    padding-right: 0;
    width: 100%;
  }
  .activity__dashboard {
    padding-left: 0;
    width: 100%;
  }
}
/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .activity__dashboard_top {
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem 0;
    padding-bottom: 1rem;
  }
  .activity__dashboard_top [for=show-weekends],
.activity__dashboard_top .form__label {
    flex-basis: auto;
    margin-bottom: 2rem;
  }
  .activity__dashboard_bottom {
    padding-top: 4rem;
    text-align: center;
  }
  .activity__dashboard_bottom .mr-3 {
    margin-left: 1rem;
  }
  .activity__dashboard_bottom .btn_primary, .activity__dashboard_bottom .contributed-link {
    margin-bottom: 1.5rem;
  }
  .activity__header {
    flex-wrap: wrap;
  }
  .activity__header .activity__title {
    flex-basis: 100%;
    padding-bottom: 1rem;
  }
  .activity__action {
    flex-basis: auto;
  }
  .activity__day-item, .activity__day-item_weekend, .activity__day-item_absent {
    flex-wrap: wrap;
  }
  .activity__day-item .activity__action, .activity__day-item_weekend .activity__action, .activity__day-item_absent .activity__action {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    flex-basis: 100%;
  }
  .activity__day, .activity__comment {
    flex-basis: 100%;
  }
  .activity__day {
    margin-bottom: 1rem;
  }
  .activity__comment {
    margin-bottom: 1.5rem;
  }
}
.recipient-selector {
  padding: 1.5rem 0 2rem;
  position: relative;
}
.recipient-selector .easy-autocomplete {
  width: auto !important;
}
.recipient-selector__container {
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  padding: 9px 20px;
}
.recipient-selector__user-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.recipient-selector__list-item {
  align-items: center;
  border: 1px solid #6375E9;
  border-radius: 20px;
  display: flex;
  margin: 5px 5px 5px 0;
  padding: 6px 8px;
}
.recipient-selector__user-name {
  color: #6375E9;
  font-size: 1.2rem;
  padding-right: 0.8rem;
  white-space: nowrap;
}
.recipient-selector__select {
  flex-grow: 1;
  transition: all 0.3s ease-in-out;
}
.recipient-selector__select:not(:focus) {
  box-shadow: none !important;
}
.recipient-selector__counter {
  align-self: center;
  font-size: 1.4rem;
  color: #02031B99;
  overflow: hidden;
  padding: 1rem 0 1rem 1rem;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recipient-selector__counter:before {
  content: "+";
}
.recipient-selector__number {
  margin-right: 3px;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .recipient-selector__counter {
    width: 50%;
  }
  .recipient-selector .grid__col-8 {
    width: 100%;
    order: 1;
  }
}
.expenses__wrapper {
  overflow-x: auto;
}
.expenses__header {
  font-weight: 900;
  color: #02031B;
  margin-top: 3rem;
  min-width: 100%;
  padding-bottom: 0.4rem;
  padding-right: 2rem;
  text-transform: uppercase;
  width: fit-content;
}
.expenses__header__month {
  min-width: 1100px;
  width: calc(100% - 22rem - 10rem - 2rem);
}
.expenses__block, .expenses__grand-total {
  background-color: #FFFFFF;
  border-radius: 1rem;
  margin-bottom: 1.2rem;
  min-width: 100%;
  padding: 0 2rem;
  width: fit-content;
}
.expenses__row {
  padding: 0.6rem 0;
}
.expenses__row.grid__row {
  align-items: center;
}
.expenses__row-title {
  padding-right: 1rem;
  min-width: 22rem;
  width: 22rem;
}
.expenses__row-total {
  align-self: center;
  padding-left: 1rem;
  text-align: right;
  min-width: 10rem;
  width: 10rem;
}
.expenses__row-child {
  border-top: 1px solid #E0E0E0;
}
.expenses__col-total {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0.6rem 0;
  text-align: center !important;
  white-space: nowrap;
  width: 100%;
}
.expenses__col-total[data-total=col-total] {
  color: #676876;
}
.expenses__grand-total {
  background-color: #676876;
  color: #FFFFFF;
  font-weight: 700;
}
.expenses__year-block {
  align-self: center;
  font-size: 2.8rem;
  margin-right: 2rem;
  min-width: 22rem;
  width: 22rem;
}
.expenses__current-year {
  margin-right: 3rem;
}
.expenses__month-block {
  min-width: 1100px;
  width: calc(100% - 10rem - 22rem);
}
.expenses__month-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 0.5vw;
  text-align: center;
}
.expenses__input {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
  font-size: 1.2rem;
  height: 32px;
  padding: 0.8rem 1rem;
  text-align: right;
  width: 100%;
}
.expenses__input:hover {
  border-color: #97989F;
}
.expenses__input:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 1px #5266e5, 0 0 0 3px rgba(145, 188, 248, 0.8);
}
.expenses__link-action {
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: underline;
  text-transform: none;
}

.unit-card {
  display: flex;
  justify-content: flex-start;
}
.unit-card_row {
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding-right: 1.5rem;
}
.unit-card_col {
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}
.unit-card__name {
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 0;
}
.unit-card__id {
  font-size: 1.6rem;
}
.unit-card__info-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0;
}
.unit-card__picto:before {
  background-image: url("../images/icons/icons-sprite.svg");
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
  content: "";
  display: inline-block;
  height: 22px;
  margin-right: 1rem;
  width: 22px;
}
.unit-card__picto-title {
  line-height: 22px;
}
.unit-card__text_capital {
  font-weight: 700;
}
.unit-card__text_minor {
  color: #676876;
  font-size: 1.2rem;
  margin: 0;
  padding: 0.5rem 0;
}
.unit-card__text_row {
  width: 100%;
}
.unit-card__logo-block {
  margin-bottom: 4rem;
  position: relative;
  width: 80%;
}
.unit-card__logo-edit {
  position: absolute;
  top: 100%;
  transform: translate(50%, -50%);
  margin: 0;
  right: 0;
}
.unit-card__logo-edit:active:not(.btn_disabled) {
  transform: translate(50%, -50%) scale(0.8);
}
.unit-card__logo_small {
  height: 4rem;
  width: auto;
}
.unit-card__logo_large {
  height: auto;
  width: 100%;
}

.picto-bussiness:before {
  background-position: -14px -14px;
}

/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .unit-card_row {
    flex-wrap: wrap;
  }
  .unit-card__logo-block {
    margin: 0 auto 4rem;
    max-width: 250px;
  }
}
.supplier-table__wrapper {
  width: 100%;
}
.supplier__top-block {
  border-bottom: 1px solid #CCCDD1;
}
.supplier__status_pending {
  background-color: #FFFFFF;
  border-radius: 2rem;
  border: 1px solid #676876;
  color: #676876;
  font-weight: 700;
  line-height: normal;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}
.supplier__status_validated {
  background-color: #FFFFFF;
  border-radius: 2rem;
  border: 1px solid #26BC94;
  color: #26BC94;
  font-weight: 700;
  line-height: normal;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}
.supplier__status_active {
  background-color: #26BC94;
  border-radius: 2rem;
  border: 1px solid #26BC94;
  color: #FFFFFF;
  font-weight: 700;
  line-height: normal;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}
.supplier__status_standby {
  background-color: #FFFFFF;
  border-radius: 2rem;
  border: 1px solid #FF5F0F;
  color: #FF5F0F;
  font-weight: 700;
  line-height: normal;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}
.supplier__status_closed {
  background-color: #FF5F0F;
  border-radius: 2rem;
  border: 1px solid #FF5F0F;
  color: #FFFFFF;
  font-weight: 700;
  line-height: normal;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}
.supplier__section {
  background-color: #FFFFFF;
  padding: 1vmin 1vw 3vmin;
}
.supplier__doc-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  gap: 1vw;
}
.supplier__doc-param {
  width: 18%;
}
.supplier__doc-file {
  width: 20%;
}
.supplier__doc-comment {
  width: calc(42% - 95px - 4vw);
}
.supplier__doc-author {
  width: 20%;
}
.supplier__doc-date {
  width: 95px;
}
.supplier__doc-status {
  border-bottom: 1px solid #E8E8EB;
  padding-bottom: 1rem;
}
.supplier__doc-row {
  align-items: flex-start;
  padding: 0.5rem 0;
}
.supplier__doc-description {
  padding-left: 1vw;
  width: 82%;
}
.supplier__doc_old {
  color: #000000;
  font-weight: 700;
  padding-top: 2rem;
}

/*------Desktop media queries------*/
@media screen and (max-width: 1439px) {
  .supplier__unit-card {
    width: 25%;
  }
  .supplier__info-grid {
    width: 75%;
  }
  .supplier__section.grid__col-3 {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .supplier__form .form__col_xxlarge {
    width: 66.67%;
  }
  .supplier__unit-card {
    width: 35%;
  }
  .supplier__info-grid {
    width: 65%;
  }
  .supplier__section.grid__col-3 {
    width: 100%;
  }
}
/*------Mobile media queries------*/
@media screen and (max-width: 767px) {
  .supplier__top-block {
    padding-top: 2rem;
    padding-bottom: 3vmin;
    padding-right: 45px;
  }
  .supplier__action {
    padding-top: 2rem;
  }
  .supplier__unit-card {
    width: 100%;
  }
  .supplier__section .grid__container > .grid__col-6 {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .supplier__info-grid {
    width: 100%;
  }
  .supplier__doc-content {
    flex-wrap: wrap;
    gap: 0;
  }
  .supplier__doc-row {
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .supplier__doc-author {
    width: calc(100% - 95px);
  }
  .supplier__doc-date {
    padding-left: 1rem;
  }
  .supplier__doc-param {
    order: 1;
  }
  .supplier__doc-file, .supplier__doc-description {
    order: 2;
  }
  .supplier__doc-comment {
    order: 3;
  }
  .supplier__doc-param, .supplier__doc-file, .supplier__doc-comment, .supplier__doc-description {
    width: 100%;
  }
}