$history-content__color: #02031B99;

.proposal {
	&__main-info {
		padding: 1rem 0;
	}

	&__actions {
		margin-left: auto;
	}

	&__section {
		background-color: $tab-content__bg-color;
		padding: 3vmin 1vw;
	}

	&__title {
		display: inline-block;
		margin: 0;
	}

	&__subtitle {
		font-size: clamp(1.4rem, 0.7351rem + 0.6494vw, 1.8rem);
		margin-bottom: 1.5vmin;
		@include subtitle;
	}

	&__tags {
		gap: 1rem;
	}

	&__tag {
		padding: .5rem 1.5rem;
		color: $default-cornflower-blue;
		border: 2px solid $default-cornflower-blue;
		border-radius: 32px;
	}
}

.history {
	&__item {
		padding-bottom: 1.5rem;
		padding-top: 2.5rem;

		&:first-child {
			padding-top: 0;
		}
	}

	&__author {
		padding-top: 21px;
	}

	&__content {
		color: $default-comet;
		margin: 0;
	}

	&__param {
		font-weight: $font-weight__bold;
	}

	&__param,
	&__value_new {
		color: $default-jaguar;
	}

	&__value_old {
		font-style: italic;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__l - 1px}) {
	.proposal {
		&__main-info {
			width: 100%;
		}

		&__section {
			width: 100%;
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.com-prop-view-mode {
		#main-page {
			&.shadowed-js {
				height: 100vh;
				overflow: hidden;
			}
		}
	}

	.proposal {
		&__main-info {
			padding: 2rem 0
		}

		&__actions {
			padding-top: 2rem;

			.actions__list {
				margin-top: 2rem;
			}
		}

		&__section {
			padding: 3vmin 0;

			& > .grid__container {
				& > .grid__col-6 {
					width: 100%;
				}

				& > .grid__cell {
					&_pl,
					&_pr {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}

			.proposal__cell_large {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__subtitle {
			padding-bottom: 1vmin;
		}
	}

	.history {
		&__content {
			&.row-item__content {
				order: 1;
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}

		&__author,
		&__date {
			align-self: center;
		}

		&__author {
			padding-top: .7rem;
		}
	}
}
