.mm-menu_theme-white {
	--mm-color-button: #6375E9;
	--mm-color-text: #02031B;
	--mm-color-text-dimmed: #6375E9;
	--mm-color-background-highlight: #FFF;
}

.mm-listitem {
	a:hover {
		background-color: $default-alabaster;
	}

	&_selected {
		color: $default-cornflower-blue;
	}
}

.mm-navbar {
	&__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
		background: none;
	}

	& > .mm-navbar__title {
		flex: auto;
		font-weight: $font-weight__bold;
		text-transform: uppercase;
	}

	&__title {
		font-weight: $font-weight__bold;
		color: $default-menu__navbar-title;
	}
}

.mm-navbars {
	&_top {
		padding: 15px 0;

		.mm-navbar {
			flex-direction: column;

			&__btn {
				align-self: flex-end;
				order: -1;
			}

			&__title {
				align-self: flex-start;
				font-weight: $font-weight__most-bold;
				font-size: clamp(2rem, 0.9333rem + 1.0417vw, 2.6rem);
				letter-spacing: .18rem;
				text-transform: uppercase;
			}

			&__searchfield {
				align-self: center;
				width: 90%;
			}
		}

		.mm-searchfield__input {
			padding-right: 0;
		}
	}

	&_bottom {
		padding: 0 3rem;

		.mm-navbar {
			min-height: 80px;
		}

		.lang-switcher {
			display: none;
			flex-direction: row;
			justify-content: flex-start;

			&__label {
				font-size: $font-size__regular;
				height: 2.8rem;
				line-height: 2.4rem;
				margin-bottom: 0;
				margin-right: 2rem;
				width: 4rem;
			}
		}
	}
}

.mm-searchfield {
	&__btn {
		right: 10px;
	}

	&__input {
		padding: 0 15px;

		input {
			background-image: url("../images/icons/search-fill.svg");
			background-repeat: no-repeat;
			background-position: right 10px bottom 50%;
			border: 1px solid $default-athens-gray !important;
			border-radius: 21px;
			height: 43px;
			padding-right: 50px;

			&:hover,
			&:focus {
				background-image: url("../images/icons/search-fill.svg");
				background-repeat: no-repeat;
				background-position: right 10px bottom 50%;
			}

			&:not(:placeholder-shown) {
				background: none;
			}
		}
	}
}

.mm-btn_close {
	&:before,
	&:after {
		width: 7px;
		height: 7px;
	}

	&:before {
		right: 15px;
	}

	&.mm-navbar__btn {
		&:before {
			border-color: $default-manatee;
			right: 10px;
		}

		&:after {
			border-color: $default-manatee;
		}

		&:before,
		&:after {
			width: 10px;
			height: 10px;
		}
	}
}

#mm-0 {
	.mm-navbar {
		min-height: 2rem;

		&__title {
			display: none;
		}
	}
}

#gescom-menu {
	&.mm-menu_opened {
		z-index: 1;
	}

	.mm-navbars_top .mm-navbar {
		border-bottom: none;
		justify-content: space-between;
	}

	.mm-panels .mm-navbar {
		border-bottom: none;
	}

	.mm-listitem__btn {
		border-left: 0;
	}
}

#mm-0 {
	.mm-listitem {
		&__text {
			display: flex;
			align-items: center;
		}

		&:not(.account__menu) > .mm-listitem__text:before {
			background-image: url("../images/icons/icons-sprite.svg");
			background-repeat: no-repeat;
			background-size: auto;
			border: none;
			content: "";
			display: inline-block;
			height: 22px;
			margin-right: 20px;
			width: 22px;
		}
	}
}

.menu_production_item .mm-listitem__text:before {
	background-position: -89px -14px;
}

.menu_sales_item .mm-listitem__text:before {
	background-position: -14px -14px;
}

.menu_adv_item .mm-listitem__text:before {
	background-position: -277px -14px;
}

.menu_purchase_item .mm-listitem__text:before {
	background-position: -52px -14px;
}

.menu_finance_item .mm-listitem__text:before {
	background-position: -202px -14px;
}

.menu_hr_item .mm-listitem__text:before {
	background-position: -164px -14px;
}

.menu_payroll_item .mm-listitem__text:before {
	background-position: -127px -14px;
}

.menu_administration_item .mm-listitem__text:before {
	background-position: -240px -14px;
}

.mm-navbar_sticky:not(.mm-hidden) ~ .mm-listview .mm-divider {
	top: 0 !important;
}

@media screen and (max-width: calc($screen__m - 1px)) {
	.mm-navbars_top {
		.mm-navbar {
			flex-direction: row;
			flex-wrap: wrap;

			&__btn {
				align-self: flex-start;
				order: 0;
			}

			&__title {
				justify-content: flex-start;
				margin-top: 2rem;
				padding-left: 3vw;
			}

			&__searchfield {
				padding: 0 3vw;
			}
		}
	}

	.mm-navbars_bottom {
		.lang-switcher {
			display: flex;
		}
	}
}
