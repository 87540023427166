$activity-wrapper__max-width: 1240px;
$activity-week-item__padding: 2rem;
$activity-1st-column__width: 150px;
$activity-2nd-column__width: 230px;

.activity {
	&__wrapper {
		margin: 0 auto;
		max-width: $activity-wrapper__max-width;
	}

	&__info {
		padding-right: 2vw;
	}

	&__dashboard {
		padding-left: 2vw;

		&_top {
			@include flex(
					$justify: flex-start,
					$wrap: wrap
			);
			gap: 1.5rem 0;
			padding-bottom: 3rem;
			padding-top: 1rem;

			[for='show-weekends'] {
				flex-basis: calc($activity-week-item__padding + $activity-1st-column__width);
			}
		}

		&_bottom {
			padding-top: 6rem;
			padding-bottom: 1rem;
			text-align: right;
		}
	}

	&__week {
		&-item {
			background-color: $default-white;
			border-radius: 1rem;
			box-shadow:  0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
			color: $default-jaguar;
			margin-bottom: 2rem;
			padding: $activity-week-item__padding;
		}
	}

	&__header {
		@include flex(
				$justify: flex-start
		);
		font-weight: $font-weight__bold;
		padding-bottom: 2rem;

		.activity__action {
			flex-grow: 1;
		}
	}

	&__title {
		font-size: 1.8rem;
		flex-basis: $activity-1st-column__width;
	}

	&__action {
		flex-basis: $activity-2nd-column__width;
	}

	&__total {
		margin-left: auto;
	}

	&__day-item,
	&__day-item_weekend,
	&__day-item_absent {
		@include flex(
				$justify: flex-start
		);
		padding: .5rem 0;
	}

	&__day-item,
	&__day-item_absent {
		& + .activity__day-item_weekend {
			border-top: 1px solid $default-gainsboro;
			margin-top: 1rem;
			padding-top: 1.5rem;
		}
	}

	&__day-item {
		&_weekend:not(.hide-elem-js) {
			& + .activity__day-item,
			& + .activity__day-item_absent {
				border-top: 1px solid $default-gainsboro;
				margin-top: 1rem;
				padding-top: 1.5rem;
			}
		}
	}

	&__day {
		flex-basis: $activity-1st-column__width;
	}

	&__comment {
		flex-basis: calc(100% - $activity-1st-column__width - $activity-2nd-column__width);

		&-input {
			height: 40px;
			padding-left: 1rem;
		}
	}

	&__month-total {
		@include flex();
		@extend .activity__week-item;
		font-weight: $font-weight__bold;
	}

	&__summary {
		margin-left: auto;
	}

	&__current-total {
		display: inline-block;
		width: 100%;
	}

	&__allocation {
		display: none;
	}
}

.total-warning {
	color: $default-white;
	background-color: $default-harley-davidson-orange;

	.activity__allocation {
		@extend .activity__current-total;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__l - 1px}) {
	.activity {
		&__info {
			padding-right: 0;
			width: 100%;
		}

		&__dashboard {
			padding-left: 0;
			width: 100%;
		}
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.activity {
		&__dashboard {
			&_top {
				align-items: flex-start;
				flex-direction: column;
				gap: .5rem 0;
				padding-bottom: 1rem;

				[for=show-weekends],
				.form__label {
					flex-basis: auto;
					margin-bottom: 2rem;
				}
			}

			&_bottom {
				padding-top: 4rem;
				text-align: center;

				.mr-3 {
					margin-left: 1rem;
				}

				.btn_primary {
					margin-bottom: 1.5rem;
				}
			}
		}

		&__header {
			flex-wrap: wrap;

			.activity__title {
				flex-basis: 100%;
				padding-bottom: 1rem;
			}
		}

		&__action {
			flex-basis: auto;
		}

		&__day-item,
		&__day-item_weekend,
		&__day-item_absent {
			flex-wrap: wrap;

			.activity__action {
				@include flex(
						$justify: space-around
				);
				margin-bottom: 2rem;
				flex-basis: 100%;
			}
		}

		&__day,
		&__comment {
			flex-basis: 100%;
		}

		&__day {
			margin-bottom: 1rem;
		}

		&__comment {
			margin-bottom: 1.5rem;
		}
	}
}
