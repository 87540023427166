html {
	font-size: $default__font-size;
}

#main-page {
	z-index: auto;

	&.shadowed-js {
		.page__header,
		.page__main,
		.page__footer {
			position: relative;

			&:before {
				@include pseudo(
						$background: rgba(0,0,0,0.4),
						$height: 100%,
						$top: 0,
						$translateY: 0,
						$translateX: 0,
						$width: 100%
				);
				left: 0;
				z-index: 10;
			}
		}
	}
}

body {
	background-color: $default-background-page;
	color: $default-color-text;
	font-family: $default-page_font_family;
	font-weight: $font-weight__regular;
	font-size: $default-page__font_size;

	.hide-elem-js {
		display: none;
	}
}

.content__wrapper {
	width: 100%;
	max-width: $default-width-page;
	margin: 0 auto;
	padding: 0 $content-wrapper__padding;
}

.main__container {
	padding-bottom: 4rem;
}

.pos_rb {
	bottom: 5px;
	right: -3px;
}

.pos_rm {
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
}

.page {
	&__main {
		padding-top: 1.5rem;
	}

	&__title {
		padding: 2rem 0;
		margin: 0;

		&_higher {
			padding: 3rem 0;
		}
	}

	&__subtitle {
		font-size: $default-subtitle__font-size;
		font-weight: $default-subtitle__font-weight;
		line-height: 18px;
		text-transform: uppercase;
	}

	&__buttons-block {
		@include flex(
			$justify: flex-end
		);
		gap: 1.6rem;
		padding: 3rem 0;
		width: 100%;
	}

	&__footer {
		padding: 3rem 0;
	}
}

.top__wrapper {
	padding: 2.9rem 0 1.5rem;
	margin-bottom: 1.6rem;
	@include flex(
			$wrap: wrap);
	width: 100%;
}

.footer {
	&__wrapper {
		position: relative;
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}

	&__logo {
		&_desktop {
			width: 57vw;
			max-width: 750px;
		}

		&_mobile {
			display: none;
		}
	}
}

.logo-block {
	@include absPosition();
	left: 50%;
	margin: 0;
}

.align {
	&-v_middle {
		display: inline-flex;
		align-items: center;
	}

	&-h_right {
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
	}

	&_center {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}

.text {
	&_right {
		text-align: right;
	}

	&_bolder {
		font-weight: bolder;
	}

	&-alert {
		color: $default-torch-red;
	}
}

.content__wrapper.footer__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.support-link__wrapper {
	text-align: right;
}

.site_version_block .version {
	color: $default-cornflower-blue;
	font-weight: $font-weight__bold;
	padding: 6px 28px 6px 8px;
}

.main {
	&-info {
		@include flex();
		gap: 3vw;

		&_center {
			position: relative;

			&:before,
			&:after {
				@include pseudo(
						$background: $default-link-water,
						$height: 80%,
						$translateX: 0);
			}

			&:before {
				left: -1.5vw;
			}

			&:after {
				right: -1.5vw;
			}
		}

		&__content {
			@include flex($justify: flex-start, $wrap: wrap);
			margin: 0;
		}
	}
}

.actions {
	&__btn {
		display: none;
	}

	&__list {
		@include flex();
		gap: 15px;
		margin: 0;
	}
}

.action {
	&__item_empty {
		height: 32px;
		width: 30px;
	}

	&__label {
		display: none;
		color: $default-cornflower-blue;
		font-weight: $font-weight__bold;
		padding-left: 1.2rem;
	}
}

.section {
	&__subtitle {
		@include subtitle;
		font-size: $default-subtitle__font-size;
		font-weight: $default-subtitle__font-weight;
		margin-bottom: 1.5vmin;
	}
}

.loader-overlay {
	overflow: hidden;
	position: relative;

	&:after {
		background: rgb(242,242,242);
		background: radial-gradient(circle, rgba(224,224,224,1) 20%, rgba(242,242,242,1) 100%);
		bottom: 0;
		content: '';
		display: inline-block;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 7;
	}

	&:before {
		@include absPosition();
		background-image: url("../images/spinner-250px.gif");
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		height: 20%;
		left: 50%;
		max-height: 80px;
		max-width: 80px;
		width: 10%;
		z-index: 8;
	}
}

.scroll__wrapper {
	position: relative;

	&.show-chevron-js {
		&:after {
			@include pseudo(
					$height: 30px,
					$top: 100%,
					$translateY: -8rem,
					$width: 30px
			);
			background-image: url(../images/icons/down-shevron.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			right: -1rem;
			animation: scroll-down 1s linear infinite;
		}
	}
}

@keyframes scroll-down {
	0% {
		top: 100%;
	}
	50% {
		top: calc(100% - 15px);
	}
	100% {
		top: 100%;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__l - 1px}) {
	.top__wrapper {
		padding: 5vmin 0 2vmin;
	}

	.logo-block {
		position: relative;
		left: unset;
		padding-bottom: 4rem;
		top: unset;
		transform: none;
		width: 100%;
	}

	.footer {
		&__wrapper {
			flex-wrap: wrap;
		}

		&__logo {
			&_desktop {
				display: none;
			}

			&_mobile {
				display: block;
				margin: 0 auto;
			}
		}
	}

	.site_version_block,
	.support-link__wrapper {
		order: 2;
		width: 50%;
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.content__wrapper {
		padding: 0 $content-wrapper__padding_mobile;
	}

	.top__wrapper {
		padding-bottom: 0;
		padding-top: 0;
		position: relative;
	}

	.main-actions__container {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;

		.action {
			&__item {
				@include flex();
			}

			&__label {
				display: inline-block;
			}
		}

		.btn-picto {
			.icon {
				margin-right: 4px;
			}
		}
	}

	.actions {
		&__list {
			&_modal {
				align-items: flex-start;
				background-color: $default-white;
				border-radius: 8px;
				box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
				display: none;
				flex-direction: column;
				gap: 1rem;
				padding: 2.5rem 3rem 2.5rem 2rem;
				position: absolute;
				right: calc($btn-show-actions__size / 2);
				top: calc($btn-show-actions__size / 2);
				z-index: -1;

				&.show-js {
					display: flex;
				}
			}
		}
	}

	.page {
		&__title {
			padding-top: 1.8rem;

			&[data-action="update-title"] {
				display: none;
			}
		}

		&__buttons-block {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.main-info {
		flex-wrap: wrap;

		&_left,
		&_center,
		&_rigth {
			width: 100%;
		}

		&_left {
			padding-right: calc($btn-show-actions__size + 1rem);
		}

		&_center {
			&:before,
			&:after {
				content: none;
			}
		}
	}
}

@media screen and (max-width: $screen__xs) {
	.site_version_block .version {
		padding: 0;
	}
}
