//variables
$max-width-form: 500px;
$logo-width: 265px;

.login__page {
	&-header {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&-logo {
		color: $default-sunglow;
		margin: 45px 0;
		font-size: 20px;
	}

	&-section {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.logo {
	&__title {
		font-weight: $font-weight__most-bold;
	}
}

.gescom-logo_large {
	margin: 0 auto;
	max-width: $logo-width;
}

.registration__form {
	display: flex;
	flex-direction: column;
	max-width: $max-width-form;
	margin: 0 auto;
	font-size: $font-size__regular;

	&-field {
		position: relative;
	}

	&-input {
		width: 100%;
		height: 50px;
		padding: 5px 22px;
		border-radius: 6px;
		border: 1px solid $default-athens-gray;
		color: $default-jaguar2;

		&.password {
		padding-right: 47px;
	}

		&:focus,
		&:not(:placeholder-shown) {
			padding-bottom: 2px;

			+ .field-label {
				font-size: 9px;
				opacity: 0.6;
				top: 11px;
				left: 19px;
			}
		}

		&::placeholder {
			opacity: 0;
			overflow: hidden;
		}
	}

	&-link-wrapper {
		margin-top: 12px;
		font-size: $font-size__light;
		text-align: right;

		.link {
			color: $default-jaguar2;
			padding: 3px;

			&:hover,
			&:focus {
				color: $default-indigo;
			}
		}
	}

	&-actions {
		margin-top: 34px;
		display: flex;
		justify-content: center;
	}
}

.field-label {
	position: absolute;
	left: 23px;
	top: 50%;
	transform: translateY(-50%);
	color: $default-jaguar2;
	transition: all .4s ease-in-out;
}

.login-field {
	margin-bottom: 30px;
}

.show-password-btn {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}