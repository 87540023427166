$form-input__font-size: $font-size__regular;
$form-label__font-size: $font-size__regular;
$form-input__height: 40px;
$form-input__padding: .5rem 1.2rem .5rem 1rem;

form {
	position: relative;		//for datepicker 'container' option
}

.form {
	&__wrapper {
		@include flex(
				$align: flex-start,
				$wrap: wrap
		);
		padding: 0 3vw;
	}

	&__row {
		@include flex(
				$align: flex-start,
				$justify: flex-start
		);
		padding-bottom: 2.2rem;
		width: 100%;

		&_low {
			@extend .form__row;
			padding-bottom: 0;
		}

		.form__col {
			.form__row:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__col {
		width: 100%;
		position: relative;

		&-2 {
			width: 50%;
			position: relative;
		}

		&-3 {
			width: 33.33%;
			position: relative;
		}

		&_many {
			@include flex(
					$align: stretch
			);
			align-self: stretch;
		}

		&_xxlarge {
			width: 66.67%;
			position: relative;
		}

		&_xlarge {
			width: 60%;
			position: relative;
		}

		&_large {
			width: 50%;
			position: relative;

			&[data-container='addition'] {
				padding-left: 1.5vw;
			}
		}

		&_medium {
			width: 45%;
			position: relative;
		}

		&_normal {
			width: 35%;
			position: relative;
		}

		&_small {
			width: 30%;
			position: relative;
		}

		&_xsmall {
			width: 25%;
			position: relative;
		}

		&_xxsmall {
			width: 20%;
			position: relative;
		}

		&_left {
			padding-right: 3vw;

			&-small {
				padding-right: 1.5vw;
			}
		}

		&_right {
			padding-left: 3vw;

			&-small {
				padding-left: 1.5vw;
			}
		}

		&_center {
			padding-left: 1.5vw;
			padding-right: 1.5vw;
		}

		&_high {
			min-height: 255px;
		}

		&_grow {
			flex-grow: 1;
		}

		&_vertical {
			@include flex(
					$wrap: wrap
			);
		}

		&_align {
			@include flex(
					$direction: column,
					$justify: flex-start,
					$align: flex-start
			);

			&-top {
				.form__col_align,
				.form__col_align-bottom {
					justify-content: flex-start;
				}

				.form__col_align-bottom {
					margin-top: 2.5rem;
				}
			}

			&-bottom {
				@include flex(
						$direction: column,
						$align: flex-start,
						$justify: flex-end
				);
			}
		}

		&-error {
			.form__message {
				position: relative;
				transform: unset;
			}
		}

		&_date {
			.input-group.date {
				.input-group-addon {
					height: $form-input__height;
					position: absolute;
					pointer-events: none;
					top: 50%;
					right: 0;
					transform: translate(-50%, -50%);
					display: flex;
					align-items: center;
				}
			}

			.form__date {
				padding-left: 15px;
				padding-right: 35px;

				&:focus {
					& + .input-group-addon {
						z-index: 3;
					}
				}
			}
		}

		&_new {
			margin-bottom: 2.2rem;
			position: relative;
			width: 100%;
		}
	}

	&__set {
		background-color: $default-background-page;
		padding: 10px 0 0;
		width: 100%;

		&_hidden {
			display: none;
			padding: 1.2rem 0 0;
		}
	}

	&__subtitle {
		@include subtitle;
		font-size: clamp(1.6rem, 1.2675rem + 0.3247vw, 1.8rem);
		margin-bottom: 15px;
		font-weight: $font-weight__bold;
	}

	&__link-box {
		align-self: flex-end;
		font-weight: $font-weight__bold;
		margin-bottom: .4rem;
		padding-bottom: .4rem;
		padding-top: .4rem;
	}

	&__input,
	&__date {
		@include defaultInput;
		font-size: $form-input__font-size;
		height: $form-input__height;
		padding: $form-input__padding;
		width: 100%;
	}

	&__input {
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $default-manatee;
			opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: $default-manatee;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: $default-manatee;
		}

		&_short {
			flex-grow: 1;
			width: auto;
		}

		&-annex {
			box-sizing: border-box;
			padding-left: .5rem;
			text-align: right;
			width: 4rem;
		}
	}

	&__btn {
		border-radius: 30px;
		min-width: 135px;
		padding: .9rem 2rem;

		&:active:not(.btn_disabled) {
			transform: scale(.92);
		}
	}

	&__error-field {
		border-color: $default-torch-red !important;
	}

	&__label {
		font-size: $form-label__font-size;
		line-height: 2rem;
		margin-bottom: 5px;

		&_required {
			position: relative;
			margin-left: 1.4rem;

			&:before {
				content: '*';
				font-size: $font-size__light;
				position: absolute;
				top: 0;
				left: -11px;
				color: $default-torch-red;
			}
		}

		&_large {
			width: 100%;
		}

		&_vertical {
			margin-bottom: 0;
			margin-left: 20px;
			margin-right: auto;
		}

		&_disable {
			color: $default-comet;
		}
	}

	&__area {
		@include defaultInput;
		box-sizing: border-box;
		padding: .8rem 1rem;
		resize: none;
		min-height: 160px;
		overflow-y: auto;
		width: 100%;
	}

	&__message {
		font-size: $font-size__light;
		line-height: normal;
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translateY(100%);
		width: 100%;

		&_short {
			padding-right: 4rem;
		}

		.text-danger {
			color: $default-torch-red !important;

			&:not(:empty) {
				display: inline-block;
				padding-top: .6rem;
			}
		}
	}

	&__notice {
		color: $default-comet;
		font-style: italic;
		margin-left: 1.4rem;
	}

	&__value {
		font-weight: $font-weight__bold;
		margin-left: 1.4rem;
	}

	&__input:not([readonly]),
	&__date,
	&__area {
		&:hover {
			border-color: $default-manatee;
		}
	}

	&__input:not([readonly]),
	&__area {
		&:focus {
			outline: none;
			border-color: transparent;
			box-shadow: $default-shadow;
		}
	}

	&__input[readonly] {
		&:hover,
		&:focus {
			cursor: default;
		}
	}

	&__date:focus {
		outline: none;
		border: none;
		box-shadow: $default-shadow;
	}

	&__select {
		&.bootstrap-select {
			border-radius: $form-input__border-radius;
			height: $form-input__height;
			width: 100%;

			&.form__select-multiple {
				height: auto;
			}
		}

		& > .dropdown-toggle {
			height: 100%;
			border-radius: $form-input__border-radius;
			font-size: $form-input__font-size;
			padding-right: 4rem;
			padding-left: 1rem;
		}

		&.form__number > .dropdown-toggle {
			font-weight: 400;
		}

		.dropdown-toggle {
			&:after {
				top: 50%;
				left: 100%;
				transform: translate(-30px, -50%);
				background-image: url("../images/icons/arrow-down.svg");
			}

			&.btn-light:focus {
				outline: none !important;
			}

			.filter-option-inner {
				@include flex(
						$justify: flex-start
				);
				height: 100%;
			}
		}

		&.show {
			.dropdown-toggle {
				&:after {
					background-image: url("../images/icons/arrow-up.svg");
				}

				&:focus {
					outline: none !important;
				}
			}

			& > .btn-light.dropdown-toggle {
				background-color: $default-white;
				border-color: $default-gainsboro;
				border-bottom-color: transparent;
				border-radius: 6px 6px 0 0;
				z-index: 9;

				&:not(.disable-element) {
					&:focus {
						border-color: transparent;
						box-shadow: $default-shadow;
					}
				}
			}
		}

		& > .dropdown-toggle.bs-placeholder {
			&:active,
			&:hover {
				color: $default-manatee;
				border-color: $default-manatee;
			}

			&:not(.disable-element):focus {
				outline: none;
				border-color: transparent;
				box-shadow: $default-shadow;
			}
		}

		& > .btn-light {
			background-color: $default-white;
			border: 1px solid $default-athens-gray;
			color: $default-color-text;

			&:hover,
			&:active {
				background-color: $default-white !important;
				border-color: $default-manatee;
			}
		}

		.dropdown-menu {
			font-size: $form-input__font-size;
			margin-top: 0;
			padding-top: 0;
			border-radius: 0 0 6px 6px;
			top: -1px !important;
			width: 100%;
			outline: none;
			border: none;
			box-shadow: $default-shadow;
			clip-path: inset(0 -4px -4px -4px);

			.inner.show {
				overflow-x: hidden;

				&::-webkit-scrollbar {
					width: 5px;
					height: 5px;
				}

				&::-webkit-scrollbar-track {
					background-color: $default-athens-gray;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $default-cornflower-blue;
				}
			}

			.dropdown-divider {
				position: absolute;
				top: -5px;
				left: 12px;
				border-top: 1px solid $default-athens-gray;
				box-shadow: $default-shadow-block;
				width: calc(100% - 24px);
				z-index: 1;
			}

			.dropdown-header {
				font-size: 1.6rem;
				font-weight: bold;
				color: #02031B;
			}
		}

		& > .dropdown-menu {
			transform: translate3d(0px, $form-input__height, 0px) !important;
			z-index: 9;
		}

		&.select__search {
			& > .dropdown-menu {
				transform: translate3d(0px, 3px, 0px) !important;
			}
		}

		.dropdown-divider {
			top: 44px;
		}

		.dropdown-item {
			color: $default-color-text;
			padding: .8rem 2.2rem;

			&.active {
				background-color: $default-alabaster;
				color: $default-cornflower-blue;
				font-weight: 700;

				&.selected {
					background-color: $default-white-smoke;
				}
			}

			&:focus,
			&:hover,
			&.active:hover {
				background-color: $default-alabaster;
			}
		}

		&-multiple {
			.dropdown-toggle {
				display: none;
			}

			.dropdown-item {
				padding: 6px 20px 6px 60px;
				position: relative;

				&:before {
					position: absolute;
					content: '';
					left: 22px;
					display: inline-block;
					width: 22px;
					height: 22px;
					background: $default-white;
					border: 1px solid $default-athens-gray;
					box-sizing: border-box;
					border-radius: 3px;
				}
			}

			& > .dropdown-menu {
				li {
					padding: 6px 0;
				}

				.no-results {
					padding-left: 16px;
				}
			}

			.bs-searchbox {
				position: relative;
				padding: 7px 0 7px 8px;
				margin-bottom: 10px;

				&:before {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(-3px, -50%);
					content: '';
					width: 25px;
					height: 25px;
					display: inline-block;
					background-image: url("../images/icons/search-select.svg");
					background-repeat: no-repeat;
					background-size: 100%;
					background-position: center center;
				}

				&.focuses:before {
					background-image: url("../images/icons/search-select-action.svg");
				}
			}
		}

		&.form__select-multiple {
			& > .dropdown-menu {
				display: block !important;
				box-sizing: content-box;
				position: relative !important;
				padding: 0 12px 10px 0;
				border: 1px solid $default-gainsboro;
				border-radius: $form-input__border-radius;
				top: -3px !important;
				width: calc(100% - 14px);
				min-width: calc(100% - 14px);
				min-height: 252px;
				z-index: 8;
				transition: $default-transition;
				box-shadow: none;
				clip-path: none;

				&:hover {
					border-color: $default-manatee;
				}

				&:focus-within {
					outline: none;
					box-shadow: $default-shadow;
				}

				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					top: 48px;
					left: 0;
					background-color: $default-athens-gray;
				}
			}
		}
	}

	&__check {
		display: none;

		&:checked + input[type='hidden'] + .label__check {
			&:before {
				background-color: $default-cornflower-blue;
				border-color: $default-cornflower-blue;
			}

			&:after {
				@include pseudo(
						$height: 12px,
						$translateX: 0,
						$width: 12px
				);
				background-image: url("../images/icons/check-white.svg");
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
				left: 5px;
			}
		}

		&[data-state='undefined'] {
			& + input[type='hidden'] + .label__check.label__check_all {
				&:before {
					background-color: $default-cornflower-blue;
					background-clip: content-box;
					padding: 2px;
				}

				&:after {
					content: '';
					position: absolute;
					background-image: none;
					background-color: $default-white;
					left: 5px;
					top: 50%;
					transform: translate(0, -50%);
					width: 12px;
					height: 3px;
					border-radius: 2px;
					display: inline-block;
				}
			}
		}
	}

	&__radio {
		display: none;

		&:checked + .label__radio {
			&:before {
				background-color: transparent;
			}

			&:after {
				@include pseudo(
						$background: $default-cornflower-blue,
						$height: 16px,
						$translateX: 0,
						$width: 16px
				);
				border-radius: 50%;
				left: 3px;
			}
		}
	}

	&__tag-box {
		@include flex();
	}

	&__tag {
		&.customer__tag {
			margin: 0;
		}

		&.selected-js {
			color: $default-cornflower-blue;
			border: 2px solid $default-cornflower-blue;
		}

		&:hover,
		&:focus-within {
			.tag__remove {
				display: inline-block;
			}
		}
	}

	&__btn-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 20px 0;

		.btn_large:last-child {
			margin-right: 0;
		}
	}
}

.col__pr {
	padding-right: 2.5rem;
}

.form__select .dropdown-toggle,
.select__search .bs-searchbox {
	&:after {
		position: absolute;
		content: '';
		width: 16px;
		height: 10px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto;
		border: none;
		margin: 0;
		padding: 0;
	}
}

.easy-autocomplete {
	width: 100% !important;

	&-container {
		z-index: 4;

		li.__hide {
			display: none !important;
		}
	}

	.input__search {
		@include defaultInput;
		color: $default-color-text;
		padding: $form-input__padding;

		&.input_active-js {
			border-radius: 6px 6px 0 0;
			border: none;
		}
	}

	.easy-autocomplete-container {
		ul {
			border: none;
			position: relative;
			border-radius: 0 0 6px 6px;
			outline: none;
			box-shadow: $default-shadow;
			clip-path: inset(2px -4px -4px -4px);
			top: -2px;

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: calc(100% - 24px);
				height: 1px;
				background-color: $default-athens-gray;
				top: 1px;
				left: 12px;
			}

			li {
				font-size: 1.6rem;
				border: none;
				padding: 8px 12px 8px 20px;

				&:last-child {
					border-radius: 0 0 6px 6px;
				}

				&.selected {
					background-color: $default-alabaster;
				}

				b {
					color: $default-cornflower-blue;
					font-weight: normal;
				}
			}
		}
	}
}

.remove-fields__btn {
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateX(.6rem);
}

.label {
	&__check,
	&__radio {
		cursor: pointer;
		margin-bottom: 0;
		padding-left: 3.5rem;
		position: relative;

		&:before {
			@include pseudo(
					$background: $default-white,
					$height: 22px,
					$translateX: 0,
					$width: 22px
			);
			display: inline-block;
			border: 1px solid $default-gainsboro;
			left: 0;
			transition: $default-transition;
		}

		&.form__label_disable {
			cursor: default;

			&:before {
				background-color: $default-white-smoke;
			}
		}

		&:not(.form__label_disable) {
			&:hover:before {
				border-color: $default-manatee;
			}

			&:focus:before {
				outline: none;
				border-color: transparent;
				box-shadow: $default-shadow;
			}
		}
	}

	&__check {
		&_all {
			color: $default-cornflower-blue;
			font-weight: $font-weight__bold;
		}

		&:before {
			border-radius: 4px;
		}
	}

	&__radio {
		margin-top: 1.5rem;

		&:before {
			border-radius: 50%;
		}
	}
}

.select-multiple {
	&__item {
		padding-top: 8px;
		padding-bottom: 8px;

		&:hover,
		&:focus-within {
			& > .item__btn-remove {
				display: inline-block;
			}
		}

		&-box {
			@include flex();
		}
	}

	&__controls {
		@include flex(
				$justify: flex-start
		);
		flex-wrap: wrap;
		gap: 1rem 1.5rem;
		margin-top: 25px;
	}
}

.item__title {
	white-space: nowrap;
}

.form__tag,
.new-tag__input,
.select-multiple__item {
	padding: 11px 20px;
	margin: 0;
	background-color: $default-white;
	border: 1px solid $default-gainsboro;
	box-sizing: border-box;
	box-shadow: $default-shadow-block;
	border-radius: 32px;
	color: $default-comet;
	line-height: 1.8rem;
	cursor: pointer;
	transition: $default-transition;
}

.form__tag,
.select-multiple__item {
	@include flex(
			$align: stretch
	);

	&:hover {
		border-color: $default-cornflower-blue;
		color: $default-cornflower-blue;
	}

	&:focus-within {
		box-shadow: $default-shadow;
		outline: none;
	}
}

.customer__tag.selected-js {
	& > .tag__remove {
		&:hover {
			background-image: url("../images/icons/close-hover-blue.svg");
		}
	}
}

.tag__remove,
.item__btn-remove {
	position: relative;
	width: 16px;
	height: 16px;
	margin-left: 15px;
	border: none;
	background-image: url("../images/icons/close.svg");
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto;
	transition: $default-transition;

	&:hover {
		background-image: url("../images/icons/close-hover-blue.svg");
	}

	&:focus {
		background-image: url("../images/icons/close-focus.svg");
		transform: scale(1.4);
	}

	&:active {
		transform: scale(.9);
	}
}

.new-tag {
	&__wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__input {
		width: 110px;

		&:focus {
			box-shadow: $default-shadow;
			outline: none;
		}
	}
}

.select__search {
	.bs-searchbox {
		& > input {
			font-size: $form-input__font-size;
			padding-left: 14px;
			padding-right: 30px;
			border: none;
			overflow: hidden;

			&:focus {
				box-shadow: none;
			}
		}
	}
}

.bootstrap-select {
	&.form__select-multiple {
		.bs-ok-default:after {
			content: none;
		}
	}

	&.show-tick.form__select-multiple {
		.dropdown-menu {
			li.selected {
				background-color: $default-white-smoke;

				.dropdown-item {
					&:hover,
					&:focus {
						background-color: $default-white-smoke;
					}
				}
			}
			.selected {
				span.check-mark {
					left: 27px;
					top: 12px;
					right: auto !important;
					background-image: url("../images/icons/check-white.svg");
					background-repeat: no-repeat;
					background-size: 100%;
					background-position: center;
					width: 12px;
					height: 11px;
				}

				&:before {
					background-color: $default-cornflower-blue;
					border-color: $default-cornflower-blue;
				}
			}
		}
	}
}

.datepicker {
	&.dropdown-menu {
		font-size: 1.6rem;
		color: $default-jaguar;
		z-index: auto !important;
	}

	&.datepicker-orient-bottom {
		margin-top: 6px;
	}

	&.datepicker-orient-top {
		transform: translateY(-6px);
	}

	tr,
	td,
	th {
		vertical-align: middle;
	}

	.datepicker-months {
		width: 300px;

		table {
			width: 100%;
		}

		.month {
			width: 31%;
		}
	}

	table {
		tr {
			td,
			th {
				width: 35px;
				height: 35px;
			}

			td {
				&.active.active,
				&.active.active:hover,
				span.active.active,
				span.active.active:hover,
				span.active:hover.active:hover {
					background-color: $default-cornflower-blue;
					border-color: $default-cornflower-blue;
				}
			}
		}
	}
}

.input-group {
	&:not(.has-validation) {
		& > .form-control:not(:last-child) {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}

	&.date:hover > .form__date {
		border-color: $default-manatee;
	}
}

[readonly],
.disable-element {
	pointer-events: none;
	background-color: $default-white-smoke !important;
	color: $default-comet;
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.form {
		&__wrapper {
			padding: 0 2vw;
		}

		&__set {
			padding: 12px 0;
		}

		&__select {
			&.form__select-multiple {
				& > .dropdown-menu {
					min-height: 236px;
				}
			}
		}

		&__col_many {
			&.form__col_mobile {
				flex-wrap: wrap;
			}
		}

		&__col_xlarge,
		&__col_large,
		&__col_normal,
		&__col_small,
		&__col_xsmall {
			width: 45%;
		}

		&__col_medium {
			width: 100%;
		}

		&__col_mobile {
			.form__col_xsmall,
			.form__col_small,
			.form__col_medium,
			.form__col_large,
			.form__col_xlarge {
				padding: 0;
				width: 100%;
			}

			.form__col_xxsmall {
				width: 45%;
			}

			.form__col_align,
			.form__col_align-bottom {
				&:not(:first-child) {
					margin-top: 2.5rem;
				}
			}

			&.form__col-2 {
				.remove-fields__btn {
					left: 100% !important;
					right: unset !important;
				}
			}

			.form__col_xxsmall {
				& + .form__col_xxsmall {
					margin-top: 0;
				}
			}
		}

		&__col_new {
			border: 1px solid $default-manatee;
			padding: 3rem 1rem 2.4rem;
			border-radius: 8px;
			margin: 1.5rem 0;

			.remove-fields__btn {
				top: 5px;
				transform: translateX(-3rem);
			}
		}

		&__col_grow {
			width: 100%;

			&.col__pr {
				padding-right: 0;
			}
		}

		&__btn-wrapper {
			justify-content: center;

			.btn_large:last-child {
				margin-right: 16px;
			}
		}
	}
}

@media screen and (max-width: #{$screen__l - 1px}) {
	.form__row {
		flex-wrap: wrap;
		padding-bottom: 0;

		.form__col-2:first-child:not(:last-child):not(:empty) {
			margin-bottom: 2.5rem;
		}

		.form__col {
			&:not(:empty) {
				margin-bottom: 1.5rem;
			}
		}

		.form__col-3,
		.form__col-2,
		.form__col_xxlarge {
			&:not(:empty) {
				margin-bottom: 2.5rem;
			}
		}
	}

	.form__col {
		&-2,
		&-3,
		&_xxlarge,
		&_large[data-container='addition'] {
			width: 100%;
			padding: 0;
		}

		&_new {
			.form__col-2:not(:first-child) {
				margin-top: 2.2rem;
			}
		}
	}
}

@media screen and (min-width: $screen__l) and (max-width: $screen__xl) {
	.form__subtitle {
		& + .form__row_tablet {
			padding-bottom: 0;
		}
	}
	.form__row {
		&_tablet {
			flex-wrap: wrap;

			.form__col {
				&-2 {
					width: 100%;
				}

				&_left-small,
				&_right-small {
					&:not(:empty) {
						padding: 2.5rem 0 0;
					}
				}
			}
		}
	}
}

/*------Desktop media queries------*/

@media screen and (min-width: $screen__l) and (max-width: 1180px) {
	.form__col_date {
		.form__date {
			padding-left: 10px;
			padding-right: 27px;
		}

		.input-group.date {
			.input-group-addon {
				width: 20px;
				right: -4px;

				img {
					width: 100%;
				}
			}
		}
	}

	.com-prop__form {
		.form__col_medium {
			width: 55%;
		}

		.form__col_small {
			width: 33%;
		}
	}
}