.unit-card {
	display: flex;
	justify-content: flex-start;

	&_row {
		align-items: center;
		flex-direction: row;
		gap: 1rem;
		padding-right: 1.5rem;
	}

	&_col {
		align-items: flex-start;
		flex-direction: column;
		flex-wrap: wrap;
	}

	&__name {
		font-size: 2.2rem;
		font-weight: $font-weight__most-bold;
		margin-bottom: 0;
	}

	&__id {
		font-size: 1.6rem;
	}

	&__info-block {
		@include flex(
				$justify: flex-start,
				$wrap: wrap
		);
		padding: 1rem 0;
	}

	&__picto {
		&:before {
			background-image: url("../images/icons/icons-sprite.svg");
			background-repeat: no-repeat;
			background-size: auto;
			border: none;
			content: "";
			display: inline-block;
			height: 22px;
			margin-right: 1rem;
			width: 22px;
		}

		&-title {
			line-height: 22px;
		}
	}

	&__text {
		&_capital {
			font-weight: $font-weight__bold;
		}

		&_minor {
			color: $default-comet;
			font-size: $font-size__light;
			margin: 0;
			padding: .5rem 0;
		}

		&_row {
			width: 100%;
		}
	}

	&__logo {
		&-block {
			margin-bottom: 4rem;
			position: relative;
			width: 80%;
		}

		&-edit {
			@include absPosition(
					$top: 100%,
					$translateX: 50%
			);
			margin: 0;
			right: 0;

			&:active:not(.btn_disabled) {
				transform: translate(50%, -50%) scale(.8);
			}
		}

		&_small {
			height: 4rem;
			width: auto;
		}

		&_large {
			height: auto;
			width: 100%;
		}
	}
}

.picto {
	&-bussiness {
		&:before {
			background-position: -14px -14px;
		}
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.unit-card {
		&_row {
			flex-wrap: wrap;
		}

		&__logo-block {
			margin: 0 auto 4rem;
			max-width: 250px;
		}
	}
}
