.edit-mode-js {
	.addition {
		&__form {
			width: 100%;
		}
	}

	.creating-elem-js {
		display: none;
		pointer-events: none;
	}
}

.create-mode-js {
	.editing-elem-js {
		display: none;
		pointer-events: none;
	}
}

.addition {
	&__wrapper {
		border: 1px solid $default-athens-gray;
		border-radius: 12px;
		height: 0;
		opacity: 0;
		padding: 0;
		overflow: hidden;
		visibility: hidden;

		&.create-mode-js,
		&.edit-mode-js {
			overflow: visible;
			visibility: visible;
		}
	}

	&__form {
		margin-left: auto;
		padding-left: 1rem;
	}

	&__comment {
		margin-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		overflow: hidden;
	}

	&__btn-container {
		@include flex(
				$justify: flex-end,
				$align: flex-end
		);
	}

	&__files-list {
		margin: 0;
		padding-right: 2rem;

		li {
			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__block_bottom {
		padding-top: 3rem;
		justify-content: flex-end;
	}
}

/*------Mobile media queries------*/
@media screen and (max-width: #{$screen__l - 1px}) {
	.addition {
		&__files-list,
		&__btn-container {
			width: 100%;
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.addition {
		&__form {
			margin-left: 0;
			margin-top: 1rem;
			padding: 0 .8rem;
			width: 100%;
		}

		&__adv-list {
			width: 100%;
		}

		&__files-list {
			padding-right: 0;
		}

		&__btn-container {
			justify-content: space-between;
			margin-top: 1rem;
		}
	}
}