$expenses-title-coll__width: 22rem;
$expenses-total-coll__width: 10rem;
$expenses-input__height: 32px;
$expenses-row-block__padding-size: 2rem;
$expenses-month-block__min-width: 1100px;

.expenses {
	&__wrapper {
		overflow-x: auto;
	}

	&__header {
		font-weight: $font-weight__most-bold;
		color: $default-jaguar;
		margin-top: 3rem;
		min-width: 100%;
		padding-bottom: .4rem;
		padding-right: $expenses-row-block__padding-size;
		text-transform: uppercase;
		width: fit-content;

		&__month {
			min-width: $expenses-month-block__min-width;
			width: calc(100% - $expenses-title-coll__width - $expenses-total-coll__width - $expenses-row-block__padding-size);
		}
	}

	&__block {
		background-color: $default-white;
		border-radius: 1rem;
		margin-bottom: 1.2rem;
		min-width: 100%;
		padding: 0 $expenses-row-block__padding-size;
		width: fit-content;
	}

	&__row {
		padding: .6rem 0;

		&.grid__row {
			align-items: center;
		}

		&-title {
			padding-right: 1rem;
			min-width: $expenses-title-coll__width;
			width: $expenses-title-coll__width;
		}

		&-total {
			align-self: center;
			padding-left: 1rem;
			text-align: right;
			min-width: $expenses-total-coll__width;
			width: $expenses-total-coll__width
		}

		&-child {
			border-top: 1px solid $default-gainsboro;
		}
	}

	&__col {
		&-total {
			display: inline-block;
			font-size: $font-size__regular;
			font-weight: $font-weight__bold;
			padding: .6rem 0;
			text-align: center !important;
			white-space: nowrap;
			width: 100%;

			&[data-total="col-total"] {
				color: $default-comet;
			}
		}
	}

	&__grand-total {
		@extend .expenses__block;
		background-color: $default-comet;
		color: $default-white;
		font-weight: $font-weight__bold;
	}

	&__year {
		&-block {
			align-self: center;
			font-size: 2.8rem;
			margin-right: $expenses-row-block__padding-size;
			min-width: $expenses-title-coll__width;
			width: $expenses-title-coll__width;
		}
	}

	&__current-year {
		margin-right: 3rem;
	}

	&__month {
		&-block {
			min-width: $expenses-month-block__min-width;
			width: calc(100% - $expenses-total-coll__width - $expenses-title-coll__width);
		}

		&-cell {
			@include flex(
				$direction: column
			);
			padding: 0 .5vw;
			text-align: center;
		}
	}

	&__input {
		@include defaultInput;
		font-size: $font-size__light;
		height: $expenses-input__height;
		padding: .8rem 1rem;
		text-align: right;
		width: 100%;
	}

	&__link-action {
		font-size: $font-size__light;
		font-weight: $font-weight__bold;
		text-decoration: underline;
		text-transform: none;
	}
}
