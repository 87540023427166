#tag-set {
	@include flex(
			$justify: flex-start
	);
	flex-wrap: wrap;
	gap: 1rem 2rem;
	padding: 0 0 .8rem;
}

.com-prop {
	&__form {
		.form__btn-wrapper {
			display: flex;
			background-color: $default-background-page;
			align-items: center;
			position: sticky;
			position: -webkit-sticky; /* Safari */
			bottom: 0;
			width: 100%;
			z-index: 8;

			&.isSticky {
				box-shadow: 0 0 0 3px $default-background-page;
				min-height: 82px;

				&:after {
					content: "";
					background: url(../images/icons/fast-forward.svg) no-repeat center;
					background-size: contain;
					position: absolute;
					right: -5vw;
					width: 30px;
					height: 30px;
					top: 50%;
					transform: translateY(-50%) rotate(90deg);
					animation: arrow-button 1s linear infinite;
				}
			}

			.link_vedecum {
				font-size: $font-size__regular;
				font-weight: $font-weight__bold;
			}

			.form__btn-active-block {
				display: flex;
				justify-content: flex-end;
				flex-grow: 1;
			}
		}
	}
}

.table__attachments {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 20px;

	thead {
		display: none;
	}

	&-description {
		width: 220px;
		max-width: 220px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-row {
		margin-bottom: 20px;

		td {
			white-space: nowrap;

			&:not(:last-child) {
				padding-right: 20px;
			}

			&:first-child {
				font-weight: $font-weight__bold;
				width: 420px;
				max-width: 420px;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			img {
				margin-right: 15px;
			}
		}
	}

	&-actions {
		vertical-align: bottom;
		border-left: 1px solid $default-athens-gray;
		padding-left: 20px;
	}

	&-button {
		font-size: 0;
		@include refresh;

		&:after {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background-position: center;
			background-size: cover;
		}

		&.download {
			padding: 1px 10px;
			display: inline-block;

			&:after {
				background-image: url("../images/icons/save.svg");
			}

			&:hover {
				&:after {
					background-image: url("../images/icons/save-focus.svg");
				}
			}
		}

		&.edit {
			padding: 1px 10px;

			&:after {
				background-image: url("../images/icons/edit-unfocus.svg");
			}

			&:hover {
				&:after {
					background-image: url("../images/icons/edit.svg");
				}
			}
		}

		&.remove {
			padding: 1px 10px;

			&:after {
				background-image: url("../images/icons/dalet.svg");
			}

			&:hover {
				&:after {
					background-image: url("../images/icons/delete.svg");
				}
			}
		}
	}

	&-section {
		margin-bottom: 30px;
	}
}

.__hide {
	height: 0;
	opacity: 0;
	overflow: hidden;
}

.attachment {
	&__form-wrapper {
		&.form__error {
			border-color: $default-torch-red;
		}

		&.edit-wrapper {
			margin-top: 15px;
			transition: all .4s;
		}

		&.__hide {
			height: 0;
			opacity: 0;
			overflow: hidden;
			padding: 0;
			margin: 0;
		}
	}

	&__account-info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&__user {
		display: flex;
		align-items: center;

		&-img {
			margin-right: 20px;
			max-width: 32px;
			max-height: 32px;
			border-radius: 50%;
		}
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-left: 50px;

		&-file {
			.file-name {
				max-width: 450px;
				@include hideOverflowText;
			}

			&:active,
			&:focus {
				&:before {
					box-shadow: $default-shadow;
				}
			}
		}

		&-actions {
			.btn_large {
				min-width: 140px;
				font-size: $font-size__regular;
			}
		}

		&.edit-attachment {
			justify-content: flex-end;
		}
	}
}

.comment__form-edit {
	@extend .attachment__form;
}

.table__scroll {
	overflow-x: auto;
}

.download,
.edit,
.remove {
	&:active,
	&:focus {
		box-shadow: $default-shadow;
	}
}

.adv__list {
	.form__select {
		width: 286px;
		height: 38px;

		& > .btn-light {
			color: $default-jaguar2;
		}
	}
}

.comment {
	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__item {
		padding: 15px 0;
		border-bottom: 1px solid $default-athens-gray;

		&-top-block {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
		}

		&-title {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: $font-weight__bold;
		}

		&-information {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-actions {
			display: flex;
			flex-direction: row-reverse;
			padding-left: 20px;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				width: 1px;
				height: 42px;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-color: $default-athens-gray;
			}
		}
	}

	&__user {
		&-img {
			margin-right: 20px;
			max-width: 32px;
			max-height: 32px;
			border-radius: 50%;
		}
	}

	&__assignee-to {
		color: $default-cornflower-blue;
		margin-left: 52px;
		margin-top: 5px;
		width: 100%;
	}

	&__attachments {
		&-button {
			@extend .table__attachments-button;
		}
	}
}

.button-action-list {
	@include refresh;
	display: flex;
	align-items: center;

	&:before {
		content: "";
		display: inline-block;
		width: 22px;
		height: 22px;
		background: url("../images/icons/dots.svg") no-repeat center;
		transition: all .5s;
		transform: rotate(180deg);
	}

	&.action-close {
		position: relative;

		&:after {
			content: "";
			width: 1px;
			height: 42px;
			background-color: $default-athens-gray;
			position: absolute;
			left: 0;
		}

		&:before {
			background: url("../images/icons/close.svg") no-repeat center;
			transform: rotate(0);
		}

		&:hover {
			&:before {
				background: url("../images/icons/close-focus.svg") no-repeat center;
			}
		}
	}
}

.grid__container-form {
	justify-content: flex-end;
}

.show__form-action-wrapper {
	margin-bottom: 20px;
}

.attachments {
	&-actions {
		display: flex;
		flex-wrap: nowrap;
		transition: width .3s ease-out, opacity .3s ease-out .1s;
	}
}

.disable-file {
	pointer-events: none;
	opacity: 0.5;
}

@keyframes arrow-button {
	0% {
		top: 50%;
	}
	50% {
		top: 40%;
	}
	100% {
		top: 50%;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: $screen__xs) {
	.com-prop__form {
		.form__btn-wrapper {
			flex-wrap: wrap;
			padding: 5px 0;

			.form__btn-active-block {
				justify-content: space-around;
				padding-bottom: 5px;
			}
		}
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.com-prop__form {
		.form__btn-wrapper {
			&.isSticky {
				padding-left: 4vw;
				padding-right: 4vw;

				.btn_large {
					margin: 0;
					min-width: 130px;
				}

				.btn_default {
					margin-right: 8px;
				}

				&:after {
					right: -3vw;
					width: 25px;
					height: 25px;
				}
			}

			.link_vedecum {
				width: 100%;
				margin: 1rem 0;
				padding-right: 1rem;
			}
		}
	}
}

@media screen and (max-width: #{$screen__l - 1px}) {
	.com-prop__form {
		.grid__col-8,
		.grid__col-4 {
			padding: 0;
			width: 100%;
		}
	}
}