//Colors
$default-black: #000000;
$default-jaguar: #02031B;
$default-jaguar2: #02031B99;
$default-black-marlin: #353549;
$default-gun-powder: #4E4F5F;
$default-storm-grey: #71727F;
$default-torch-red: #FF0544;
$default-tawny: #C84F00;
$default-persimmon: #E05900;
$default-safety-orange: #FF5F0F;
$default-harley-davidson-orange: #CF3200;
$default-persian-blue: #122ED7;
$default-free-speech-blue: #394ED2;
$default-cornflower-blue: #6375E9;
$default-royal-blue: #5064E5;
$default-indigo: #5364CE;
$default-dark-violet: #930DB8;
$default-christi: #6ABA03;
$default-sunglow: #FFC932;
$default-comet: #676876;
$default-dim-gray: #727272;
$default-jumbo: #7C7D85;
$default-manatee2: #868A94;
$default-manatee: #97989F;
$default-aluminium: #AAABB2;
$default-link-water: #CCCDD1;
$default-gainsboro: #E0E0E0;
$default-athens-gray: #E8E8EB;
$default-white-smoke: #F2F2F2;
$default-alabaster: #F8F8F8;
$default-white: #FFFFFF;

$default-scooter: #369EA6;
$default-spanish-yellow: #F4B41A;
$default-atomic-tangerine: #FF9666;
$default-java: #D41118;
$default-cabaret: #D25675;
$default-red-orange: #FF4800;
$default-pearl-aqua: #82D4BB;
$default-red: #FF0000;
$default-eucalyptus: #2BA65A;
$default-pelorous: #1EA0AE;
$default-mulberry: #D25698;
$default-gamboge: #E79F0D;
$default-fire-engine-red: #BB1B21;
$default-amaranth: #E93A47;
$default-disco: #862240;
$default-mountain-meadow: #26BC94;

// Shadows
$default-shadow: 0 0 0 1px hsla(232, 74%, 61%, 1.00),
                  0 0 0 3px hsla(215, 88%, 77%, 0.8);
$default-shadow-block: 0 4px 6px rgba(2, 3, 27, 0.02);
$default-shadow_active: 0 0 0 4px hsla(215, 88%, 77%, 0.8);

// Font size
$font-size__light: 1.2rem;
$font-size__regular: 1.4rem;
$font-size__medium: 1.6rem;
$font-size__heavy-medium: 2rem;
$font-size__big: 3.2rem;

// Font family
$font-roboto: 'Roboto', sans-serif;

// Font weight
$font-weight__regular: 400;
$font-weight__heavier: 500;
$font-weight__bold: 700;
$font-weight__most-bold: 900;

// Media width
$screen__xxs: 375px;
$screen__xs: 480px;
$screen__s: 640px;
$screen__m: 768px;
$screen__l: 1024px;
$screen__xl: 1440px;

//transition
$default-transition: all .3s ease-in-out;

//form
$form-input__border-radius: 6px;