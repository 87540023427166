$user-photo__width_large: 27%;
$user-photo__max-width_large: 8rem;

.user-account {
    display: flex;
    height: 100%;
    position: relative;

    &__photo {
        border-radius: 50%;
        height: $user-photo__size_small;
        margin: 0 auto;
        overflow: hidden;
        transition: all .3s ease-in-out;
        width: $user-photo__size_small;
    }

    &__control {
        cursor: pointer;
        padding: 2px;
    }

    &__notification {
        background-color: $default-torch-red;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: $font-weight__bold;
        color: $default-white;
        text-align: center;
        cursor: pointer;

        &:not(:empty) {
            line-height: 1.1rem;
            min-height: 16px;
            min-width: 16px;
        }
    }

    &__menu {
        background-color: $default-white;
        box-shadow: 0 4px 12px rgba(2, 3, 27, 0.12);
        margin: 0;
        min-width: 300px;
        padding: 2.5rem 3.1rem 1rem 1.5rem;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translate(1rem, 5px);
        z-index: 10;

        &-list {
            max-height: 75vh;
            overflow: hidden;
            overflow-y: auto;
        }

        &-section {
            margin-bottom: 0;
            padding-bottom: 1rem;
            position: relative;

            & + .user-account__menu-section {
                border-top: 1px solid $default-athens-gray;
            }

            &:last-child {
                margin: 0;
            }
        }

        &-item {
            padding: 4px;
            font-size: $font-size__regular;
        }

        &-link,
        &-title {
            border-radius: 8px;
            display: block;
            color: $default-jaguar;
            line-height: 1.6rem;
            padding: 9px 0 9px 16px;
            position: relative;
            transition: $default-transition;

            &:before {
                border-radius: 50%;
                height: 4px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translate(7px, -50%);
                width: 4px;
            }
        }

        &-title {
            color: $default-cornflower-blue;

            &:before {
                background-color: $default-cornflower-blue;
                content: '';
            }
        }

        &-link {
            &:hover {
                background-color: $default-alabaster;
                color: $default-jaguar;
                text-decoration: none;

                &:before {
                    background-color: $default-jaguar;
                    content: '';
                }
            }

            &:focus {
                border-radius: 6px;
                box-shadow: $default-shadow;
            }

            &:active {
                background-color: transparent;
                box-shadow: $default-shadow_active;
                color: $default-royal-blue;

                &:before {
                    background-color: $default-royal-blue;
                }
            }

            &__exit {
                margin-top: 1rem;
                padding-left: 2.5rem;

                &:before {
                    background-image: url("../images/icons/icons-sprite.svg");
                    background-color: transparent;
                    background-position: -305px -12px;
                    background-repeat: no-repeat;
                    border-radius: unset;
                    content: '';
                    height: 24px;
                    left: 0;
                    transform: translate(0 , -50%);
                    width: 24px;
                }

                &:hover,
                &:active {
                    &:before {
                        background-color: transparent;
                    }
                }
            }
        }

        &-close {
            @include absPosition(
                    $top: 0,
                    $translateY: 8px,
                    $translateX: -8px
            );
            right: 0;
        }
    }

    &__top-block {
        align-items: center;
    }

    &__photo_large {
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: 8rem;
        width: $user-photo__width_large;
    }

    &__user {
        flex-grow: 1;
    }

    &__full-name {
        color: $default-cornflower-blue;
        font-size: clamp(1.8rem, 1.4780rem + 0.3145vw, 2rem);
        font-weight: $font-weight__most-bold;
        text-transform: uppercase;
    }

    &__position {
        font-size: clamp(1.4rem, 1.0780rem + 0.3145vw, 1.6rem);
        font-weight: $font-weight__bold;
        color: $default-jaguar;
    }

    &__info-list {
        flex-direction: column;
        padding-left: max(#{$user-photo__width_large}, #{$user-photo__max-width_large});
    }

    &__bottom-block {
        padding-left: max(#{$user-photo__width_large}, #{$user-photo__max-width_large});
        width: auto;
    }
}

.photo {
    border-radius: 50%;
    height: auto;
    width: 100%;
}

.notification {
    &__all {
        bottom: 0;
        color: $default-white;
        cursor: pointer;
        font-size: 1rem;
        font-weight: $font-weight__bold;
        position: absolute;
        right: 10px;
        text-align: center;
        transform: translate(100%, -7px);

        &:not(:empty) {
            line-height: 1.1rem;
            min-height: 16px;
            min-width: 16px;
            padding: 3px;

            &:before {
                background-color: $default-torch-red;
                border-radius: 8px;
                box-shadow: 0 0 0 0 rgba(255, 5, 68, 1);
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transform: scale(1);
                width: 100%;
                z-index: -1;
                animation: pulse 3s infinite;
            }
        }
    }

    &__item {
        margin-left: 1rem;

        &:not(:empty) {
            padding: 3px 7px;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 5, 68, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 5, 68, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 5, 68, 0);
    }
}

.info-list {
    &__item {
        margin-bottom: .9rem;
        min-height: 2.2rem;
        padding-left: 3rem;
        position: relative;
        word-break: break-word;

        &:before {
            content: '';
            background-image: url("../images/icons/icons-sprite.svg");
            background-repeat: no-repeat;
            background-size: auto;
            height: 20px;
            left: 0;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            width: 20px;
        }

        &-phone-number::before {
            background-position: -52px -50px;
        }

        &-mobile-number::before {
            background-position: -164px -50px;
        }

        &-city::before {
            background-position: -89px -50px;
        }
    }
}

#tasks-toggle-btn {
    &:hover,
    &:focus {
        & + .notification__all:before {
            animation: none;
        }
    }
}

/*------Mobile media queries------*/

@media screen and (max-width: calc($screen__l - 1px)) {
    .user-account {
        &__photo_large {
            width: 20%;
        }

        &__info-list,
        &__bottom-block {
            padding-left: max(20%, #{$user-photo__max-width_large});
        }
    }
}

@media screen and (max-width: calc($screen__m - 1px)) {
    .user-account {
        &__menu {
            display: block;
            height: 100vh;
            left: 0;
            padding: 4.5rem 2.5rem 1rem;
            position: fixed;
            top: 0;
            transform: unset;

            &-list {
                max-height: calc(100vh - 5.5rem);
            }

            &-link,
            &-title {
                font-size: 1.6rem;
                padding-left: 2rem;
            }

            &-link__exit {
                padding-left: 3rem;
            }

            &-close {
                transform: translate(-2rem, 2rem);
            }
        }

        &__top-block {
            align-items: flex-start;
        }

        &__user {
            word-break: break-word;
        }

        &__photo_large {
            min-width: 5rem;
            padding-left: 0;
            padding-right: 0;
            width: 15%;
        }

        &__info-list,
        &__bottom-block {
            padding-left: max(15%, 5rem);
        }

        &__full-name {
            margin-bottom: .5rem;
        }
    }
}
