$prefixes: "webkit", "moz", "ms", "o";

@mixin prefix($declarations) {
	@each $property, $value in $declarations {
		@each $prefix in $prefixes {
			#{'-' + $prefix + '-' + $property}: $value;
		}
		#{$property}: $value;
	}
}

@mixin flex(
	$display: flex,
	$direction: row,
	$justify: space-between,
	$align: center,
	$wrap: nowrap
) {
	display: $display;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
	flex-wrap: $wrap;
}

@mixin pseudo(
	$background: transparent,
	$height: 1px,
	$top: 50%,
	$translateY: -50%,
	$translateX: -50%,
	$width: 1px
) {
	background-color: $background;
	content: '';
	height: $height;
	position: absolute;
	top: $top;
	transform: translate($translateX,$translateY);
	width: $width;
}

@mixin absPosition(
	$top: 50%,
	$translateY: -50%,
	$translateX: -50%
) {
	position: absolute;
	top: $top;
	transform: translate($translateX,$translateY);
}

@mixin circleBtn(
	$size: 40px,
	$margin: 10px,
	$scale: .8
) {
	@include flex(
			$display: inline-flex,
			$justify: center
	);
	border-radius: 50%;
	height: $size;
	margin: 0 $margin;
	width: $size;

	&:active:not(.btn_disabled) {
		transform: scale($scale);
	}
}

@mixin status(
	$background: $default-white,
	$border-color: $default-comet,
	$padding: .2rem 1.5rem,
	$radius: 2rem,
	$text-color: $default-white,
) {
	background-color: $background;
	border-radius: $radius;
	border: 1px solid $border-color;
	color: $text-color;
	font-weight: $font-weight__bold;
	line-height: normal;
	padding: $padding;
	text-transform: uppercase;
}