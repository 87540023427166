.page__header {
	border-top: 6px solid $default-cornflower-blue;
	box-shadow: $default-shadow-block;
	background-color: $default-white;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 11;
}

.header {
	&__wrapper {
		@include flex();
		position: relative;
		padding-bottom: 1rem;
		padding-top: 1rem;
	}

	&__left-item {
		padding-left: 1.6vw;
	}
}

.block__environment {
	background-color: $default-torch-red;
	text-align: center;

	.environment_description_text {
		color: $default-white;
		font-weight: $font-weight__bold;
		text-transform: uppercase;
		margin: 0;
	}
}

.menu__toggle {
	color: $default-jaguar;
	padding: 8px 6px;

	&:hover {
		color: $default-cornflower-blue;
	}
}

.logo {
	color: $default-cornflower-blue;
	font-size: 2rem;
	font-weight: $font-weight__bold;
	line-height: 2.6rem;
	margin-left: 1vw;
	margin-right: auto;

	&__image {
		max-width: 150px;
	}
}

.gescom-logo_small {
	@include absPosition();
	left: 50%;
	margin: 0 auto;
	max-width: 150px;
}

.gescom-logo,
.website-logo {
	width: 100%;
	height: auto;
}

.lang-switcher {
	@include flex(
			$direction: column
	);

	&__label {
		border-radius: 8px;
		color: $default-comet;
		cursor: pointer;
		font-size: $font-size__light;
		font-weight: $font-weight__bold;
		height: 2rem;
		line-height: 1.8rem;
		margin: 0;
		padding: 2px;
		text-align: center;
		transition: all .3s ease-in-out;
		width: 3rem;

		&:hover {
			color: $default-cornflower-blue;
		}

		&:focus {
			box-shadow: $default-shadow;
			outline: none;
		}

		&:not(:last-child) {
			margin: 0 0 .5rem 0;
		}
	}

	&__radio {
		display: none;

		&:checked + .lang-switcher__label {
			background-color: $default-cornflower-blue;
			background-clip: content-box;
			color: $default-white;

			&:hover {
				background-color: $default-indigo;
			}
		}
	}
}

.search {
	&-block {
		align-items: center;
		align-self: stretch;
		display: inline-flex;
		justify-content: flex-end;
		width: auto;

		&.mobile-only-js {
			background-color: $default-white;
			height: 100%;
			justify-content: center;
			left: 0;
			margin: 0;
			padding: 0 2rem;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;

			.search__form {
				justify-content: space-between;
				padding: 0 5px;
				width: 100%;
			}

			.search__input {
				margin: 0;
				padding: 0 4rem 0 1.5rem;
				width: 90%;
			}

			.search-icon__block {
				right: 12%;
			}

			.btn-search-open-js {
				display: none;
			}

			.btn-search-close-js {
				display: flex;
			}
		}
	}

	&__form {
		@include flex(
				$justify: flex-end
		);
		height: 100%;
		overflow: hidden;
		padding: 3px 0;
		position: relative;
		width: 0;
	}

	&__input {
		border: 1px solid $default-athens-gray;
		border-radius: 12px;
		height: 40px;
		margin-right: 5px;
		padding: 0 6rem 0 2.2rem;
		transition: all .3s ease-in-out;
		width: 95%;

		&:hover {
			border-color: #97989F;
		}

		&:focus-within {
			border-color: transparent;
			box-shadow: $default-shadow;
			outline: none;

			& ~ .search-icon__block {
				opacity: 0;
			}
		}
	}

	&-icon {
		color: $default-cornflower-blue;
		height: 25px;
		width: 25px;

		&__block {
			position: absolute;
			right: 25px;
			top: 50%;
			transform: translateY(-50%);
			transition: $default-transition;
			z-index: 1;
		}
	}
}

.btn-search {
	&-close-js {
		display: none;
	}

	&-open-js {
		margin-right: 1.5rem;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: calc($screen__m - 1px)) {
	#toggle-menu-btn {
		margin-right: .5rem;
	}

	.header {
		&__wrapper {
			flex-wrap: wrap;
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}

		&__left-item {
			padding-left: 1.5rem;
		}

		&__gescom-logo {
			left: unset;
			margin: 0 auto 0 0;
			max-width: 110px;
			top: unset;
			transform: unset;
			position: relative;
		}
	}

	.logo,
	.lang-switcher {
		display: none;
	}

	.search-block {
		.btn-search-open-js {
			margin-right: 0;
		}
	}
}
