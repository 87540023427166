$paginate-item__font-size: clamp(1.4rem, 0.9077rem + 0.4808vw, 1.6rem);
$paginate-btn__line-height: clamp(1.8rem, 1.0615rem + 0.7212vw, 2.1rem);
$paginate-btn__color: $default-cornflower-blue;
$paginate-btn__border: 1px solid $paginate-btn__color;
$paginate-btn_hover__color: $default-white;
$paginate-btn_hover__bg-color: $paginate-btn__color;
$paginate-btn_active__color: $paginate-btn_hover__color;
$paginate-btn_active__bg-color: $default-royal-blue;
$paginate-btn_disabled__color: $default-manatee;
$paginate-btn__border-radius: 20px;
$paginate-btn__padding: .5em .9em;

.paginate {
	&__wrapper {
		margin-left: auto;
	}

	&__pager-list {
		display: flex;
		justify-content: center;
		align-items: stretch;
		font-family: $font-roboto;
		font-weight: $font-weight__bold;
		font-size: $paginate-item__font-size;
		margin: 0;
	}

	&__pager-item:not(:last-child) {
		margin-right: 6px;
	}

	&__button {
		background-color: transparent;
		border: $paginate-btn__border;
		border-radius: $paginate-btn__border-radius;
		color: $paginate-btn__color;
		display: inline-block;
		line-height: $paginate-btn__line-height;
		padding: $paginate-btn__padding;
		text-align: center;
		text-decoration: none;
		transition: $default-transition;

		&:hover {
			background-color: $paginate-btn_hover__bg-color;
			color: $paginate-btn_hover__color;
			text-decoration: none;
		}

		&:focus {
			border-color: transparent;
			box-shadow: $default-shadow;
		}

		&:active {
			background-color: $paginate-btn_active__bg-color;
			color: $paginate-btn_active__color;
			box-shadow: $default-shadow_active;
			transform: scale(.85);
			transform-origin: center center;
		}

		&_current {
			color: $paginate-btn_active__color;
			background-color: $paginate-btn_hover__bg-color;
		}

		&_disabled {
			display: inline-block;
			color: $paginate-btn_disabled__color;
			line-height: 21px;
			padding: .5em .9em;
			height: 100%;
		}
	}
}