.document {
	&__label {
		border-radius: 1rem;
		display: inline-block;
		font-size: $font-size__light;
		line-height: normal;
		padding: .4rem .8rem;
	}

	&__type-label {
		background-color: $default-link-water;
		border: 1px solid $default-link-water;
		color: $default-gun-powder;
		text-align: center;
	}

	&__date-label {
		background-color: $default-white;
		border: 1px solid $default-gainsboro;
		color: $default-gun-powder;

		&:before {
			background-image: url("../images/icons/calendar.svg");
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
			display: inline-block;
			height: 14px;
			margin-bottom: -2px;
			margin-right: 5px;
			width: 14px;
		}
	}

	&__warning-label {
		background-color: $default-white;
		border: 1px solid $default-torch-red;
		color: $default-torch-red;
		text-align: center;
	}

	&__requirement {
		&-item {
			padding-bottom: 3rem;
		}
	}

	&__title {
		color: $default-jaguar;
		font-weight: $font-weight__bold;
	}

	&__description {
		font-size: $font-size__light;
		display: block;
	}
}