$btn-primary__color: $default-white;
$btn-primary__border-color: $default-cornflower-blue;
$btn-primary__bg-color: $btn-primary__border-color;
$btn-primary_hover__color: $default-white;
$btn-primary_hover__border-color: $default-free-speech-blue;
$btn-primary_hover__bg-color: $btn-primary_hover__border-color;
$btn-primary_active__color: $btn-primary_hover__color;
$btn-primary_active__border-color: $default-persian-blue;
$btn-primary_active__bg-color: $btn-primary_active__border-color;

$btn-secondary__color: $default-cornflower-blue;
$btn-secondary__border-color: $btn-secondary__color;
$btn-secondary_hover__color: $default-white;
$btn-secondary_hover__bg-color: $btn-secondary__color;
$btn-secondary_active__color: $btn-secondary_hover__color;
$btn-secondary_active__border-color: $default-free-speech-blue;
$btn-secondary_active__bg-color: $btn-secondary_active__border-color;

$btn-default__color: $default-dim-gray;
$btn-default__border-color: $btn-default__color;
$btn-default_hover__color: $default-white;
$btn-default_hover__bg-color: $btn-default__color;
$btn-default_active__color: $btn-default_hover__color;
$btn-default_active__border-color: $default-black-marlin;
$btn-default_active__bg-color: $btn-default_active__border-color;

$btn-gescom__line-height: 1.8rem;

.gescom-btn {
	border-width: 2px;
	border-style: solid;
	background-color: transparent;
	font-weight: $font-weight__bold;
	font-size: $default-btn__font-size;
	line-height: $btn-gescom__line-height;
	transition: $default-transition;

	&:focus:not(.btn_disabled) {
		outline: none;
		border-color: transparent;
		box-shadow: $default-shadow;
	}

	&:active:not(.btn_disabled) {
		transform-origin: center center;
		box-shadow: $default-shadow_active;
	}
}

.btn {
	&_default {
		color: $btn-default__color;
		border-color: $btn-default__border-color;

		&:hover:not(:focus):not(.btn_disabled) {
			color: $btn-default_hover__color;
			background-color: $btn-default_hover__bg-color;
		}

		&:active:not(.btn_disabled) {
			color: $btn-default_active__color;
			border-color: $btn-default_active__border-color;
			background-color: $btn-default_active__bg-color;
		}
	}

	&_primary {
		background-color: $btn-primary__bg-color;
		border-color: $btn-primary__border-color;
		color: $btn-primary__color;

		&:hover:not(:focus):not(.btn_disabled) {
			background-color: $btn-primary_hover__bg-color;
			border-color: $btn-primary_hover__border-color;
			color: $btn-primary_hover__color;
		}

		&:active:not(.btn_disabled) {
			background-color: $btn-primary_active__bg-color;
			border-color: $btn-primary_active__border-color;
			color: $btn-primary_active__color;
		}
	}

	&_secondary {
		border-color: $btn-secondary__color;
		color: $btn-secondary__border-color;

		&:hover:not(:focus):not(.btn_disabled) {
			background-color: $btn-secondary_hover__bg-color;
			color: $btn-secondary_hover__color;
		}

		&:active:not(.btn_disabled) {
			background-color: $btn-default_active__bg-color;
			border-color: $btn-default_active__border-color;
			color: $btn-default_active__color;
		}
	}

	&_large {
		min-width: 135px;
		border-radius: 32px;
		padding: 1.4rem 3rem;

		&:active:not(.btn_disabled) {
			transform: scale(.92);
		}
	}

	&_small {
		@include circleBtn(
			$size: 40px
		)
	}

	&_mini {
		@include circleBtn(
			$size: 32px,
			$margin: 5px,
			$scale: .9
		)
	}

	&-combo {
		@include flex(
				$display: inline-flex
		);
		position: relative;

		&__icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&__text {
			padding-left: 3rem;
			white-space: nowrap;
		}
	}

	&-picto {
		@include flex(
				$display: inline-flex,
				$justify: center
		);
		background-color: transparent;
		border: none;
		border-radius: 6px;
		padding: 4px;
		transition: $default-transition;

		&:focus:not(.btn_disabled) {
			box-shadow: $default-shadow;
		}

		&:active:not(.btn_disabled) {
			transform-origin: center center;
			box-shadow: $default-shadow_active;
		}

		&_default {
			color: $default-manatee2;

			&:hover:not(:focus):not(.btn_disabled) {
				color: $default-comet;
			}

			&:active:not(.btn_disabled) {
				color: $default-black-marlin;
			}
		}

		&_primary {
			color: $default-cornflower-blue;

			&:hover:not(:focus):not(.btn_disabled) {
				color: $default-free-speech-blue;
			}

			&:active:not(.btn_disabled) {
				color: $default-persian-blue;
			}
		}

		&_secondary {
			color: $default-dim-gray;

			&:hover:not(:focus):not(.btn_disabled) {
				color: $default-cornflower-blue;
			}

			&:active:not(.btn_disabled) {
				color: $default-free-speech-blue;
			}
		}
	}

	&-anim {
		&:hover:not(:focus):not(.btn_disabled) > .icon {
			transform: scale(1.25);
		}
	}

	&-show-hide {
		display: none;
		width: 30px;
		height: 30px;
		transition: $default-transition;

		.icon-arrow {
			transform: rotate(-90deg);
		}

		&.slide-active-js {
			.icon-arrow {
				transform: rotate(90deg);
			}
		}
	}

	&_disabled {
		opacity: .5;
		pointer-events: none;
	}
}

.icon {
	transition: transform .15s linear;

	&-add {
		height: 16px;
		width: 16px;

		&-circle {
			height: 20px;
			width: 21px;
		}
	}

	&-close,
	&-arrow {
		height: 14px;
		width: 14px;
	}

	&-close_large {
		height: 21px;
		width: 21px;
	}

	&-check {
		height: 12px;
		width: 14px;
	}

	&-search {
		height: 25px;
		width: 24px;
	}

	&-tasks {
		height: 24px;
		width: 24px;
	}

	&-add_large,
	&-delete,
	&-question,
	&-action,
	&-edit,
	&-download,
	&-copy,
	&-refresh,
	&-show,
	&-dots,
	&-view,
	&-favorite,
	&-ok-circle,
	&-document,
	&-archive,
	&-hide {
		height: 22px;
		width: 22px;
	}

	&-attach {
		height: 18px;
		width: 18px;
	}

	&-settings {
		height: 23px;
		width: 22px;
	}

	&-menu {
		height: 15px;
		width: 22px;
	}

	&-contact {
		height: 19px;
		width: 19px;
	}

	&-long-arrow {
		height: 16px;
		width: 18px;
	}
}

.check-picto {
	&[type=checkbox] {
		appearance: none;
		-webkit-appearance: none;
		cursor: pointer;
		padding: 4px;
		border-radius: 6px;
		transition: $default-transition;

		&:after {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			content: "";
			height: 22px;
			width: 22px;
		}

		&:focus {
			box-shadow: $default-shadow;
		}
	}

	&__label {
		@include flex(
				$justify: center
		);
		margin-bottom: 0;
	}
}

.add_to_favorite__label {
	.check-picto {
		&:after {
			background-image: url("../images/icons/favorite.svg");
		}

		&:checked:after {
			background-image: url("../images/icons/favorite-active.svg");
		}
	}

	&:hover {
		.check-picto:not(:checked) {
			&:after {
				background-image: url("../images/icons/favorite-hover.svg");
			}
		}
	}
}

.subscribe__label {
	.check-picto {
		&:after {
			background-image: url("../images/icons/notification.svg");
		}

		&:checked:after {
			background-image: url("../images/icons/notification-active.svg");
		}
	}

	&:hover {
		.check-picto:not(:checked) {
			&:after {
				background-image: url("../images/icons/notification-hover.svg");
			}
		}
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: $screen__s) {
	.btn-show-hide {
		display: flex;
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.btn {
		&_large {
			padding: 1rem 2rem;
		}
	}

	.actions {
		&__btn {
			border-radius: 50%;
			height: $btn-show-actions__size;
			width: $btn-show-actions__size;
			z-index: 2;
			@include flex(
					$justify: center
			);

			&.active-js {
				background-color: $default-cornflower-blue;
				color: $default-white;
			}
		}
	}
}