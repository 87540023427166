.recipient-selector {
	padding: 1.5rem 0 2rem;
	position: relative;

	.easy-autocomplete {
		width: auto !important;
	}

	&__container {
		align-items: center;
		border: 1px solid $default-gainsboro;
		border-radius: 6px;
		box-shadow: $default-shadow-block;
		padding: 9px 20px;
	}

	&__user-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
	}

	&__list-item {
		align-items: center;
		border: 1px solid $default-cornflower-blue;
		border-radius: 20px;
		display: flex;
		margin: 5px 5px 5px 0;
		padding: 6px 8px;
	}

	&__user-name {
		color: $default-cornflower-blue;
		font-size: $font-size__light;
		padding-right: .8rem;
		white-space: nowrap;
	}

	&__select {
		flex-grow: 1;
		transition: $default-transition;

		&:not(:focus) {
			box-shadow: none !important; //rewrite plugin style
		}
	}

	&__counter {
		align-self: center;
		font-size: $font-size__regular;
		color: $default-jaguar2;
		overflow: hidden;
		padding: 1rem 0 1rem 1rem;
		text-align: right;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:before {
			content: "+";
		}
	}

	&__number {
		margin-right: 3px;
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.recipient-selector {
		&__counter {
			width: 50%;
		}

		.grid__col-8 {
			width: 100%;
			order: 1;
		}
	}
}