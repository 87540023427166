$card-item__padding_small: .5vw;
$card-item__padding_large: 1vw;
$panel-item__min-height: 380px;
$notice-icon__size: 33px;
$main-panel__gap_mobile: 20px;

.main-panel {
    &__container {
        margin: 2rem 0 3rem;
        @include flex(
                $align: stretch
        );
    }

    &__user-info {
        min-height: $panel-item__min-height;
        width: 28%;
    }

    &__activities {
        margin: 0;
        gap: 4% 0;
        min-height: $panel-item__min-height;
        width: 21%;
        @include flex(
                $align: stretch,
                $wrap: wrap
        );
    }

    &__days-counter {
        min-height: $panel-item__min-height;
        width: 15%;
    }

    &__notices {
        margin: 0;
        min-height: $panel-item__min-height;
        width: 30%;
        @include flex(
                $direction: column,
                $justify: flex-start,
                $align: flex-start
        );
    }

    &__card {
        background-color: $default-white;
        border-radius: 12px;
        box-shadow:  0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
        font-size: clamp(1.2rem, 0.8780rem + 0.3145vw, 1.4rem);
    }
}

.card {
    &-indent {
        &__large {
            padding: $card-item__padding_large;
        }

        &__small {
            padding: $card-item__padding_small;
        }
    }

    &-item {
        min-height: 30%;
        padding-bottom: 2.2rem;
        position: relative;
        width: 48%;
        @include flex(
                $direction: column,
                $justify: center
        );

        &_large {
            @extend .card-item;
            width: 100%;
        }

        &__link {
            right: 0;
            @include absPosition(
                    $top: 100%,
                    $translateY: calc(-100% - $card-item__padding_small),
                    $translateX: -$card-item__padding_small
            );
        }
    }

    &__link {
        font-weight: $font-weight__bold;
        text-align: center;
    }

    &__icon {
        background-image: url("../images/icons/icons-sprite.svg");
        background-repeat: no-repeat;
        background-size: auto;
        height: 32px;
        margin-bottom: .5rem;
        width: 36px;

        &-fees {
            background-position: 2px -74px;
        }

        &-missions {
            background-position: -112px -73px;
        }

        &-absences {
            background-position: -38px -73px;
        }

        &-skills {
            background-position: -74px -73px;
        }

        &-team {
            background-position: -154px -73px;
        }
    }
}

.days-counter {
    &__item {
        text-align: center;
        @include flex(
                $direction: column,
                $justify: center
        );

        &:nth-child(-n + 2) {
            border-bottom: 1px solid $default-gainsboro;
        }
    }

    &__title {
        color: $default-comet;
        font-size: $font-size__light;
        margin: 0;
    }

    &__value {
        color: $default-jaguar;
        font-weight: $font-weight__bold;
        margin: 0;
    }

    &__warning {
        color: $default-safety-orange;
    }
}

.notice-item {
    padding: 1.2rem 0 1rem 2rem;
    @include flex(
            $justify: flex-start
    );

    & + .notice-item {
        border-top: 1px solid $default-gainsboro;
    }

    &__icon {
        background-image: url("../images/icons/icons-sprite.svg");
        background-repeat: no-repeat;
        background-size: auto;
        height: $notice-icon__size;
        margin-right: 1rem;
        width: $notice-icon__size;
    }

    &__message {
        margin: 0;
        width: calc(100% - $notice-icon__size - 1rem);

        .cell__content_important {
            transition: $default-transition;
        }
    }

    &__link {
        color: $default-color-text;
        word-break: break-word;
    }

    &__information {
        .notice-item__icon {
            background-position: -226px -50px;
        }
    }

    &__alert-level-1 {
        .notice-item__icon {
            background-position: -264px -50px;
        }

        .notice-item__link {
            &:hover {
                &,
                * {
                    color: $link-primary__color !important;
                }
            }

            &:active {
                &,
                * {
                    color: $link-primary_hover__color !important;
                }
            }
        }
    }

    &__alert-level-2 {
        .notice-item__icon {
            background-position: -302px -50px;
        }
    }

    &__alert-level-3 {
        .notice-item__icon {
            background-position: -340px -50px;
        }
    }

    &__alert-level-2,
    &__alert-level-3 {
        .notice-item__link {
            &:hover {
                &,
                * {
                    color: $default-safety-orange !important;
                }
            }

            &:active {
                &,
                * {
                    color: $default-tawny !important;
                }
            }
        }
    }
}

/*------Mobile media queries------*/

@media screen and (max-width: calc($screen__l - 1px)) {
    .main-panel {
        &__container {
            flex-wrap: wrap;
            gap: $main-panel__gap_mobile;
        }

        &__user-info,
        &__notices {
            width: calc(60% - $main-panel__gap_mobile / 2);
        }

        &__activities,
        &__days-counter {
            width: calc(40% - $main-panel__gap_mobile / 2);
        }
    }

    .card {
        &__icon {
            height: 32px;
            width: 32px;

            &-fees {
                background-position: 0px -109px;
            }

            &-missions {
                background-position: -33px -109px;
            }

            &-absences {
                background-position: -66px -109px;
            }

            &-skills {
                background-position: -98px -109px;
            }

            &-team {
                background-position: -132px -109px;
            }
        }

        &-item {
            padding-bottom: $card-item__padding_small;

            &__link {
                top: 0;
                transform: translate(-0.5vw, 0.5vw);;
            }
        }
    }
}

@media screen and (max-width: calc($screen__m - 1px)) {
    .main-panel {
        &__user-info,
        &__notices,
        &__activities,
        &__days-counter {
            min-height: auto;
            width: 100%;
        }

        &__activities {
            gap: 10px;
        }

        &__card {
            font-size: $font-size__regular;
        }
    }

    .card {
        &-indent {
            &__large,
            &__small {
                padding: 2rem;
            }
        }

        &__icon {
            margin-bottom: 0;
            margin-right: 1rem;
        }

        &-item {
            flex-direction: row;
            height: auto;
            min-height: unset;
            justify-content: flex-start;
            width: 100%;

            &__link {
                top: 50%;
                transform: translate(-2rem, -50%);
            }
        }
    }

    .days-counter {
        &__item {
            &:nth-child(-n + 3) {
                padding: 0 .5rem;
                width: 33.3%;
            }

            &:nth-child(-n + 2) {
                border-bottom: none;
            }

            &:nth-child(2) {
                border-left: 1px solid $default-gainsboro;
                border-right: 1px solid $default-gainsboro;
            }

            &:last-child {
                margin-top: 2rem;
            }
        }
    }

    .contributed-link__value {
        line-height: 1.8rem;
    }

    .notice-item {
        padding-left: .8rem;
        padding-right: .8rem;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}