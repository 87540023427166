$params-col__width: 18%;
$date-col__width: 95px;
$author-col__width: 20%;
$file-item-col__width: 20%;
$document-flex__gap: 1vw;


.supplier {
	&-table__wrapper {
		width: 100%;
	}

	&__top-block {
		border-bottom: 1px solid $default-link-water;
	}

	&__status {
		&_pending {
			@include status(
				$background: $default-white,
				$border-color: $default-comet,
				$text-color: $default-comet
			);
		}

		&_validated {
			@include status(
				$background: $default-white,
				$border-color: $default-mountain-meadow,
				$text-color: $default-mountain-meadow
			);
		}

		&_active {
			@include status(
				$background: $default-mountain-meadow,
				$border-color: $default-mountain-meadow,
				$text-color: $default-white
			);
		}

		&_standby {
			@include status(
				$background: $default-white,
				$border-color: $default-safety-orange,
				$text-color: $default-safety-orange
			);
		}

		&_closed {
			@include status(
				$background: $default-safety-orange,
				$border-color: $default-safety-orange,
				$text-color: $default-white
			);
		}
	}

	&__section {
		background-color: $default-white;
		padding: 1vmin 1vw 3vmin;
	}

	&__doc {
		&-content {
			@include flex(
					$align: flex-start
			);
			flex-grow: 1;
			gap: $document-flex__gap;
		}

		&-param {
			width: $params-col__width;
		}

		&-file {
			width: $file-item-col__width;
		}

		&-comment {
			width: calc(100% - $params-col__width - $file-item-col__width - $author-col__width - $date-col__width - 4 * $document-flex__gap);
		}

		&-author {
			width: $author-col__width;
		}

		&-date {
			width: $date-col__width;
		}

		&-status {
			border-bottom: 1px solid $default-athens-gray;
			padding-bottom: 1rem;
		}

		&-row {
			align-items: flex-start;
			padding: .5rem 0;
		}

		&-description {
			padding-left: 1vw;
			width: calc(100% - $params-col__width);
		}

		&_old {
			color: $default-black;
			font-weight: $font-weight__bold;
			padding-top: 2rem;
		}
	}
}

/*------Desktop media queries------*/
@media screen and (max-width: #{$screen__xl - 1px}) {
	.supplier {
		&__unit-card {
			width: 25%;
		}

		&__info-grid {
			width: 75%;
		}

		&__section {
			&.grid__col-3 {
				width: 50%;
			}
		}
	}
}

@media screen and (max-width: #{$screen__l - 1px}) {
	.supplier {
		&__form {
			.form__col_xxlarge {
				width: 66.67%;
			}
		}

		&__unit-card {
			width: 35%;
		}

		&__info-grid {
			width: 65%;
		}

		&__section {
			&.grid__col-3 {
				width: 100%;
			}
		}
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.supplier {
		&__top-block {
			padding-top: 2rem;
			padding-bottom: 3vmin;
			padding-right: 45px;
		}

		&__action {
			padding-top: 2rem;
		}

		&__unit-card {
			width: 100%;
		}

		&__section {
			.grid__container {
				& > .grid__col-6 {
					padding-left: 0;
					padding-right: 0;
					width: 100%;
				}
			}
		}

		&__info-grid {
			width: 100%;
		}

		&__doc {
			&-content {
				flex-wrap: wrap;
				gap: 0;
			}

			&-row {
				flex-wrap: wrap;
				padding-bottom: 1rem;
			}

			&-author {
				width: calc(100% - $date-col__width);
			}

			&-date {
				padding-left: 1rem;
			}

			&-param {
				order: 1;
			}

			&-file,
			&-description {
				order: 2;
			}

			&-comment {
				order: 3;
			}

			&-param,
			&-file,
			&-comment,
			&-description {
				width: 100%;
			}
		}
	}
}