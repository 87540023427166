@mixin subtitle {
	background-color: inherit;
	color: $default-subtitle__color-text;
	position: relative;
	z-index: 0;

	&:after {
		background-color: $default-subtitle__color-text;
		content: "";
		display: inline-block;
		height: 1px;
		left: 0;
		position: absolute;
		top: 50%;
		width: 100%;
		z-index: -1;
	}

	.subtitle__text {
		background-color: inherit;
		padding-right: 2rem;
	}
}

@mixin refresh {
	background-color: transparent;
	border: none;
}

@mixin hideOverflowText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin defaultInput {
	border: 1px solid $default-gainsboro;
	border-radius: $form-input__border-radius;
	box-shadow: $default-shadow-block;
	background-color: $default-white;
	transition: $default-transition;

	&:hover {
		border-color: $default-manatee;
	}

	&:focus {
		outline: none;
		border-color: transparent;
		box-shadow: $default-shadow;
	}
}