$default-width-page: 1770px;
$content-wrapper__padding: 3vw;
$content-wrapper__padding_mobile: 2vw;

$default-color-text: $default-black-marlin;
$default-background-page: $default-white-smoke;

$default__font-size: 10px;
$default-page__font_size: $font-size__regular;
$default-page_font_family: $font-roboto;
$default-btn__font-size: $font-size__regular;

// Subtitle
$default-subtitle__color-text: $default-cornflower-blue;
$default-subtitle__font-size: clamp(1.4rem, 0.4154rem + 0.9615vw, 1.8rem);
$default-subtitle__font-weight: $font-weight__bold;

// Menu
$default-menu__color-button: $default-cornflower-blue;
$default-menu__color-text: $default-jaguar;
$default-menu__color-text-dimmed: $default-cornflower-blue;
$default-menu__listitem-hover-color: $default-alabaster;
$default-menu__listitem-selected-color: $default-alabaster;
$default-menu__navbar-title: $default-cornflower-blue;

// Link
$link-primary__color: $default-cornflower-blue;
$link-primary_hover__color: $default-persian-blue;

$user-photo__size_small: 42px;
$btn-show-actions__size: 42px;

// Tabs
$tab-content__bg-color: $default-white;