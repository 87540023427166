$photo-col__width: 54px;
$main-info-col__width: 30%;

.comment{
	&-row {
		@extend .attach-row;

		&__content {
			@extend .attach-row__content;
		}

		&__actions {
			@extend .attach-row__actions;
		}
	}

	&-cell {
		@extend .attach-cell;
	}

	&__author-photo {
		width: $photo-col__width;
	}

	&__main-info {
		width: $main-info-col__width;
	}

	&__info {
		color: $default-comet;
	}

	&__date {
		@extend .attach__date;
	}

	&__content {
		width: calc(100% - $photo-col__width - $main-info-col__width - $date-col__width - 3 * $attach-flex_gap);
	}
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.comment {
		&-row__content {
			position: relative;
		}

		&__main-info {
			padding-left: 1rem;
			width: calc(100% - $photo-col__width);
		}

		&__author {
			padding-right: $date-col__width;
		}

		&__content {
			width: 100%;
		}

		&__date {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
