$favorite-modal-content__font-size: $font-size__regular;
$favorite-modal-table-controls__font-size: clamp(1.2rem, 0.7077rem + 0.4808vw, 1.4rem);
$favorite-tabs-content__padding: 3rem;

.favorite {
	&__wrap {
		align-self: stretch;
		display: flex;
		align-items: center;
	}

	&__modal {
		@include absPosition(
				$top: 100%,
				$translateY: 0
		);
		background-color: $default-background-page;
		box-shadow: 0 4px 12px rgba(35, 36, 42, 0.56);
		border-radius: 12px;
		left: 50%;
		margin: 0;
		width: 70%;
		z-index: 10;

		&_close {
			display: none;
		}

		&.shadowed-js {
			&:before {
				@include pseudo(
						$background: rgba(0,0,0,0.4),
						$height: 100%,
						$top: 0,
						$translateY: 0,
						$translateX: 0,
						$width: 100%
				);
				border-radius: 12px;
				left: 0;
				z-index: 10;
			}
		}
	}

	&__title {
		@include absPosition(
				$top: 1.4rem,
				$translateY: 0,
				$translateX: 2vw
		);
		margin-bottom: 0;
	}

	&-table {
		&__wrapper {
			padding-top: $favorite-tabs-content__padding;
			padding-bottom: $favorite-tabs-content__padding;

			.dataTables_length,
			.dataTables_filter,
			.dataTables_paginate,
			.dataTables_info {
				font-size: $favorite-modal-table-controls__font-size;
			}

			.dataTables_paginate {
				.paginate_button {
					line-height: clamp(1.4rem, 0.4154rem + 0.9615vw, 1.8rem);
				}
			}
		}

		&.dataTable {
			thead th {
				height: 44px;
				padding-bottom: 0;
				padding-top: 0;
				vertical-align: middle;
			}
		}
	}
}

button[data-btn='tabs-btn'] {
	position: absolute;
	bottom: $favorite-tabs-content__padding;
	left: 2vw;
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__l - 1px}) {
	.favorite__modal {
		min-width: 85vw;
	}
}

@media screen and (max-width: #{$screen__m - 1px}) {
	.favorite {
		&__modal {
			background-color: $default-white;
			border-radius: 0;
			height: 100vh;
			left: 0;
			overflow: hidden scroll;
			padding: 2rem 3vw 0;
			position: fixed;
			top: 0;
			transform: unset;
			width: 100vw;

			&_close {
				@include absPosition(
						$top: 0,
						$translateY: 1rem
				);
				display: inline-block;
				right: 0;
			}

			&.shadowed-js {
				&:before {
					border-radius: unset;
				}
			}
		}

		&__title {
			position: relative;
		}

		&-table {
			&__wrapper {
				padding-top: 0;
			}
		}
	}

	button[data-btn='tabs-btn'] {
		bottom: 0;
		display: block;
		margin: 0 auto;
		position: relative;
	}
}
