[class^='grid__col'] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-self: stretch;
}

.grid {
    &__container {
        @include flex(
            $justify: flex-start,
            $align: stretch,
            $wrap: wrap
        );
        width: 100%;
    }

    &__row {
        @include flex(
                $justify: flex-start,
                $align: baseline
        );
        width: 100%;
    }

    &__col-12 {
        width: 100%;
    }

    &__col-11 {
        width: 91.67%;
    }

    &__col-10 {
        width: 83.33%;
    }

    &__col-9 {
        width: 75%;
    }

    &__col-8 {
        width: 66.67%;
    }

    &__col-7 {
        width: 58.33%;
    }

    &__col-6 {
        width: 50%;
    }

    &__col-5 {
        width: 41.67%;
    }

    &__col-4 {
        width: 33.33%
    }

    &__col-3 {
        width: 25%;
    }

    &__col-2 {
        width: 16.66%;
    }

    &__col-1 {
        width: 8.33%;
    }

    &__col_center {
        align-self: center;
    }

    &__cell {
        &_pl {
            padding-left: 1rem;
        }

        &_pr {
            padding-right: 1rem;
        }

        &_px {
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}

.cell {
    &__title {
        font-weight: $font-weight__bold;
    }

    &__subtitle {
        font-size: $font-size__light;
        color: $default-comet;
        font-weight: $font-weight__regular;
        margin-bottom: .5rem;
    }

    &__content {
        font-size: $font-size__regular;
        margin: 0;
        word-break: break-word;

        &_important {
            font-weight: $font-weight__bold;
            color: $default-jaguar;
        }
    }

    &__link-container {
        margin-top: -4px;
    }
}

.row-item {
    &__title {
        color: $default-jaguar;
        padding-left: 1rem;
    }

    &__date {
        color: $default-jaguar;
        padding-left: 1rem;
        text-align: right;
    }

    &__content {
        margin-left: auto;
        padding-left: 1rem;
        padding-right: 1.5rem;
    }

    &__files {
        margin-left: auto;
        padding-left: 1rem;
    }
}

.row__border_bottom {
    border-bottom: 1px solid $default-athens-gray;
    box-shadow: 0 4px 6px rgba(2, 3, 27, 0.02);
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
    [class^=grid__col] {
        padding-top: .7rem;
        padding-bottom: .7rem;
    }

    .grid__col-12 {
        &.grid__cell {
            &_pr,
            &_pl {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .row-item {
        &__img {
            width: $user-photo__size_small;
        }

        &__date {
            padding-left: .5rem;
            width: 25%;
        }

        &__title {
            width: calc(100% - $user-photo__size_small - 25%);
        }

        &__content {
            width: 66.67%;
            margin-left: 0;
            padding-left: 0.5rem;
            padding-right: 1rem;
        }

        &__buttons {
            width: 33.33%;
            padding-left: 1rem;
        }

        &__files {
            width: 100%;
            padding-left: 0.5rem;
        }
    }
}

@media screen and (max-width: $screen__xs) {
    .row-item {
        .row-item {
            &__content {
                width: 83.33%;
            }

            &__buttons {
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                padding-left: .8rem;
                padding-right: .8rem;
                width: 16.66%;

                &:before {
                    height: 100%;
                }

                .btn-picto {
                    margin: .3rem 0;
                }
            }
        }
    }
}
