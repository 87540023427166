$row-odd__bg-color: $default-white;
$row-even__bg-color: $default-white-smoke;
$table__border-color: $default-gainsboro;
$thead__bg-color: $default-gun-powder;
$thead_hover__bh-color: $default-black-marlin;
$thead_focus__bg-color: $default-storm-grey;
$thead__border-radius: 5px;

.activities__wrapper {
	padding-bottom: 3rem;
}

.main-table__wrapper {
	padding-bottom: 5rem;

	.dataTables_wrapper {
		.dataTables_paginate {
			font-size: $paginate-item__font-size;

			.paginate_button:not(.disabled) {
				line-height: $paginate-btn__line-height;
			}
		}
	}
}

.table__wrapper {
	.dataTables_wrapper {
		.dataTables_filter {
			float: unset;

			input {
				@include defaultInput;
				border-radius: 25px;
				margin-left: 0;
				min-width: 250px;
				padding: 1.4rem 5rem 1.4rem 2rem;
				width: 20vw;
				background-image: url("../images/icons/search-fill.svg");
				background-repeat: no-repeat;
				background-position: calc(100% - 20px) 10px !important;
			}
		}

		.dataTables_paginate {
			font-weight: $font-weight__bold;
			margin-top: 2.5rem;

			.paginate_button:not(.disabled) {
				background-color: transparent;
				border: $paginate-btn__border;
				color: $paginate-btn__color !important;
				transition: $default-transition;
				border-radius: $paginate-btn__border-radius;
				margin-left: 6px;
				padding: $paginate-btn__padding;

				&:hover,
				&.current {
					text-decoration: none;
					background-color: $paginate-btn_hover__bg-color !important;
					background-image: none;
					color: $paginate-btn_hover__color !important;
					border: 1px solid $btn-primary__border-color;
				}

				&:focus {
					border-color: transparent;
					box-shadow: $default-shadow;
				}

				&:active {
					background-color: $paginate-btn_active__bg-color;
					background-image: none;
					border-color: transparent;
					box-shadow: $default-shadow_active;
					transform: scale(.85);
					transform-origin: center center;
				}
			}

			.paginate_button {
				&.current {
					cursor: default;
				}

				&.disabled {
					color: $paginate-btn_disabled__color !important;
				}
			}
		}

		.dataTables_length {
			float: unset;

			select {
				@include defaultInput;
				margin: 0 3px;
				padding: 1.3rem 1.5rem;
			}
		}

		.dataTables_scroll {
			.dtfc-right-top-blocker {
				background-color: $tab-content__bg-color;
				width: $thead__border-radius !important;
			}
		}

		&.no-footer {
			.dataTables_scrollBody {
				border-bottom: none;
				border-left: 1px solid $table__border-color;
				border-right: 1px solid $table__border-color;
			}
		}
	}
}

table.dataTable {
	border-collapse: collapse;
	font-size: $font-size__light;

	&.no-footer {
		border-bottom: none;
	}

	&.stripe {
		tbody {
			tr {
				&.odd {
					background-color: $row-odd__bg-color;

					& > .dtfc-fixed-right {
						background-color: $row-odd__bg-color;
					}
				}

				&.even {
					background-color: $row-even__bg-color;

					& > .dtfc-fixed-right {
						background-color: $row-even__bg-color;
					}
				}
			}
		}
	}

	thead {
		th {
			background-color: $thead__bg-color;
			border-bottom: none;
			color: $default-white;
			padding: 1.1rem 1.8rem 1.1rem .5rem;
			text-align: center;
			transition: opacity .3s ease-in-out;

			&.sorting {
				&:hover {
					background-color: $thead_hover__bh-color;
				}
			}

			&:first-child {
				border-top-left-radius: $thead__border-radius;

				&:hover {
					border-top-left-radius: $thead__border-radius;
				}
			}

			&:last-child {
				border-top-right-radius: $thead__border-radius;

				&:hover {
					border-top-right-radius: $thead__border-radius;
				}
			}

			&:focus:not(:active) {
				background-color: $thead_focus__bg-color;
			}
		}

		tr > .dtfc-fixed-right {
			background-color: $thead__bg-color;
		}
	}

	tbody {
		tr {
			border-top: 1px solid $table__border-color;
			border-bottom: 1px solid $table__border-color;
			transition: $default-transition;

			.dt-check {
				border-left: 1px solid $table__border-color;
			}

			.dt-control,
			.dt-action {
				outline: 1px solid $table__border-color;
			}

			.dt-control {
				cursor: default;
			}

			&.shown {
				td.dt-control {
					overflow: visible;
				}
			}
		}

		td {
			overflow: hidden;
			padding: .6rem 1rem;
			text-overflow: ellipsis;
			vertical-align: middle;

			&:last-child {
				padding-left: .5rem;
				padding-right: .5rem;
			}

			&.cell-center {
				text-align: center;
			}

			&.td_center {
				text-align: center;
				padding-right: 20px;
			}

			.check-picto {
				&:after {
					background-image: url("../images/icons/star.svg");
				}

				&:checked:after {
					background-image: url("../images/icons/star-active.svg");
				}

				&:focus {
					box-shadow: $default-shadow;
				}
			}
		}
	}
}

.table {
	&__top-left {
		float: left;
	}

	&__top-right {
		@include flex(
				$align: baseline
		);
		gap: 6px;
		float: right;
	}

	&__top-left,
	&__top-right {
		margin-bottom: 2rem;

		label {
			margin-bottom: 0;
		}
	}

	&__action-btn {
		@include flex(
				$display: inline-flex,
				$justify: center
		);
		border-radius: 50%;
		height: 30px;
		position: relative;
		width: 30px;
		z-index: 1;
	}

	&__cell-icon {
		background-image: url("../images/icons/icons-sprite.svg");
		background-repeat: no-repeat;
		background-size: auto;
		display: block;
		font-size: 0;
		height: 22px;
		margin: 0 auto;
		width: 22px;
	}

	&__icon-active {
		background-position: -331px -13px;
	}

	&__modal {
		&-wrapper {
			@include absPosition(
					$translateY: 0,
					$translateX: -100%
			);
			background-color: $default-white;
			border-radius: 8px;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.07);
			left: 50%;
			padding: 1rem 2rem;
		}

		&-list {
			@include flex(
					$align: flex-start,
					$direction: column
			);
			margin: 0;

			&-item {
				padding: .6rem;
			}
		}
	}
}

.action__container {
	position: absolute;
	transform: translate(-50%, -50%);
	z-index: 11;
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
	.main-table__wrapper {
		padding: .5rem 0;
	}

	.table {
		&__top-left,
		&__top-right {
			float: unset;
		}

		&__top-right {
			justify-content: center;
		}
	}

	.table__wrapper {
		.dataTables_wrapper {
			.dataTables_filter {
				text-align: center;

				input {
					min-width: 315px;
				}
			}
		}
	}

	.table__action-btn {
		&:focus:not(.btn_disabled) {
			box-shadow: none;
		}
	}
}