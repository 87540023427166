.gescom-modal {
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    &[data-priority="main"] {
        z-index: 1000;
    }

    &__content {
        @include absPosition();
        background-color: $default-white;
        border-radius: 12px;
        left: 50%;
        margin: auto;

        &_small {
            max-width: 600px;
            padding: 4% 3%;
            width: 70%;
        }

        &_large {
            max-width: 800px;
            padding: 3% 4rem;
            width: 90%;
        }
    }

    &__title {
        padding-bottom: 3vmin;
        text-align: center;
    }

    &__text {
        padding-bottom: 7%;
        text-align: center;
    }

    &__import-block {
        text-align: left;
        margin-bottom: 3rem;
    }

    &__period {
        color: $default-jaguar;
        font-weight: $font-weight__bold;
    }

    &__btn-wrapper {
        @include flex(
                $justify: center,
                $wrap: wrap
        );
        gap: 2rem;
    }

    &__close {
        @include absPosition(
                $top: 0,
                $translateY: 50%
        );
        right: 0;
    }
}

.form-modal {
    &__content {
        height: 90vh;
    }

    &__overflow {
        @include flex(
            $direction: column,
            $justify: flex-start,
            $align: unset
        );
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: .4rem;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__set {
        background-color: $default-white;
        padding: 1rem 0 0;
        width: 100%;
    }

    &__btn-wrapper {
        @include flex(
                $justify: flex-end,
                $wrap: wrap
        );
        gap: 2rem;
        margin-top: auto;
    }
}

/*------Mobile media queries------*/

@media screen and (max-width: #{$screen__m - 1px}) {
    .gescom-modal {
        &__content_large {
            border-radius: unset;
            height: 100%;
            left: 0;
            padding: 4rem 1.5rem 1rem;
            top: 0;
            transform: unset;
            width: 100%;

            .grid {
                &__col-6 {
                    width: 100%;
                }
            }
        }

        &__text {
            padding-bottom: 4vmin;
        }

        &__btn-wrapper {
            margin-top: 2rem;
        }

        &__import-block {
            .attach__file {
                &-item {
                    justify-content: space-between;
                }

                &-label {
                    margin: 0 auto;
                }

                &-name {
                    min-width: 88%;
                }
            }
        }
    }

    .form-modal {
        &__btn-wrapper {
            justify-content: space-around;
            gap: .5rem 0;
        }
    }
}

@media screen and (max-width: $screen__xs) {
    .gescom-modal {
        &__content {
            &_small {
                padding: 8% 5%;
                width: 90%;
            }
        }
    }
}

